/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { RouteProps } from 'react-router-dom';
import { Permission } from '../constants/config';

/*
 * users-management interface routes
 * */
export enum ManagementRouter {
  APP = '/management',
  HOME = '/management/home',
  DASHBOARD = '/management/dashboard',
  USER_MANAGEMENT = '/management/user-management',
  PROPERTY_MANAGEMENT = '/management/property-management',
  THREE_D_CONTENT_MANAGEMENT = '/management/3d-content-management',
  QUIZ_MANAGEMENT = '/management/quiz-management',
  NOT_FOUND = '/management/404',
  NOT_AUTHORIZED = '/management/403',
}

export enum ManagemenDasboardRoutes {
  USERS = '/users',
  PROPERTIES = '/properties',
  SHOPPING = '/shopping',
  PRODUCTS = '/products',
  REVENUE = '/revenue',
}

export enum UserManagementRoute {
  CLIENTS = '/clients',
  TEAM_PATRICIA = '/team-patricia',
  SUPPLIERS = '/suppliers',
  ORDERS = '/orders',
  PROPERTIES = '/properties',
  ARCHIVED_USERS = '/archived-users',
  RENDERS = '/renders',
}

export enum UserManagementClientsRoute {
  INFO = '/info',
  PRODUCTS = '/products',
  PROPERTIES = '/properties',
  INSPIRATION_QUIZZES = '/inspiration-quizzes',
  CART_ITEM = '/shopping_cart_item',
  USER_ACTIVITY = '/activities',
  RENDERS = '/renders',
}

export enum UserManagementTeamPatriciaRoute {
  INFO = '/info',
  ROLE_MANAGEMENT = '/role-management',
}

export enum UserManagementSuppliersRoute {
  INFO = '/info',
  DATABASE = '/database',
  ORDERS = '/orders',
  ATTACHMENT_FILES = '/attachment-files',
}

export enum QuizManagementRoute {
  INSPIRATION_QUIZ = '/inspiration-quiz',
  DESIGN_STYLE = '/design-style',
  DESIGN_STYLE_DRILL_DOWN = '/design-style/:id',
  DESIGN_STYLE_ROOM_DRILL_DOWN = '/design-style/:id/:roomId',
  ARCHIVE = '/archive',
}

export const PropertiesManagementRoutes = {
  PROPERTY_MANAGEMENT_NEW_TASK_PROPERTIES: `${ManagementRouter.PROPERTY_MANAGEMENT}/new-tasks/properties`,
  PROPERTY_MANAGEMENT_NEW_TASK_UNITS: `${ManagementRouter.PROPERTY_MANAGEMENT}/new-tasks/units`,
  PROPERTY_MANAGEMENT_ARCHIVED: `${ManagementRouter.PROPERTY_MANAGEMENT}/archived`,
  PROPERTY_MANAGEMENT_PUBLISHED: `${ManagementRouter.PROPERTY_MANAGEMENT}/published`,
};

export const ThreeDContentManagementRoutes = {
  THREE_D_MANAGEMENT_ALL_TASKS: `${ManagementRouter.THREE_D_CONTENT_MANAGEMENT}/all-tasks`,
  THREE_D_MANAGEMENT_IN_DB: `${ManagementRouter.THREE_D_CONTENT_MANAGEMENT}/in-db`,
};

/*
 * client interface routes
 * */
export enum ClientRouter {
  APP = '/app',
  MY_ACCOUNT = '/app/my-account',
  PROFILE = '/app/my-account/edit',
  PROFILE_ADDRESSES = '/app/my-account/edit/addresses',
  ADD_PROPERTY = '/app/properties?modal=add-property',
  PROPERTIES = '/app/properties',
  PROPERTY = '/app/properties/:propertyId',
  QUIZ_ADD_PROPERTY = '/app/inspiration-quizzes?modal=add-property',
  SKINS = '/app/skins',
  SKIN = '/app/skins/:id',
  SKIN_CHECKOUT = '/app/skins/checkout/:id',
  SHOP = '/app/shop',
  SEARCH = '/app/search',
  CART = '/app/cart/',
  PROPERTY_CART = '/app/cart/:cartId',
  CHECKOUT = '/app/checkout',
  CHECKOUT_SUCCESS = '/app/checkout/success',
  CHECKOUT_FAILURE = '/app/checkout/failure',
  ORDERS = '/app/orders',
  ORDER_DETAILS = '/app/orders/:orderId',
  ONBOARDING = '/app/onboarding',
  UPLOAD_PLAN = '/app/onboarding/upload-plan',
  SELECT_FLOOR = '/app/onboarding/select-floor',
  CONFIRM_PLAN = '/app/onboarding/confirm-plan',
  PLAN_CONFIRMED = '/app/onboarding/plan-confirmed',
  QUIZ_QUESTION = '/app/onboarding/quiz-question',
  QUIZ_SUMMARY = '/app/onboarding/quiz-summary',
  ADDITIONAL_INFO = '/app/onboarding/additional-info',
  CREATE_KEY_CONFIRMATION = '/app/onboarding/create-key-confirmation',
  DONE = '/app/onboarding/done',
  NOT_FOUND = '/app/404',
  TOUR = '/app/tour/:propertyId',
  TOUR_UNITS = '/app/tour/:unitId?units=true',
  HELP = '/app/help',
  CONTACT = '/app/contact-us',
  BECOME_AN_EXPERT = '/app/become-an-expert',
  BECOME_A_SELLER = '/app/become-a-seller',
  SELL_YOUR_PRODUCTS = '/app/sell-your-products',
  AUTH = '/app/auth',
  PROPERTY_IN_PROGRESS = '/app/property-in-progress',
  PRODUCTS = '/app/products',
  TERMS_OF_USE = '/app/terms-of-use',
  PRIVACY_POLICY = '/app/privacy-policy',
  PRODUCT_CATEGORY = '/app/products/:catName',
  PRODUCT_SUB_CATEGORY = '/app/products/:catName/:subCatName',
  PRODUCT_PAGE = '/app/products/:catName/:subCatName/:productId',
  SEARCH_PAGE = '/app/search/:searchName',
  MAGAZINE = '/app/magazine',
  MAGAZINE_PAGE = '/app/magazine/:magazineName',
  COMING_SOON = '/app/coming-soon',
  MOBILE_TOUR_APP_ROUTE = '/enter-home?token=:token&region=:region&is_mobile=:isMobile&ambientOcclusionEnabled=:ambientOcclusionEnabled&cartId=:cartId&base_ep=:baseEp&property_id=:propertyId&baseWebHost=:baseWebHost&user_id=:userId&start_position=:start_position&skinId=:skinId',
  ACCESSIBILITY_PAGE = '/app/accessibility',
  IDEA_PAGE = '/app/ideas/:id',
  IDEAS_PAGE = '/app/ideas',
  BUY_MORE_PATRICIA_COINS = '/app/buy-more-patricia-coins',
  SALES_PAGE = '/app/sales',
  SELLER_LANDING_PARENT = '/app/landing',
  SELLER_LANDING = '/app/landing/:supplierName',
  SELLER_IFRAME_FROM = '/app/iframe/landing/seller/:supplierName',
  PROPERTIES_SELLER = '/app/sellerProperties',
  PROPERTIES_SELLER_LISTING = '/app/sellerProperties/project',
  PROPERTIES_SELLER__MY_PROJECT = '/app/sellerProperties/project/:projectId',
  ADD_PROJECT = '/app/sellerProperties/project?modal=add-project',
  SUPPLIER_LOGIN = '/app/supplier-login',
  SUPPLIER_REGISTER = '/app/supplier-register',
  SUPPLIER_LOGIN_WELCOME = '/app/supplier-login-welcome',
  SUPPLIER_CONGRATULATIONS = '/app/supplier-congratulations/:type',
  SUPPLIER_INFO = '/app/supplier-info',
  SUPPLIER_LOGIN_THANKS = '/app/supplier-info/supplier-login-thanks',
  SUPPLIER_STORE_INFO = '/app/supplier-info/store-details',
  SUPPLIER_MAIN_DETAILS = '/app/supplier-info/main-details',
  SUPPLIER_ADDITIONAL_INFO = '/app/supplier-info/additional-info',
  SUPPLIER_MARKETING_INFO = '/app/supplier-info/marketing-info',
  PROPERTY_MEDIA = '/app/property-media',
}

/*
 * suppliers interface routes
 * */
export enum SupplierRouter {
  APP = '/supplier',
  HOME = '/supplier/home',
  DASHBOARD = '/supplier/dashboard',
  STATISTICS = '/supplier/statistics',
  MY_DATABASE = '/supplier/my-database',
  ORDERS = '/supplier/orders',
  FILES = '/supplier/files',
  USERS = '/supplier/users',
  USER = '/supplier/user',
  USER_INFO = '/supplier/user/:id',
  NOT_FOUND = '/supplier/404',
}

export const SupplierDashBoardRoutes = {
  INFO: `${SupplierRouter.DASHBOARD}/info`,
  SELLING_PRODUCTS: `${SupplierRouter.DASHBOARD}/sold-products`,
};

export const SupplierMyDatabaseRoutes = {
  ALL: `${SupplierRouter.MY_DATABASE}/all`,
  IN_PROGRESS: `${SupplierRouter.MY_DATABASE}/in-progress`,
};

export const SupplierUsersRoutes = {
  CLIENTS: `${SupplierRouter.USERS}/clients`,
  SELLERS: `${SupplierRouter.USERS}/sellers`,
};

export const SupplierOrdersRoutes = {
  ALL_ORDERS: `${SupplierRouter.ORDERS}/all-orders`,
  DISPUTE_ORDERS: `${SupplierRouter.ORDERS}/dispute-orders`,
};
/*
 * auth screens routes
 * */
export enum AuthRoute {
  LOGIN = '/login',
  REGISTER = '/register',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_LINK_SENT = '/reset-link-sent',
  RESET_PASSWORD = '/reset-password',
  RESET_SUCCESS = '/reset-success',
}

export interface IRouteConfig {
  path: string;
  exact: boolean;
  component:
    | React.LazyExoticComponent<
        React.FC<RouteProps & { routes: IRouteConfig[] } & any>
      >
    | React.FC<RouteProps & { routes: IRouteConfig[] } & any>;
  routes: IRouteConfig[];
  permissions?: Permission[];
  isPublic?: boolean;
}
