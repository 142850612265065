import Message, { LanguageMessages } from './messages.config';

const messages: LanguageMessages = {
  [Message.APP_NAME]: 'Patricia',
  [Message.LOADING]: 'Loading...',
  [Message.PRIMARY_LANGUAGE]: 'English',
  [Message.SECONDARY_LANGUAGE]: 'עברית',
  [Message.AUTH_PROVIDERS_BUTTON]: '{authContext} with {provider}',
  [Message.OR]: 'Or',
  [Message.ORCONNECTWITH]: 'Or connect with',
  [Message.YES]: 'Yes',
  [Message.NO]: 'No',
  [Message.NO_VALUE]: 'N/A',
  [Message.DONE]: 'Done',
  [Message.NOT_SUPPORTED_REGION]:
    'The system is not available for your region at the moment.',
  [Message.COUNTRY_SEARCH_PLACEHOLDER]: 'Search for country...',
  [Message.SEARCH_NO_RESULTS]: 'No results found for "{inputValue}"',
  [Message.LOGOUT_CONFIRM_CAPTION]:
    'Are you sure you want to logout from Patricia?',
  [Message.SEARCH_PLACEHOLDER]: 'Find a Product',
  [Message.MENU_ITEM_LOGIN]: 'Login',
  [Message.MENU_ITEM_TEAM_MEMBER_LOGIN]: 'Team/Supplier Login',
  [Message.MENU_ITEM_MENU_ITEM_REGISTRATION]: 'Sign up',
  [Message.MENU_ITEM_PROFILE]: 'My Profile',
  [Message.MENU_ITEM_BUY_MORE_PATRICIA_COINS]: 'Buy more Patricia coins',
  [Message.MENU_ITEM_PROPERTIES]: 'My Properties',
  [Message.MENU_ITEM_ORDERS]: 'My Orders',
  [Message.MENU_ITEM_PRODUCTS]: 'Products Catalog',
  [Message.MENU_ITEM_CART]: 'Shopping Cart',
  [Message.MENU_ITEM_SUPPORT]: 'Customer Support',
  [Message.MENU_ITEM_CONTACT]: 'Contact Us',
  [Message.CONTACT_INFO_TITLE]: 'Customer service :',
  [Message.CONTACT_INFO_DEPARTMENT]: 'Department*',
  [Message.CONTACT_INFO_DEPARTMENT_OPTION1]: 'Customer support',
  [Message.CONTACT_INFO_DEPARTMENT_OPTION2]: 'Technical support',
  [Message.CONTACT_INFO_PUBLIC_AREA]: 'Problem area*',
  [Message.CONTACT_INFO_PUBLIC_AREA_OPTION1]: 'Website on the computer',
  [Message.CONTACT_INFO_PUBLIC_AREA_OPTION2]: 'Website on the phone',
  [Message.CONTACT_INFO_PUBLIC_AREA_OPTION3]: 'Website on the tablet',
  [Message.CONTACT_INFO_PUBLIC_AREA_OPTION4]: 'Website in the phone app',
  [Message.CONTACT_INFO_PUBLIC_AREA_OPTION5]: 'In 3D on the computer',
  [Message.CONTACT_INFO_PUBLIC_AREA_OPTION6]: 'In 3D on the tabled',
  [Message.CONTACT_INFO_PUBLIC_AREA_OPTION7]: 'In 3D on the phone',
  [Message.CONTACT_INFO_COUNTRY_USA]: 'USA',
  [Message.CONTACT_INFO_COUNTRY_ISRAEL]: 'ISRAEL',
  [Message.CONTACT_INFO_WORK_DAY_TITLE]: 'Work Days:',
  [Message.CONTACT_INFO_WORK_HOUR_TITLE]: 'Work Hours:',
  [Message.CONTACT_INFO_WORK_DAYS_USA]: 'Monday - Friday',
  [Message.CONTACT_INFO_WORK_HOURS_USA]: '9:00 - 18:00 EST',
  [Message.CONTACT_INFO_WORK_DAYS_ISRAEL]: 'Sunday - Thursday',
  [Message.CONTACT_INFO_WORK_HOURS_ISRAEL]: '9:00 - 18:00 GMT+2',
  [Message.CONTACT_INFO_WORK_PHONE_USA]: '+1 415 691 5492',
  [Message.CONTACT_INFO_WORK_PHONE_ISRAEL]: '+972 52 656 7777',
  [Message.CONTACT_INFO_WORK_EMAIL]: 'team@patricia-ai.com',
  [Message.CONTACT_INFO_DEVICE_INFO]: 'Device Type*',
  [Message.CONTACT_INFO_DEVICE_INFO_OPTION1]: 'iPhone',
  [Message.CONTACT_INFO_DEVICE_INFO_OPTION2]: 'Android',
  [Message.CONTACT_INFO_DEVICE_INFO_OPTION3]: 'Apple Tablet',
  [Message.CONTACT_INFO_DEVICE_INFO_OPTION4]: 'Android Tablet',
  [Message.CONTACT_INFO_DEVICE_INFO_VERSION]: 'Device version (eg. iPhone11)',
  [Message.CONTACT_INFO_DESCRIPTION]: 'Description',
  [Message.CONTACT_INFO_THANK_YOU]: 'Thank you ',
  [Message.CONTACT_INFO_BACK]: 'we will get back to you soon :)',
  [Message.CONTACT_SEND]: 'Send',
  [Message.CONTACT_EMAIL_ADDRESS]: 'Email address',
  [Message.CONTACT_ENTER_YOUR_MESSAGE]: 'Enter your message here...',
  [Message.MENU_ITEM_LOGOUT]: 'Logout',
  [Message.FOOTER_APP_DESCRIPTION]:
    'One-stop-shop virtual home design marketplace.',
  [Message.FOOTER_COPYRIGHT_STATEMENT]:
    'Copyright © {currentYear}. Patricia AI. All rights reserved.',
  [Message.FOOTER_ABOUT_MENU_TITLE]: 'About',
  [Message.FOOTER_ABOUT_MENU_OUR_STORY]: 'Our story',
  [Message.FOOTER_ABOUT_MENU_TEAM]: 'Meet the team',
  [Message.FOOTER_ABOUT_MENU_CAREERS]: 'Careers',
  [Message.FOOTER_ABOUT_MENU_NEWS]: 'Press',
  [Message.FOOTER_ABOUT_MENU_MAGAZINE]: 'Magazine',
  [Message.FOOTER_SUPPORT_MENU_TITLE]: 'Support',
  [Message.FOOTER_SUPPORT_MENU_QA]: 'Support FAQs',
  [Message.FOOTER_SUPPORT_MENU_TERMS]: 'Terms of use',
  [Message.FOOTER_SUPPORT_MENU_PRIVACY]: 'Privacy policy',
  [Message.FOOTER_SUPPORT_MENU_CONTACT]: 'Contact us',
  [Message.FOOTER_SUPPORT_MENU_ACCESSIBILITY]: 'Accessibility',
  [Message.FOOTER_COMMUNITY_MENU_TITLE]: 'Community',
  [Message.FOOTER_COMMUNITY_MENU_EXPERT]: 'Become an expert',
  [Message.FOOTER_COMMUNITY_MENU_SUPPLIER]: 'Sell your products',
  [Message.FOOTER_COMMUNITY_BECOME_A_SUPPLIER]: 'Become a Seller',
  [Message.FOOTER_COMMUNITY_MENU_SUPPLIER_INTERFACE]: 'Supplier’s interface',
  [Message.FOOTER_CONTACT_MENU_TITLE]: 'Contact us',
  [Message.FOOTER_CONTACT_MENU_CUSTOMER_SERVICE]: 'Customer service:',
  [Message.FOOTER_CONTACT_MENU_PRIMARY_OFFICE_TITLE]: 'USA',
  [Message.FOOTER_CONTACT_MENU_SECONDARY_OFFICE_TITLE]: 'Israel',
  [Message.FOOTER_CONTACT_MENU_WORK_DAYS]: 'Work days:',
  [Message.FOOTER_CONTACT_MENU_WORK_HOURS]: 'Work hours:',
  [Message.FOOTER_CONTACT_MENU_PRIMARY_OFFICE_WORK_DAYS]: 'Monday - Friday',
  [Message.FOOTER_CONTACT_MENU_SECONDARY_OFFICE_WORK_DAYS]: 'Sunday - Thursday',
  [Message.FOOTER_CONTACT_MENU_PRIMARY_OFFICE_WORK_HOURS]: '9:00 18:00 EST',
  [Message.FOOTER_CONTACT_MENU_SECONDARY_OFFICE_WORK_HOURS]: '9:00 18:00 GMT+2',
  [Message.FOOTER_CONTACT_MENU_PRIMARY_OFFICE_PHONE]: '+1 846 665 654',
  [Message.FOOTER_CONTACT_MENU_SECONDARY_OFFICE_PHONE]: '+972 52 656 7777',
  [Message.FOOTER_CONTACT_MENU_PRIMARY_OFFICE_EMAIL]: 'team@patricia-ai.com',
  [Message.FOOTER_CONTACT_MENU_SECONDARY_OFFICE_EMAIL]: 'team@patricia-ai.com',
  [Message.FOOTER_CONTACT_QUICK_SERVICE]: 'Quick Service',
  [Message.FOOTER_CONTACT_WEEKLY_HOURS]: 'Weekly Hours',
  [Message.FREE_DELIVERY]: 'FREE DELIVERY',
  [Message.FREE_DELIVERY_CONTENT_1]:
    'We want to celebrate our launch with you by offering a great deal.',
  [Message.FREE_DELIVERY_CONTENT_2]: 'For a limited time, ',
  [Message.FREE_DELIVERY_CONTENT_3]: 'free shipping for all!',
  [Message.HOME_HERO_TITLE]: 'Welcome to {linebreak}<span>Patricia</span>',
  [Message.HOME_HERO_TITLE_WELCOME]: 'Welcome Back',
  [Message.HOME_HERO_CAPTION]: 'Upload.Design.<span>Shop</span>',
  [Message.HOME_WATCH_DEMO]: 'Watch Demo',
  [Message.HOME_HERO_CAPTION_SHOP]: 'Shop',
  [Message.HOME_HERO_SUMMARY]: 'Your dream house is only a click away.',
  [Message.CREATE_HOME_BUTTON]: 'Create Your Home',
  [Message.ENTER_HOME_BUTTON]: 'Enter your home',
  [Message.HOME_STORIES_TITLE]: 'Highlights of Patricia AI',
  [Message.HOME_STORIES_MEET_PATRICIA]: 'Meet Patricia',
  [Message.HOME_STORIES_HOW_TO_START]: 'How It Works',
  [Message.HOME_STORIES_BRANDS]: 'Patricia Brands',
  [Message.HOME_STORIES_SKINS]: 'Meet Patricia’s Skins',
  [Message.HOME_STORIES_PRODUCTS_DESIGNS]: 'More Inspiration',
  [Message.HOME_SKINS_TITLE]: 'Patricia Skins',
  [Message.HOME_SKINS_TITLE_BUTTON]: 'See more',
  [Message.HOME_SKINS_EMPTY_STATE_MESSAGE]: 'No skins available at the moment',
  [Message.HOME_MAGAZINE_TITLE]: 'Magazine',
  [Message.HOME_EXPERTS_TITLE]: 'Our Experts',
  [Message.HOME_BECOME_A_SUPPLIER_TITLE]: 'Become a supplier',
  [Message.HOME_PRODUCTS_TITLE]: 'Shop By Category',
  [Message.HOME_PRODUCTS_TITLE_BUTTON]: 'See more',
  [Message.DISCOUNT_PERCENT]: '{percentage}% Off',
  [Message.HOME_MAGAZINE_BANNER_TITLE]: "Let's see what's new",
  [Message.HOME_MAGAZINE_BANNER_CAPTION]:
    'Come and learn from our experts of what really matters in home design.',
  [Message.HOME_MAGAZINE_BANNER_ACTION]: 'Take me in',
  [Message.HOME_SUPPLIER_CMS_TITLE]: 'My Store Dashboard',
  [Message.HOME_MANAGEMENT_TITLE]: 'Management',
  [Message.HOME_IDEAS_TITLE]: 'Ideas',
  [Message.HOME_SKINS_BANNER_TITLE]: 'Patricia Skins',
  [Message.HOME_SKINS_BANNER_CAPTION]:
    'For the first time in the world, you can dress up your home in a click of a button!',
  [Message.HOME_SKINS_BANNER_ACTION]: 'Explore Skins',

  [Message.HOME_EXPERTS_BANNER_TITLE]: 'Meet Patricia’s Experts',
  [Message.HOME_EXPERTS_BANNER_CAPTION]:
    'Introducing the Interior Designers, Architects, Influencers and furniture brands who make the difference in interior design.',
  [Message.HOME_BECOME_A_SUPPLIER_BANNER_CAPTION]:
    'Join Patricia to become a supplier and share your{linebreak} products with thousands of potential buyers in{linebreak} a 3D environment.',
  [Message.HOME_BECOME_A_SUPPLIER_BANNER_CAPTION_MOBILE]:
    'Join Patricia to become a supplier and{linebreak} share your products with thousands of{linebreak} potential buyers in a 3D environment.',
  [Message.HOME_EXPERTS_BANNER_ACTION]: 'Learn more',
  [Message.HOME_BECOME_A_SUPPLIER_BANNER_ACTION]: 'Join now',
  [Message.HOME_ENTER_YOUR_HOME_TOOLTIP_TEXT]: `You don't have a ready property for 3D touring`,
  [Message.SHOP_NOW]: 'Shop {linebreak} Now',
  [Message.SEARCH_RESULT_FOR]: 'Search results for',
  [Message.YOUR_SEARCH_RESULT_FOR]: 'Your search result for:',
  [Message.PROPERTIES_TITLE]: 'My Properties',
  [Message.PROPERTIES_BUTTON]: 'Add a new property',
  [Message.PROPERTIES_INFO_BOX_TITLE]: 'Properties',
  [Message.PROPERTIES_INFO_BOX_TEXT]:
    'We have already gathered everything you need to create your own virtual home.By clicking on one of the tabs on the left, you will dive into your specific property page where you can take a tour, start design, see your updated products that are ready for ship and all related orders.',
  [Message.PROPERTIES_EMPTY_STATE_CAPTION]:
    "Don't have a published property yet? {linebreak} In order to create property for you, {linebreak} we will have to go through an Inspiration quiz",
  [Message.PROPERTIES_EMPTY_STATE_BUTTON_LABEL]: 'Start building your home',
  [Message.PROPERTY_CARD_NAME]: 'Property name',
  [Message.PROPERTY_CARD_QUIZ_NAME]: 'Quiz name',
  [Message.PROPERTY_CARD_LAST_MODIFIED]: 'Last modified',
  [Message.PROPERTY_NOT_FOUND]: 'Property not found',
  [Message.PROPERTY_ADD_PROPERTY_MODAL_TITLE]:
    'In order for Patricia to be able to create another 3D model of your property , please upload a floor plan that is as accurate and clear as possible.',
  [Message.PROPERTY_ADD_CLIENT_MODAL_TITLE]: 'Client Information',
  [Message.PROPERTY_ADD_PROPERTY_BUTTON_NEXT]: 'Next',
  [Message.PROPERTY_ADD_PROPERTY_BUTTON_UPLOAD]: 'Upload',
  [Message.PROPERTY_ADD_PROPERTY_BUTTON_PREVIOUS]: 'Back',
  [Message.PROPERTY_ADD_PROPERTY_BUTTON_SKIP]: 'Skip',
  [Message.PROPERTY_ADD_PROPERTY_MODAL_QUIZ_TITLE]:
    'Since you already took a quiz before, you can use the same one or start a new quiz as well.',
  [Message.PROPERTY_ADD_PROPERTY_MODAL_SELECT_QUIZ]: 'Select Quiz',
  [Message.PROPERTY_ADD_PROPERTY_MODAL_QUIZ_RESULT]:
    '{quizName} (result: {quizResult})',
  [Message.PROPERTY_ADD_PROPERTY_MODAL_START_QUIZ]: 'Start A New Quiz',
  [Message.PROPERTY_ADD_PROPERTY_ADD_BUTTON]: 'Add property',
  [Message.PROPERTY_EDIT_PROPERTY]: 'Edit property',
  [Message.PROPERTY_BUTTON_TAKE_TOUR]: 'Take a tour',
  [Message.BUTTON_SHOW_ALL_MEDIA]: 'Show all media',
  [Message.PROPERTY_PRODUCTS_TITLE]: 'Products <span> {count} </span>',
  [Message.PROPERTY_PRODUCTS_NO_ITEMS]: 'No media available',
  [Message.PROPERTY_BUTTON_SHOW_MORE]: 'Show more',
  [Message.PROPERTY_ORDERS_TITLE]: 'Orders',
  [Message.PROPERTY_CANCEL_ORDERS_TITLE]: 'Cancel Order',
  [Message.PROPERTY_CANCEL_ORDERS_DESCRIPTION_LABLE]: 'Description :',
  [Message.PROPERTY_CANCEL_ORDERS_CONTACTINFO_LABLE]: 'Contact Info :',
  [Message.PROPERTY_CANCEL_ORDERS_DESCRIPTION]:
    'Pressing the Submit button will submit an order cancellation request for Order Number {ORDER_NUMBER}. {linebreak} Cancelling the order does not mean a refund will be provided.{linebreak} After submitting the form our customer support staff will contact you about finalizing the cancellation of the order and the payment refund.{linebreak}Please fill out your phone number and email address so our customer support can contact you about the cancellation.',
  [Message.ORDERS_CARD_ORDER_NUMBER]: 'Order Number',
  [Message.ORDERS_CARD_TRACKING_NUMBER]: 'Tracking Number',
  [Message.PROPERTY_ORDERS_CARD_ORDER_DATE]: 'Order date:',
  [Message.PROPERTY_ORDERS_NO_ORDERS_TITLE]:
    'How come you have not ordered anything yet?',
  [Message.PROPERTY_ORDERS_NO_ORDERS_TEXT1]: 'We encourage you to browse our',
  [Message.PROPERTY_ORDERS_NO_ORDERS_SKINS]: 'Skins',
  [Message.PROPERTY_ORDERS_NO_ORDERS_TEXT2]: 'page to find inspiration',
  [Message.PROPERTY_MEDIA_MODAL_TITLE]: 'My Media',
  [Message.PROPERTY_CHIP_MODAL_TITLE]: 'Filter By Room',
  [Message.PROPERTY_MODAL_TITLE_SELLER]: 'Add a new property',
  [Message.PROPERTY_MODAL_UPLOAD_FLOOR_PLAN]: 'Upload a floor plan',
  [Message.PROPERTY_MODAL_UPLOAD_FLOOR_PLAN_SUBTITLE]:
    'We will take care for all{linebreak} the rest',
  [Message.PROPERTY_MODAL_DO_IT_YOURSELF_TITLE]: 'Do it yourself',
  [Message.PROPERTY_MODAL_DO_IT_YOURSELF_SUBTITLE]:
    'Upload a floor plan or{linebreak} draw from scratch',
  [Message.PROPERTY_MODAL_DEMO_FLOOR_PLANS_TITLE]: 'Demo floor plans',
  [Message.PROPERTY_MODAL_DEMO_FLOOR_PLANS_SUBTITLE]:
    'Choose from our{linebreak} pre-made houses',
  [Message.PROPERTY_MODAL_COMING_SOON]: 'Coming soon',
  [Message.PROPERTY_MODAL_CUSTOMER_DETAILS]: 'Customer details',
  [Message.PROPERTY_MODAL_VALIDATION_MESSAGE]: '*All details are mandatory',
  [Message.MORE_PROPERTIES_MODAL_TITLE]: 'You have more than one cart',
  [Message.MORE_PROPERTIES_MODAL_TEXT]:
    ' You can {switch} between them using the tabs (under my cart)',
  [Message.MORE_PROPERTIES_MODAL_BUTTON]: 'Got it!',
  [Message.CAROUSEL_MEDIA_TITLE]: 'My Media <span>({mediaCount})</span>',
  [Message.MEDIA_SECTION_TITLE]: 'Media <span>({mediaCount})</span>',
  [Message.CART_TITLE]: 'My cart',
  [Message.CART_TOTAL_ITEMS_LABEL]: '{value} Total items',
  [Message.CART_TOTAL_ITEMS_LABEL_MOBILE]: '{value} items',
  [Message.CART_ITEM_OUT_OF_STOCK_LABEL]: 'Out of stock',
  [Message.CART_ITEM_STOCK_LABEL]: 'in stock',
  [Message.CART_ITEM_STOCK_LABEL_FULL]: 'Maximum {quantity} in stock',
  [Message.CART_ITEM_STOCK_LABEL_FEW_IN_STOCK]: 'Few in stock',
  [Message.CART_MAXIMUM_TEXT]: 'Maximum',
  [Message.CART_ADDITIONAL_TEXT]:
    '*There may be some additional installation fees for some products',
  [Message.CHECKOUT_INFO_TITLE]: 'Checkout Info',
  [Message.CHECKOUT_ORDER_SUMMARY_TITLE]: 'Order summary',
  [Message.CHECKOUT_PERSONAL_DETAIL_TITLE]: 'Personal Details',
  [Message.CHECKOUT_PERSONAL_DETAIL_FULLNAME]: 'Full Name',
  [Message.CHECKOUT_SHIPPING_ADDRESS_TITLE]: 'Shipping address',
  [Message.CHECKOUT_YOUR_CART_TITLE]: 'Your Cart',
  [Message.BILLING_SHIPPING_ADDRESS_SAME_LABEL]:
    'Billing address same as shipping address',
  [Message.CHECKOUT_SUB_TOTAL_LABEL]: 'Subtotal',
  [Message.BUYCOIN_SUB_TOTAL_LABEL]: 'Sub-Total',
  [Message.CHECKOUT_VAT_LABEL]: 'VAT ({value})',
  [Message.CHECKOUT_TAX_LABEL]: 'TAX ({value})',
  [Message.CHECKOUT_SHIPPING_LABEL]: 'Shipping',
  [Message.CHECKOUT_DISCOUNT_LABEL]: 'Discount',
  [Message.CHECKOUT_TOTAL_LABEL]: 'Total',
  [Message.CHECKOUT_PROCEED_BUTTON]: 'Proceed',
  [Message.CHECKOUT_DISCOUNT_BUTTON]: 'Apply',
  [Message.CHECKOUT_ADD_NEW_ADDRESS_BUTTON]: 'Add New Address',
  [Message.CHECKOUT_ADD_NEW_ADDRESS_TEXT]: 'Add New Address',
  [Message.CHECKOUT_TOOLTIP_INVALID_NOTFOUND_TEXT]:
    'This code is invalid. Make sure you copied the entire code.',
  [Message.CHECKOUT_TOOLTIP_EXPIRED_TEXT]:
    'This code is expired. Please try a different code.',
  [Message.CHECKOUT_TOOLTIP_INVALID_MAX_USES_TEXT]:
    'This code has reached its maximum uses. Please try a different code.',

  [Message.CART_CHECKOUT_BUTTON]: 'Checkout',
  [Message.CART_PLACE_ORDER_BUTTON]: 'Place Order',
  [Message.CART_KEEP_SHOPPING_BUTTON]: 'Keep shopping',
  [Message.ADDED_TO_CART]: 'Added to cart',
  [Message.CREDIT_CARD_PROVIDER_TITLE]: 'We accept',
  [Message.CREDIT_CARD_PROVIDER_TEXT]:
    'Continue to the checkout with our secure service',
  [Message.CART_RESTRICTION_MODAL_TITLE]: 'Shopping restriction',
  [Message.CART_RESTRICTION_MODAL_MISSING_ITEMS_ERROR]:
    'Some items in your cart are missing',
  [Message.CART_RESTRICTION_MODAL_MISSING_ITEMS_SUBTITLE]:
    'You can edit your cart manually, or let us clean any missing items',
  [Message.CART_RESTRICTION_MODAL_CONTINUE_BUTTON]:
    'Continue without unavailable items',
  [Message.CART_RESTRICTION_MODAL_EDIT_CART_BUTTON]: 'Edit cart',
  [Message.CART_EMPTY_STATE_TITLE]: 'How come you don’t add any product yet?',
  [Message.CART_EMPTY_STATE_BUTTON]: 'Start shopping',
  [Message.FREE_DELIVERY_BUTTON]: 'Start shopping',
  [Message.CART_OUT_OF_STOCK_CAPTION]:
    'The cart of the selected property contains only out of stocked items, please change its content in order to continue.',
  [Message.CART_DELETE_CONFIRMATION_MODAL_TITLE]: 'Just before deleting...',
  [Message.CART_DELETE_CONFIRMATION_MODAL_CAPTION]:
    'Are you sure you want to delete this item?',
  [Message.CART_ALL_DELETE_CONFIRMATION_MODAL_CAPTION]:
    'Are you sure you want to delete all cart item?',
  [Message.CART_DELETE_CONFIRMATION_MODAL_OK]: 'Delete item',
  [Message.CHECKOUT_DISCOUNT_PLACEHOLDER]: 'Enter your code',
  [Message.CHECKOUT_TITLE]: 'Checkout Info',
  [Message.CHECKOUT_ITEM_QUANTITY_LABEL]: 'Quantity',
  [Message.CHECKOUT_SHIPPING_OPTIONS_TITLE]: 'Shipping options',
  [Message.CHECKOUT_STANDARD_SHIPPING_TITLE]:
    ' - Launch celebration - free shipping',
  [Message.CHECKOUT_LATE_SHIPPING_TITLE]:
    ' - Later delivery - choose when to get your delivery',
  [Message.CHECKOUT_SHIPPING_LABEL_DESCRIPTION]: 'Delivered on or before',
  [Message.CHECKOUT_LATE_SHIPPING_LABEL_DESCRIPTION]: 'Delivered on ',
  [Message.CHECKOUT_EXPRESS_SHIPPING_TITLE]: 'Express shipping',
  [Message.CHECKOUT_MISSING_ADDRESS_CAPTION]:
    'Please {action} to proceed with your purchase.',
  [Message.CHECKOUT_MISSING_ADDRESS_ACTION]: 'Add a shipping address',
  [Message.CHECKOUT_SUCCESS_CAPTION]:
    'We received your order! Feel free to go to the order page for more information.',
  [Message.CHECKOUT_SUCCESS_BUTTON]: 'My orders',
  [Message.BREADCRUMBS_HOME_BUTTON]: 'Home',
  [Message.USER_SHIPPING_ADDRESSES]: 'Shipping addresses',
  [Message.USER_DEFAULT_SHIPPING_ADDRESS]: 'Default delivery address',
  [Message.USER_SET_DEFAULT_ADDRESS_BUTTON]: 'Set as default delivery address',

  [Message.USER_DELETE_ACCOUNT_HEADING_1]: 'Delete your data and account',
  [Message.USER_DELETE_ACCOUNT_HEADING_2]:
    'Delete your account and your account data',
  [Message.USER_DELETE_ACCOUNT_BUTTON]: 'Delete account',
  [Message.USER_DELETE_ACCOUNT_GREETING_TEXT]: 'Hi',
  [Message.USER_DELETE_ACCOUNT_ENTER_EMAIL_SUBTITLE]:
    'Please enter the email address {email} to continue the deletion process',
  [Message.USER_DELETE_ACCOUNT_EMAIL_ADDRESS]: 'Email address',
  [Message.USER_DELETE_ACCOUNT_CANCEL_BUTTON]: 'Cancel',
  [Message.USER_DELETE_ACCOUNT_NEXT_BUTTON]: 'Next',
  [Message.USER_DELETE_ACCOUNT_DELETE_BUTTON]: 'Delete',
  [Message.USER_DELETE_ACCOUNT_SECOND_MODAL_TITLE]:
    'Please note that if you click Delete, your account will be permanently deleted and cannot be recovered.',
  [Message.USER_DELETE_ACCOUNT_SECOND_MODAL_SUBTITLE]:
    'If you do not want to Delete your account, click Cancel',

  [Message.USER_ADD_NEW_ADDRESS_BUTTON]: 'Add a new address',
  [Message.EDIT_BUTTON]: 'Edit',
  [Message.USER_DETAILS]: 'Hi {displayName}',
  [Message.ACCOUNT_SUGGESTED_PRODUCTS_TITLE]: 'Our product suggestions',
  [Message.ACCOUNT_SUGGESTED_PRODUCTS_EMPTY_STATE]: 'No products found',
  [Message.ACCOUNT_SUGGESTED_SKINS_TITLE]: 'Our skins suggestions',
  [Message.ACCOUNT_SUGGESTED_SKINS_EMPTY_STATE]: 'No skins found',
  [Message.PROFILE_INFO_BOX_TITLE]: 'Your personal details',
  [Message.PROFILE_INFO_BOX_DETAILS]:
    "While your email address keeps your unique user data at Patricia and it can't be changed, feel free to change your first/last name or your profile picture at any time..",
  [Message.PROFILE_INFO_BOX_SHIPPING_TITLE]: 'Shipping address',
  [Message.PROFILE_INFO_BOX_SHIPPING_DETAILS]:
    'Manage your addresses as you want. Add a new shipping address for your next order or delete an old one..',
  [Message.FIRST_NAME]: 'First name',
  [Message.LAST_NAME]: 'Last name',
  [Message.PHONE_NUMBER]: 'Phone number',
  [Message.EMAIL_TITLE]: 'Email',
  [Message.SAVE_TITLE]: 'Save',
  [Message.SAVE_CHANGES]: 'Save changes',
  [Message.DISCARD]: 'Discard',
  [Message.CANCEL]: 'Cancel',
  [Message.SUBMIT]: 'Submit',
  [Message.ADDRESS_FORM_EDIT]: 'Edit address',
  [Message.ADDRESS_FORM_EDIT_TO_SHIP]: 'Edit address to shipping',
  [Message.ADDRESS_FORM_EDIT_INFO_BOX_TEXT]:
    'Have you moved apartment or floor? Just want to update something? Please note that all data is correct so that you can receive your shipment exactly where you ordered it.',
  [Message.ADDRESS_FORM_ADD_INFO_BOX_TEXT]:
    'When you add a new address, you can set it to be your primary address for future orders. Don’t worry - You can add/remove addresses on any time.',
  [Message.ADDRESS_FORM_ADD_NEW_ADDRESS]: 'Add a new address',
  [Message.ADDRESS_FORM_COUNTRY]: 'Country',
  [Message.ADDRESS_FORM_SELECT_COUNTRY]: 'Select Country',
  [Message.ADDRESS_FORM_CITY]: 'City',
  [Message.ADDRESS_FORM_SELECT_CITY]: 'Select City',
  [Message.ADDRESS_FORM_STREET_ADDRESS]: 'Street address',
  [Message.ADDRESS_FORM_ADDRESS_LINE1]: 'Address Line 1',
  [Message.ADDRESS_FORM_ADDRESS_LINE2]: 'Address Line 2',
  [Message.ADDRESS_FORM_HOUSE_NO]: 'House No',
  [Message.ADDRESS_FORM_ENTRANCE]: 'Apartment Number',
  [Message.ADDRESS_FORM_FLOOR]: 'Floor',
  [Message.ADDRESS_FORM_CHECKBOX_ELEVATOR]: 'Elevator',
  [Message.ADDRESS_FORM_POSTCODE]: 'Postcode',
  [Message.ADDRESS_FORM_OPTIONAL]: 'Optional',
  [Message.ADDRESS_FORM_DEFAULT_ADDRESS]: 'Default delivery address',
  [Message.ADDRESS_FORM_DEFAULT_ADDRESS_NEW]: 'Set as default delivery address',
  [Message.ADDRESS_FORM_DELETE_ADDRESS]: 'Delete address',
  [Message.ADDRESS_FORM_BEFORE_LEAVE_DELETE_ADDRESS_TITLE]:
    'Just before deleting...',
  [Message.ADDRESS_FORM_BEFORE_LEAVE_DELETE_ADDRESS_TEXT]:
    'Are you sure you want to delete this address?',
  [Message.ADDRESS_FORM_CONFIRM_MODAL_CHANGES_TITLE]:
    'Would you like to save your changes?',
  [Message.ADDRESS_FORM_CONFIRM_MODAL_CHANGES_TEXT]:
    'We have detected that you have made changes.{linebreak} Are you sure you want to save them?',
  [Message.INPUT_NAME_ERROR]:
    'Name must contain English or hebrew letters only',
  [Message.CLIENT_AUTH_TITLE]: 'Sign up or login',
  [Message.CLIENT_AUTH_ONBOARDING_BUTTON]:
    'Create your first property & sign up',
  [Message.CLIENT_AUTH_ONBOARDING_TITLE]: `Hey, Nice to meet you!`,
  [Message.CLIENT_AUTH_ONBOARDING_GO_BUTTON]: `Let's Start`,
  [Message.CLIENT_AUTH_ONBOARDING_DESCRIPTION_1]: `I'm Patricia`,
  [Message.CLIENT_AUTH_ONBOARDING_DESCRIPTION_2]: `, the first virtual shopping assistant
  <br /> To get to know you better and your preferrences, I have
  created some<br/>questions that will help me to give you the`,
  [Message.CLIENT_AUTH_ONBOARDING_DESCRIPTION_3]: `Best result`,
  [Message.CLIENT_AUTH_ONBOARDING_UPLOAD_PLAN]: `Upload Home`,
  [Message.CHOOSE_PLAN_CONTAINER_TITLE_1]: `Upload your Home floor plan`,
  [Message.CHOOSE_PLAN_CONTAINER_TITLE_2]: `<span>O</span>R`,
  [Message.CHOOSE_PLAN_CONTAINER_TITLE_3]: `R`,
  [Message.CHOOSE_PLAN_CONTAINER_TITLE_4]: `Select from one of our demo floor plans`,
  [Message.CLIENT_AUTH_EMAIL_PASSWORD_BUTTON]: 'Login with email & password',
  [Message.PRODUCT_PAGE]: 'Products catalog',
  [Message.PRODUCT_PAGE_SEARCH]: 'Search what you want',
  [Message.TOUR_PLAYER_LOADING_TEXT]:
    "Loading... {linebreak} You're almost home",
  [Message.AUTH_LOGIN_SUBMIT_BUTTON]: 'Sign in',
  [Message.AUTH_LOGIN_FORGOT_PASSWORD_BUTTON]: 'Forgot password?',
  [Message.AUTH_LOGIN_CAPTION]:
    'To start using the patricia system you should log in by your email and password. Please insert your email address and password to continue.',
  [Message.AUTH_LOGIN_REMEMBER_ME]: 'Remember me',
  [Message.AUTH_LOGIN_EMAIL_FIELD]: 'Email address',
  [Message.AUTH_LOGIN_EMAIL_FIELD_PLACEHOLDER]: 'e.g example@patricia.com...',
  [Message.AUTH_REGISTRATION_VERIFY_EMAIL_FIELD]: 'Confirm email',
  [Message.AUTH_REGISTRATION_VERIFY_PASSWORD_FIELD]: 'Repeat password',
  [Message.AUTH_LOGIN_PASSWORD_FIELD]: 'Password',
  [Message.AUTH_LOGIN_PASSWORD_FIELD_PLACEHOLDER]:
    '8-10 letters including special characters...',
  [Message.AUTH_LOGIN_TITLE]: 'Welcome to Patricia',
  [Message.AUTH_REGISTRATION_READ_TERMS_AND_POLICY]:
    'I have read and agree to {termsOfUse} & {privacyPolicy}',
  [Message.AUTH_FORGOT_PASSWORD_PASSWORD_FIELD]: 'New password',
  [Message.AUTH_FORGOT_PASSWORD_VERIFY_FIELD]: 'Verify New password',
  [Message.AUTH_FORGOT_PASSWORD_TITLE]: 'Forgot password',
  [Message.AUTH_FORGOT_PASSWORD_INFO]:
    'To recover your password, please insert your email address.{linebreak}A reset link will be send to your email account.',
  [Message.AUTH_FORGOT_PASSWORD_BUTTON_LABEL]: 'Send',
  [Message.AUTH_FORGOT_PASSWORD_BACK_LINK]: 'Return to sign in',
  [Message.AUTH_RESET_LINK_SENT_TITLE]: 'Reset link was sent! {successIcon}',
  [Message.AUTH_RESET_LINK_SENT_INFO]:
    'Password reset link sent successfully to your address.{linebreak}Please check you email inbox and recover your password.',
  [Message.AUTH_RESET_PASSWORD_TITLE]: 'Enter a new password',
  [Message.AUTH_RESET_PASSWORD_BUTTON_LABEL]: 'Change password',
  [Message.AUTH_RESET_PASSWORD_SUCCESS_TITLE]:
    'Password was changed! {successIcon}',
  [Message.SHOP_BY_DEPARTMENT]: 'Shop by Department',
  [Message.ORDERS_TITLE]: 'My Orders',
  [Message.ORDERS_INFO_BOX_TITLE]: 'Orders',
  [Message.ORDER_TITLE_NUMBER]: 'Order #{idNumber}',
  [Message.ORDERS_INFO_BOX_TEXT]:
    'All of your order details, status, products, and ETAs for shipping in one place. Choose any of the orders, and click to dice in for the full details.',
  [Message.ORDER_DATE]: 'Order Date',
  [Message.ORDER_DATE_SHIPPING]: 'Shipping Date',
  [Message.ORDER_INFO_BOX_TITLE]: 'On the order page',
  [Message.ORDER_INFO_BOX_TEXT]:
    'Here is the summary of your order. You can find order information such as what products include, order status, shipping tracking number, and more. For any issue, please contact customer support.',
  [Message.ORDER_SHIPPING_TITLE]: 'Shipping',
  [Message.ORDER_PRODUCT_COLOR_LABEL]: 'Color',
  [Message.ORDER_SUMMARY]: 'Order Summary',
  [Message.DISCOUNT_CODE]: 'Discount',
  [Message.ORDER_TAXES]: 'Taxes',
  [Message.ORDER_DOWNLOAD_RECEIPT]: 'Download Invoice',
  [Message.ORDER_ITEMS]: 'Order Items',
  [Message.ORDER_DELIVERED_ON]: 'Delivered on ',
  [Message.ORDER_DELIVERY_ON]: 'Estimated delivery on ',
  [Message.ORDER_STATUS_WAITING_SHIPPING]: 'Waiting to ship',
  [Message.ORDER_STATUS_SHIPPING]: 'Shipped',
  [Message.ORDER_STATUS_ARRIVED]: 'Arrived',
  [Message.ORDER_STATUS_PENDING]: 'Pending',
  [Message.ORDER_STATUS_IN_PROGRESS]: 'In progress',
  [Message.ORDER_STATUS_ITS_DELIVERED]: 'It’s Delivered!',
  [Message.ORDER_STATUS_NOT_APPROVED]: 'Not approved',
  [Message.ORDER_STATUS_IN_DISPUTE]: 'In dispute',
  [Message.THINGS_THAT_ARE_ALWAYS_WORTHWHILE_AT_HOME]:
    'Things that are always worthwhile at home',
  [Message.PATRICIA_BEST_RECOMMENDED]:
    'Patricia’s best recommended products for you',
  [Message.SKINS_TITLE]: 'Skins',
  [Message.SKINS_HEADER_TITLE]: 'Meet Patricia <span>Skins</span>',
  [Message.SKINS_HEADER_TITLE_MOBILE]: 'Meet Patricia <span>Skins</span>',
  [Message.SINGLE_SKINS_HEADER_TITLE]: 'About the {value}',
  [Message.SINGLE_SKINS_HEADER_SUB_TEXT_FOR_WEB]: `At {name} we believe in designing homes which are beautiful to look at and comfortable to live in.{linebreak}
  Inspired by lifestyles across the globe - from NYC to London all the way to Tokyo - we give these cultures expression with a local Israeli flair.{linebreak} 
  Each component in our designs balances one another, making use of every crevasse from floor to ceiling, with great attention to detail.{linebreak}
  We love layering materials and textures, creating a sense of three dimensionality, and using calming color palettes which allow for personalized aesthetics to shine through. Moved by precise architecture that evokes feelings and combining creativity with organization, we constantly search for what is different, distinctive and new.`,
  [Message.SINGLE_SKINS_HEADER_SUB_TEXT_FOR_WEB_READ_LESS]: `At {name} we believe in designing homes which are beautiful to look at and comfortable to live in.{linebreak}
  Inspired by lifestyles across the globe - from NYC to London all the way to Tokyo - we give these cultures expression with a local Israeli flair.`,
  [Message.SINGLE_SKINS_ABOUT_TITLE]: 'About this skin',
  [Message.SINGLE_SKINS_ABOUT_SUB_TITLE]:
    'We want to make your life easy and designed.{linebreak} Therefore, we have gathered right here all the design choices we make for{linebreak} our clients. Now all you have to do is see how everything fits into your{linebreak} home.',
  [Message.SINGLE_SKINS_SKIN_NAME]: 'Skin name: {name}',
  [Message.SINGLE_SKINS_ABOUT_PRICE]: 'Price: {price}',
  [Message.SINGLE_SKINS_PRODUCTS_SKIN]:
    'Products that you can find in this Skin',
  [Message.SINGLE_SKINS_FREE]: 'Free',
  [Message.SINGLE_SKINS_ABOUT_THE_DESIGNER]: 'About the designer',
  [Message.SINGLE_SKINS_PRODUCTS_IN_THIS_SKIN]: 'Products in this skin',
  [Message.SINGLE_SKINS_READ_MORE]: 'Read More',
  [Message.SINGLE_SKINS_READ_LESS]: 'Read Less',
  [Message.SKINS_HEADER_TEXT_BOLD]:
    'For the first time in the world, you can dress up your home in a click of a button! ',
  [Message.SKINS_HEADER_TEXT]:
    'The amazing interior designers who work with us, influencers in the field and leading brands have created for you skins - complete interior designs for the home that include furniture and accessories in various design styles and full of inspiration.',
  [Message.SKINS_HEADER_TEXT_FOR_WEB]:
    'For the first time in the world, you can redesign your space with special{linebreak} Skins made by top interior designers, architects, and influencers{linebreak} <span>Just pick up a Skin you like and apply it in your Patricia 3D </span>',
  [Message.SKINS_HEADER_TEXT_FOR_MOBILE]:
    'For the first time in the world, you can{linebreak} redesign your space with special{linebreak} Skins made by top interior designers,{linebreak} architects, and influencers <span>Just pick up a Skin you like and apply it in your Patricia 3D </span>',
  [Message.SKINS_HEADER_SUB_TEXT_FOR_WEB]:
    'Dress up your home like an Interior designer in a<a>click of a button!</a>',
  [Message.SKINS_SHOW_MORE_BUTTON]: 'Show more',
  [Message.SKIN_CARD_PRICE]: 'Price',
  [Message.SKIN_CARD_PRICE_FREE]: 'Available Now',
  [Message.SKIN_DESIGNED_BY_TEXT]: 'Skin design by {value}',
  [Message.SKIN_NOT_FOUND]: 'Skin not found',
  [Message.SKIN_NO_DESCRIPTION_AVAILABLE]: 'No description available.',
  [Message.SKIN_GOOD_TO_KNOW_TITLE]: 'Good to know',
  [Message.SKIN_APPLY_SKIN]: 'Apply skin',
  [Message.SKIN_BUY_NOW]: 'Buy now',
  [Message.SKIN_FILTER_BY_BUDGET]: 'Budget',
  [Message.SKIN_FILTER_BY_DESIGNED_BY]: 'Design By',
  [Message.SKIN_FILTER_BY_DESIGN_STYLE]: 'Design Style',
  [Message.SKIN_FILTER_BY_POPULAR]: 'Popular',
  [Message.SKIN_FILTER_BY_ROOM]: 'Room',
  [Message.SKIN_FILTER_BY_SORT]: 'Sort',
  [Message.SKIN_FILTER_BY_COLOR]: 'Color',
  [Message.SKIN_FILTER_MODAL_TITLE]: 'Filter',
  [Message.SKIN_FILTER_MODAL_ALL]: 'ALL',
  [Message.SKIN_FILTER_MODAL_CLEAR]: 'Clear',
  [Message.SKIN_FILTER_MODAL_SHOW_FILTER]: 'Show results',
  [Message.SKIN_MORE_FROM]: 'More from {user}',
  [Message.SKIN_FULL_HOUSE]: 'Full House',
  [Message.SKIN_LOCK_TEXT]:
    'Products will show just after you{linebreak} purchase this skin',
  [Message.SKIN_BUY_IT_NOW]: 'Buy it now',
  [Message.SKINS_YOU_MAY_ALSO_LIKE]: 'You may also like',
  [Message.SUPPLIER_HEADER_VIDEO]: 'watch the video',
  [Message.SUPPLIER_HEADER_BUTTON]: `Let's Talk`,
  [Message.SUPPLIER_HEADER_MAIN_TITLE]: 'Already in Patricia Marketplace',
  [Message.SUPPLIER_HEADER_TITLE]: 'Join The',
  [Message.SUPPLIER_HEADER_TITLE_CAPTION]: 'With Patricia.',
  [Message.SUPPLIER_HEADER_TITLE_CAPTION_BOLD]: '3D Commerce Revolution',
  [Message.SUPPLIER_INTEGRATION_WEEK]: 'week',
  [Message.GET_STARTED_SUPPLIER]: 'GET STARTED',
  [Message.SUPPLIER_ACTION_BUTTON]: 'Become a seller - TODAY',
  [Message.SUPPLIER_OFFERS_MAIN_TITLE]:
    'What will my customer gain from working with Patricia?',

  [Message.TOUR_INSTRUCTIONS_MODAL_FIRST_PAGE_TITLE]: 'How to navigate?',
  [Message.TOUR_INSTRUCTIONS_MODAL_CLOSE_BUTTON_TEXT]: 'Continue',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_WALK]: 'Walk',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN]: 'Design',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL]: 'General',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_WALK1]:
    'Right Click and drag the mouse to look around',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_WALK2]:
    'Double click on any surface to move to it',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_WALK3]: 'Scroll to Zoom In/Out',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_WALK4]:
    'Or use the arrow keys to turn right and left',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_WALK5]:
    'Or use the arrow keys to move forward and back',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN1]: 'Shopping Cart',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN2]: '3D Preview',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN3]: 'Rotation',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN4]: 'Info',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN5]: 'Duplicate',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN6]: 'Delete Item',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_DESIGN1]:
    'Click this button to add the selected product to your cart',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_DESIGN2]:
    'Click to see the product up close',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_DESIGN3]:
    'Use the slider to rotate the selected product',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_DESIGN4]:
    'Click to see the detailed information of the selected object',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_DESIGN5]:
    'Click to make a copy of the selected object',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_DESIGN6]:
    'Click to delete the selected object',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL1]: 'Patricis Skins',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL2]: 'Add Item',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL3]: 'Shopping Cart',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL4]: 'View Settings',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL5]: 'Mini Map',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL6]: 'Screenshot',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL7]: 'Save',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL8]: 'Settings',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL9]: 'Undo',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL10]: 'Redo',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL11]: 'Caracter Height',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL12]: 'Back to Start',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL13]: 'Dollhouse',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL14]: 'Measurements',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL1]:
    'Chose one of the skins created by Patricia Professionals to see it in your home',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL2]:
    'Search for any furniture and drag it into your home',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL3]:
    'Click this button to add the selected product to your cart',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL4]:
    'Sunlight | Artificial lights | Outside View',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL5]:
    'Show the floor plan of the home. Drag the character on the map to move in 3D space',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL6]:
    'Take a screenshot of the current view',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL7]: 'Save all changes',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL8]:
    'Control image quality, speed of movement & measurment unit',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL9]:
    'Click to undo the last action',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL10]:
    'Click to redo the last undone action',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL11]:
    'Change the height of the camera',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL12]:
    'Move back to the starting position (entrance door)',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL13]:
    'Look at your home from a top-down view',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL14]:
    'There are 3 measurement options. Press this button and chose one of them',
  [Message.TOUR_INSTRUCTIONS_MODAL_SECOND_PAGE_TITLE]:
    'How to change a product?',
  [Message.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_TITLE]:
    'How do I add/search for a new product?',
  [Message.TOUR_INSTRUCTIONS_MODAL_FOURTH_PAGE_TITLE]: 'How to apply a skin?',
  [Message.TOUR_INSTRUCTIONS_MODAL_FIFTH_PAGE_TITLE]:
    'How to save the entire design?',
  [Message.TOUR_INSTRUCTIONS_MODAL_SIXTH_PAGE_TITLE]:
    'General settings and other features',
  [Message.TOUR_INSTRUCTIONS_MODAL_FIRST_PAGE_BULLET_ONE]:
    'Right click with your mouse to drag the camera around and change your angle of view.',
  [Message.TOUR_INSTRUCTIONS_MODAL_FIRST_PAGE_BULLET_TWO]:
    'Use the arrow keys on your keyboard to walk inside your tour.',
  [Message.TOUR_INSTRUCTIONS_MODAL_SECOND_PAGE_BULLET_ONE]:
    'Click/tap on any furniture you would like to replace.',
  [Message.TOUR_INSTRUCTIONS_MODAL_SECOND_PAGE_BULLET_TWO]:
    'After clicking, you will get a list of similar products which can replace your current selected item by clicking the replacement choice.',
  [Message.TOUR_INSTRUCTIONS_MODAL_SECOND_PAGE_BULLET_THREE]:
    'You can also search for a different product to replace from the left search menu.',
  [Message.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_ONE]:
    'Click/tap on the search button to start searching for products from different categories.',
  [Message.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_TWO]:
    'Choose your desired category, then choose a subcategory to see the available products.',
  [Message.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_THREE]:
    'Drag the product you want to the scene, and place it wherever you like.',
  [Message.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_FOUR]:
    'You can also search for a product by name, SKU or tags inside each subcategory.',
  [Message.TOUR_INSTRUCTIONS_MODAL_FOURTH_PAGE_BULLET_ONE]:
    'Click on the main red button in the middle of the header.',
  [Message.TOUR_INSTRUCTIONS_MODAL_FOURTH_PAGE_BULLET_TWO]:
    'Choose a skin to apply for free or buy our premium skins from top designers and influencers.',
  [Message.TOUR_INSTRUCTIONS_MODAL_FIFTH_PAGE_BULLET_ONE]:
    'Just click on the "Save" button',
  [Message.TOUR_INSTRUCTIONS_MODAL_SIXTH_PAGE_BULLET_ONE]:
    'You can control and change the different settings of the tour by clicking on the sprocket button at the top right of the screen.',
  [Message.TOUR_INSTRUCTIONS_MODAL_SIXTH_PAGE_BULLET_TWO]:
    'The app has many other interesting and useful features. Take a few minutes to explore the various buttons in the UI and see what they do.',
  [Message.TOUR_INSTRUCTIONS_MODAL_DO_NOT_SHOW_AGAIN_CHECKBOX]:
    "Don't show next time",
  [Message.TOUR_INSTRUCTIONS_MODAL_DONE_BUTTON]: 'Done',
  [Message.SHOP_BEST_SELLERS]: 'Shop best sellers',
  [Message.FILE_DROP_ZONE_TITLE]: 'Upload file',
  [Message.FILE_DROP_ZONE_DESCRIPTION]:
    'To get the best result we allow you to upload files PDF / PNG / JPEG, up to 10MB or 4 pages.',
  [Message.UPLOAD_PLAN_CONTAINER_TITLE]: '1. Upload your floor plan',
  [Message.CHOOSE_PLAN_CONTAINER_TITLE]: '1. Choose your floor plan',
  [Message.MOBILE_UPLOAD_PLAN_CONTAINER_TITLE]: 'Upload your ',
  [Message.MOBILE_UPLOAD_PLAN_CONTAINER_BOLD_TITLE]: 'own ',
  [Message.MOBILE_UPLOAD_PLAN_CONTAINER_FLOOR_PLAN_TITLE]: 'floor plan',
  [Message.MOBILE_CHOOSE_PLAN_CONTAINER_TITLE]:
    '1.Choose a pre-made floor plan',
  [Message.MOBILE_UPLOAD_PLAN_CONTAINER_BOLD_TITLE_HEBREW]: ' your',
  [Message.MOBILE_UPLOAD_PLAN_CONTAINER_TITLE_HEBREW]:
    'Upload the apartment plan',
  [Message.CHOOSE_PLAN_CONTAINER_TEXT]:
    'Don’t have a floor plan? Don’t worry:){linebreak}Just choose one of the pre-made houses and enjoy{linebreak}Patricia’s design experience',
  [Message.CHOOSE_PLAN_TEXT_1]: 'I don’t have a floorplan,',
  [Message.CHOOSE_PLAN_TEXT_2]: 'what should I do?',
  [Message.PLAN_BEDROOMS]: '{name} Bedrooms',
  [Message.UPLOAD_PLAN_CONTAINER_TEXT]:
    'In order for Patricia to be able to create a 3D model of your home,{linebreak} please upload a floor plan that is as accurate and clear as possible.',
  [Message.UPLOAD_PLAN_ALREADY_USER]: 'Already a user?',
  [Message.UPLOAD_PLAN_LOGIN_LINK]: 'Login',
  [Message.SELECT_FLOOR_CAPTION]:
    'We found that your file contains more than one page. Please select the desired page',
  [Message.UPLOAD_FILE_SUCCESS]: '2. The file was uploaded successfully!',
  [Message.UPLOAD_LETS_START_TITLE]:
    "Let's start the questionnaire to get Patricia to know you better :)",
  [Message.UPLOAD_LETS_START_BUTTON]: "Let's start",
  [Message.UPLOAD_HELP_PATRICIA_TITLE]:
    '3. Help Patricia understand your design style',
  [Message.UPLOAD_CHOOSE_YOUR_STYLE_TITLE]:
    'Choose the image you <span>like</span> the most in every question',
  [Message.UPLOAD_BUDGET_QUESTION]: 'Set your <span>Budget</span>',
  [Message.UPLOAD_BUDGET_BUTTON]: 'Select budget',
  [Message.UPLOAD_ROOMS_QUESTION]:
    'Choose the rooms you would like{linebreak}to <span>Design</span>',
  [Message.UPLOAD_ROOM_QUESTION]:
    'Choose the room you would like{linebreak}to <span>Design</span>',
  [Message.DESIGN_STYLES_QUESTION]: 'Choose your <span> Design style</span>',
  [Message.UPLOAD_COLOR_QUESTION]: 'Choose your <span>Color palette</span>',
  [Message.UPLOAD_THANKS_FOR_QUIZ_TITLE]:
    '4. Thank you for helping Patricia understand your design style!',
  [Message.UPLOAD_WERE_DONE_TITLE]: "We're done!",
  [Message.UPLOAD_WERE_DONE_TEXT]:
    'Patricia is now creating and designing your 3D home.{linebreak}We will update you as soon as it is ready. ',
  [Message.EMAIL_GO_TO_PROMOTIONS]:
    '*the email may go into the Promotions folder of your email*',
  [Message.UPLOAD_TAKE_24_TEXT]: 'It could take up to 24 hours.',
  [Message.ONBOARDING_REGISTRATION_TITLE]: '<span>Key</span> creation',
  [Message.ONBOARDING_REGISTRATION_SUBTITLE]:
    'Just a few more details before you get your 3D home',
  [Message.UPLOAD_BUTTON_BACK_TO_HOME]: 'Back to home page',
  [Message.BROWSE_PRODUCT_CATALOG]: 'See our Products Catalog',
  [Message.START_DEMO_TOUR]: 'Start Demo Tour',
  [Message.DONE_GOTO_EXAMPLE_HOME_TEXT]:
    'In the meantime, we are inviting you to take a tour of our example 3D home',
  [Message.ADD_TO_CART]: 'Add to cart',
  [Message.PRODUCT_MORE_FROM]: 'More from {cat}',
  [Message.HOME_SECTION_HEADER_1]:
    'Upload your home floor plan in a PDF or PNG format',
  [Message.HOME_SECTION_HEADER_2]: 'Find your inspiration',
  [Message.HOME_SECTION_HEADER_3]:
    'Start a new shopping experience in your new virtual home for free',
  [Message.HOME_SECTION_TEXT_2]:
    'Simply select photos that make you feel at home. Patricia will match your personal style and build your creative direction.',
  [Message.HOME_SECTION_TEXT_3]:
    'For the first time ever, Patricia will take you on a digital tour and present you a 3D virtual reality version of your home',
  [Message.NO_PRODUCT_AVAILABLE]: 'No product available',
  [Message.VIEW_DETAILS]: 'View Details',
  [Message.CLEAR_CART]: 'Clear Cart',
  [Message.NO_CATEGORY]: 'No category available',
  [Message.NO_SUB_CATEGORY]: 'No sub-category available',
  [Message.FREE_SHIPPING]: 'Launching celebration - Free shipping!',
  [Message.GET_IT_BY]: 'Get it by',
  [Message.SHIP_TO]: 'Ship to',
  [Message.GOOD_TO_KNOW]: 'Good to know',
  [Message.DIMENSIONS]: 'Dimensions',
  [Message.DESCRIPTION]: 'Description',
  [Message.HEIGHT_CM]: 'Height:',
  [Message.LENGTH_CM]: 'Length:',
  [Message.DEPTH_CM]: 'Depth:',
  [Message.HEIGHT_INCH]: 'Height:',
  [Message.LENGTH_INCH]: 'Length:',
  [Message.DEPTH_INCH]: 'Depth:',
  [Message.CATEGORIES]: 'Categories',
  [Message.SUB_CATEGORIES]: 'Sub Categories',
  [Message.PRODUCTS]: 'Products',
  [Message.BRANDS]: 'Brands',
  [Message.NO_RESULT]: 'No result available',
  [Message.THREED_BUTTON_TEXT]: 'See Product in 3D',
  [Message.TOUR_DEVICE_DENIED_HEADING]: 'Your device is not supported',
  [Message.TOUR_SUPPORTED_DEVICE_TITLE]:
    'please make sure you are using one of the supported devices and browsers as following:',
  [Message.TOUR_SUPPORTED_DEVICES]: 'Mac | Laptop | PC',
  [Message.TOUR_SUPPORTED_BROWSER]: 'Chrome | Firefox',
  [Message.TOUR_WE_ARE_SORRY]: 'We are sorry,',
  [Message.TOUR_ADD_ENTIRE_ROOM_TO_CART]: 'Add Entire Room',
  [Message.DELETE_PROPERTY_TEXT]:
    'Are you sure you want to delete this property?',
  [Message.GO_TO_MY_PROPERTIES]: 'Go to my properties',
  [Message.PROPERTY_NOTIFICATION_MODAL_TITLE]: 'Your new 3D home is ready',
  [Message.WEBSITE_ACCESSIBILITY_TITLE]:
    'Guide to Building an Accessibility Arrangements Page - Auto Accessibility Component',
  [Message.WEBSITE_ACCESSIBILITY_INTRO_TITLE]: 'Introduction',
  [Message.WEBSITE_ACCESSIBILITY_INTRO_SECTION]:
    '<p>The Internet is today the largest repository of freedom of information for all users, and users with disabilities in particular. {linebreak}As such, we place great importance on providing equal opportunity for people with disabilities to use the information displayed on the site, and enable a better browsing experience. {linebreak}We strive to ensure that digital services are accessible to people with disabilities, and therefore many resources have been invested in facilitating the use of the site for people with disabilities, as much as possible, in the belief that every person deserves the right to live in equality, dignity, comfort and independence. </p><p>In order to fulfill this promise, we strive to adhere as much as possible to the recommendations of the Israeli Standard (T.I. 5568) for accessibility of content on the Internet at AA level and the international WCAG2.1 document.</p>',
  [Message.WEBSITE_ACCESSIBILITY_SITE_ACCESSABILITY_TITLE]:
    'How does site accessibility work?',
  [Message.WEBSITE_ACCESSIBILITY_SITE_ACCESSABILITY_SECTION]:
    '<p>The website has an accessibility menu of a website accessibility company - accessible with a click. Pressing the menu allows you to open the accessibility buttons. After selecting a menu item, wait for the page to load.</p><p>The software works in popular browsers: Chrome, Firefox, Safari, Opera. The responsibility for the use and application of the website applies to the owners of the website and / or those on its behalf, including the content displayed on the website, subject to the restrictions and conditions of use of the software.</p>',
  [Message.WEBSITE_ACCESSIBILITY_MENU_TITLE]:
    'Accessibility option in the menu:',
  [Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_1]:
    'Blocking the Flickers - Stopping moving elements and blocking the flickers',
  [Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_2]:
    'Increase the site font to 5 different sizes',
  [Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_3]:
    'Adapting the site to a screen reader - Adapting the site for assistive technologies such as NVDA, JAWS',
  [Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_4]:
    'Adaptation for keyboard navigation - Enabling keyboard navigation',
  [Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_5]:
    'Change the font to more readable',
  [Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_6]:
    'Website adaptation for color blind people',
  [Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_7]:
    'Contrast adjustment - change color contrast based on a light background',
  [Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_8]:
    'Contrast adjustment - change the color contrast based on a dark background',
  [Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_9]:
    'Highlighting headlines on the site',
  [Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_10]:
    'Highlighting links on the site',
  [Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_11]:
    'Increase the display to about 200%',
  [Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_12]:
    'Enlarge the cursor and change its color to black or white',
  [Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_13]:
    'Sending accessibility feedback',
  [Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_14]:
    'Declaration of accessibility',
  [Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_15]:
    'Displays an alternative description for images',
  [Message.WEBSITE_ACCESSIBILITY_COORDINATOR_TITLE]:
    'Contact the accessibility coordinator',
  [Message.WEBSITE_ACCESSIBILITY_COORDINATOR_DESC]:
    '<p>if while browsing the site you have encountered difficulties regarding accessibility, the company`s accessibility staff is at your disposal in a variety of channels for accessing issues, we will be happy to receive feedback from you.</p>{linebreak}Details of the company`s accessibility coordinator.{linebreak}',
  [Message.WEBSITE_ACCESSIBILITY_COORDINATOR_FIELD_NAME]: 'Name : ',
  [Message.WEBSITE_ACCESSIBILITY_COORDINATOR_FIELD_TELEPHONE]: 'Telephone : ',
  [Message.WEBSITE_ACCESSIBILITY_COORDINATOR_FIELD_EMAIL]: 'Email : ',
  [Message.WEBSITE_ACCESSIBILITY_COORDINATOR_FIELD_MAIL_ADDRESS]:
    'Mailing address : ',
  [Message.WEBSITE_ACCESSIBILITY_ARRANGEMENTS_DEPANDANCY_TITLE]:
    'Publication of accessibility arrangements in the company (depending on the organization, its nature and services)',
  [Message.IDEA_PRICE_TAG_LABEL]: 'Turn On / Off to show Price tag',
  [Message.IDEA_TAB_TITLE_TAB1]: 'You may also like',
  [Message.IDEA_TAB_TITLE_TAB2]: 'Product in this image',
  [Message.IDEA_TAB_TOUR_BUTTON]: 'Enter this home',
  [Message.IDEA_IMAGE_TITLE]: 'Photo - Tekimport modern white bathroom',
  [Message.IDEA_DESIGNED_BY_LABEL]: 'Designed by -',
  [Message.IDEA_START_TOUR_BUTTON]: 'Start a Tour',
  [Message.IDEA_BRAND_IMAGE_TITLE]: 'Brands you will find inside ',
  [Message.IDEAS_TITLE]: 'Ideas',
  [Message.IDEAS_NOT_FOUND]: 'Ideas not found',
  [Message.IDEAS_PRODUCT_TITLE]: 'Products in this image {count}',

  [Message.SKINS_SORT_NEW]: 'Newest',
  [Message.SKINS_SORT_POPULAR]: 'Popularity',
  [Message.SKINS_SORT_PRICE_HIGH]: 'Price high to low',
  [Message.SKINS_SORT_PRICE_LOW]: 'Price low to high',

  [Message.SKINS_FILTER_COLOR_RED]: 'Red',
  [Message.SKINS_FILTER_COLOR_BLACK]: 'Black',
  [Message.SKINS_FILTER_COLOR_BLUE]: 'Blue',
  [Message.SKINS_FILTER_COLOR_GREY]: 'Grey',
  [Message.SKINS_FILTER_COLOR_YELLOW]: 'Yellow',
  [Message.SKINS_FILTER_COLOR_ORANGE]: 'Orange',
  [Message.SKINS_FILTER_COLOR_PURPLE]: 'Purple',
  [Message.SKINS_FILTER_COLOR_PINK]: 'Pink',
  [Message.SKINS_FILTER_COLOR_BROWN]: 'Brown',
  [Message.SKINS_FILTER_COLOR_GREEN]: 'Green',
  [Message.BUY_PATRICIA_COINS_TITLE]:
    'Hi {name} , your current balance standing on {count}',
  [Message.BUY_PATRICIA_COINS_BUTTON_TITLE]:
    'Patricia coins - {count} coins (buy more)',

  [Message.ORDER_PDF_ORDER_SUMMARY_FOR]: 'Order summary for',
  [Message.TAKE_A_LOOK]: 'Take a look',
  [Message.ONBOARDING_HOME_DESCRIPTION]:
    "<span>I'm Patricia</span>, your virtual shopping and design assistant.{linebreak} In order to create the <span>perfect 3D home</span> for you, I’ll ask you some{linebreak}questions that will help me create your digital key in a few minutes.",
  [Message.ONBOARDING_HOME_DESCRIPTION_MOBILE]:
    "<span>I'm Patricia</span>,{linebreak}your virtual shopping and design assistant.{linebreak} In order to create the <span>perfect 3D home</span> for you,{linebreak}I’ll ask you some questions that will help me{linebreak}create your digital key in a few minutes.",
  [Message.ONBOARDING_UPLOAD_YPUR_PLAN]: 'Upload your Home floor plan',
  [Message.ONBOARDING_UPLOAD_NOTICE]:
    'To get the best result we allow you to upload files{linebreak}PDF / PNG / JPEG{linebreak}up to 10MB or 4 pages',
  [Message.ONBOARDING_FLOOR_PLAN]: 'Floor plan',
  [Message.ONBOARDING__SELECTED_PREMADE_MESSAGE]:
    'You have selected the{linebreak}<span> {name} room demo floor plan</span>',
  [Message.ONBOARDING_PLAN_UPLOAD_SUCCESS]:
    'Your plan has been <span>successfully uploaded</span>',
  [Message.ONBOARDING_AMOUNT_OF_MONEY_TO_SPEND]:
    'Set the amount of money you want to spend{linebreak}on this project',
  [Message.CHOOSE_IMAGE_SUBTEXT]: 'Please select one of the images',
  [Message.SELECT_ONE_COLOR]: 'You can only select one color palette',
  [Message.YOUR_MOODBOARD]: 'Your Moodboard',
  [Message.KEY_CONFIRMATION_STARTED_WORKING]:
    'I have started working on your <span>Home</span>',
  [Message.CREATE_DIGITAL_KEY]:
    'To keep your 3D HOME secure and private, we’ll need to create a <span>Digital Key</span>',
  [Message.LETS_MAKE_KEY_BUTTON]: "Let's make a key",
  [Message.ONBOARDING_LEAVE_CINFIRM_TITLE]: 'Are you sure?',
  [Message.ONBOARDING_LEAVE_CONFIRM_SUBTITLE1]:
    "You're about to leave in the middle of the process",
  [Message.ONBOARDING_LEAVE_CONFIRM_SUBTITLE2]:
    ' If you leave, the process will not be saved',
  [Message.OONBOARDING_LEAVE_CONFIRMATION_STAY_BUTTON]: ' I want to stay',
  [Message.OONBOARDING_LEAVE_CONFIRMATION_LEAVE_BUTTON]: 'Leave now',
  [Message.ONBOARDING_TOOLTIP_STEP_1]: 'Step 1',
  [Message.ONBOARDING_TOOLTIP_DESIGN_STYLE_STEP_6]: 'Step 2',
  [Message.ONBOARDING_TOOLTIP_FLOOR_PLAN_SELECT]: 'Floor plan select',
  [Message.ONBOARDING_TOOLTIP_DESIGN_STYLE_SELECT]: 'Floor plan select',
  [Message.ONBOARDING_TOOLTIP_FLOOR_ROOM_SELECT]: 'Choose rooms to design',
  [Message.ONBOARDING_TOOLTIP_BUDGET_SELECT]: 'Set your budget',
  [Message.ONBOARDING_TOOLTIP_BUDGET]: 'Budget',
  [Message.ONBOARDING_TOOLTIP_CONFIRMATION]: 'Confirmation',
  [Message.ONBOARDING_TOOLTIP_MAKE_KEY]: 'Make a key',
  [Message.ONBOARDING_TOOLTIP_COLOR_SELECT]: 'Color palette',
  [Message.ONBOARDING_TOOLTIP_DESIGN_SELECT]: 'Design Quiz',
  [Message.ONBOARDING_TOOLTIP_DESIGN_STYLE]: 'Step 2',
  [Message.FINISH]: 'Finish',
  [Message.ONBOARDING_YOUR_MOODBOARD]: 'Your Moodboard',
  [Message.MOODBOARD_DESCRIPTION]:
    'Thank you for helping Patricia understand your design style',
  [Message.ONBOARDING_DONE_KEY_CREATED_SUCCESSFULL]:
    'Your key was {linebreak} created successfully',
  [Message.ONBOARDING_DONE_SUBTITLE1]:
    'I will personally update you when your property is ready',
  [Message.ONBOARDING_DONE_EMAIL_NOTICE]:
    '(If you don\'t receive a message within <span>24 hours</span>, check your "Promotions" folder)',
  [Message.ONBOARDING_DONE_SUBTITLE2]: "Can't wait?",
  [Message.ONBOARDING_DONE_SUBTITLE]:
    'Check out the <span>Ideas</span> we prepared for you in advance',
  [Message.ONBOARDING_DONE_SUBTITLE_MOBILE]:
    'Check out the <span>Ideas</span> we prepared for you{linebreak}in advance',
  [Message.ONBOARDING_DONE_RETURN_HOME_BUTTON]: 'Return to Home Page',
  [Message.ONBOARDING_DONE_KEY_OWNER]: 'Key Owner:',
  [Message.ONBOARDING_DONE_PROPERTY_NUMBER]: 'Property number:',
  [Message.ONBOARDING_PATRICIA_GIFT]: 'A welcome gift from Patricia:',
  [Message.ONBOARDING_DONE_COIN_NOTICE]: '*Used to create HD images',
  [Message.SELECT_ALL]: 'Select All',
  [Message.CLIENT_FIRST_NAME]: 'First Name*',
  [Message.CLIENT_LAST_NAME]: 'Last Name*',
  [Message.CLIENT_EMAIL_TITLE]: 'Email*',
  [Message.CLIENT_PHONE_NUMBER_TITLE]: 'Phone Number',
  [Message.CLIENT_PASSWORD]: 'Client Password:',
  [Message.PROPERTIES_SEARCH_BUTTON]: 'Search',
  [Message.PROPERTIES_SEARCH_PLACEHOLDER]: 'Search by property name',
  [Message.CLIENT_EMAIL_ERROR]:
    'Email is already in use, please enter a new email.',
  [Message.CLIENT_PHONE_ERROR]: 'The phone number entered is not valid.',
  [Message.GO_TO_IDEAS_BUTTON]: 'Go to Ideas',
  [Message.ONBOARDING_PLAN_UPLOAD_RESOLUTION_TITLE]:
    'The image you selected{linebreak}is too big',
  [Message.ONBOARDING_PLAN_UPLOAD_RESOLUTION_SUBTITLE]:
    'Please choose another file',
  [Message.ONBOARDING_PLAN_UPLOAD_RESOLUTION_NOTICE_DESKTOP]:
    'Maximum accepted height or width is 10000 and {linebreak} aspect ratio should be 3:1',
  [Message.ONBOARDING_PLAN_UPLOAD_RESOLUTION_NOTICE_MOBILE]:
    'Maximum acceptedheight or width is{linebreak}10000 andaspect ratio should be 3:1',
  [Message.ONBOARDING_PLAN_UPLOAD_RESOLUTION_OK_BUTTON]: 'OK, got it',
  [Message.REGISTRATION_PHONE_NUMBER]: 'Phone Number',
  [Message.IDEAS_CARD_VIEWS]: '{viewCount} views',
  [Message.ONBOARDING_QUIZ_PANORAMA_QUESTION]: 'Select your <span>View</span>',
  [Message.UPDATE_AVAILABLE_TITLE]: 'New update available',
  [Message.UPDATE_AVAILABLE_DESCRIPTION]:
    "Please make sure you're using the updated version of the app as old version is no longer supported",
  [Message.UPDATE_BUTTON_TEXT]: 'Update',

  [Message.LANDING_PAGE_HEADING]: 'Lets design your home for free',
  [Message.LANDING_PAGE_UPLOAD_FLOOR_PLAN_BUTTON]: 'Upload Floor Plan',
  [Message.LANDING_PAGE_TEXT]:
    'רוצים לראות איך {linebreak} הרהיטים החדשים {linebreak} משתלבים אצלכם בבית?',
  [Message.LANDING_PAGE_TEXT_MOBILE]:
    'לא מצליחים לדמיין איך{linebreak} הרהיטים החדשים משתלבים {linebreak}אצלכם בבית?',
  [Message.LANDING_PAGE_SUB_TEXT]:
    'חדש ברשת ID DESIGN, מערכת תלת מימד שמדמה{linebreak} בדיוק כיצד הרהיטים החדשים ייראו אצלכם בבית',
  [Message.LANDING_PAGE_SUB_TEXT_MOBILE]:
    'חדש ברשת ID DESIGN, מערכת תלת מימד שמדמה{linebreak} בדיוק כיצד הרהיטים החדשים ייראו אצלכם בבית',
  [Message.LANDING_PAGE_TEXT_POINT_1]:
    'Leave your details and upload a floor plan today',
  [Message.LANDING_PAGE_TEXT_POINT_2]:
    ' Make an appointment to one of our showrooms',
  [Message.LANDING_PAGE_TEXT_POINT_3]:
    ' Come plan and design your home as you have always dreamed',
  [Message.LANDING_PAGE_BUTTON]: 'Are you tired too? Click here',
  [Message.LANDING_PAGE_MIDDLE_HEADING]: 'How it works?',
  [Message.LANDING_PAGE_UPLOAD_FLOOR_PLAN]: 'Uploading a floor plan',
  [Message.LANDING_PAGE_MEETING_SHOWROOM]: 'Meeting in one of the showrooms',
  [Message.LANDING_PAGE_HIGH_QUALITY_IMAGES]: 'Receiving high quality images',
  [Message.LANDING_PAGE_UPLOAD_FLOOR_PLAN_TEXT]:
    'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis reter kjaskjhas snk',
  [Message.LANDING_PAGE_MEETING_SHOWROOM_TEXT]:
    'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis reter kjaskjhas snk',
  [Message.LANDING_PAGE_HIGH_QUALITY_IMAGES_TEXT]:
    'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis reter kjaskjhas snk',

  [Message.LANDING_PAGE_FORM_HEADING]:
    'Its time to stop imagining and start seeing',
  [Message.LANDING_PAGE_HEADING_TEXT]:
    'Leave your details and one of our representatives will get back to you',
  [Message.LANDING_PAGE_FIRST_NAME]: 'First Name*',
  [Message.LANDING_PAGE_LAST_NAME]: 'Last Name*',
  [Message.LANDING_PAGE_SCHEDULE_MEETING]: 'Schedule Meeting*',
  [Message.LANDING_PAGE_FORM_EMAIL]: 'Email*',
  [Message.LANDING_PAGE_PHONE_NUMBER]: 'Phone Number*',
  [Message.LANDING_PAGE_UPLPAD_FLOOR_PLAN_BUTTON]: 'Upload floor plan',
  [Message.LANDING_PAGE_CHOOSE_FILE_BUTTON]: 'Choose a file',
  [Message.LANDING_PAGE_CHANGE_FILE_BUTTON]: 'Change a file',
  [Message.LANDING_PAGE_FILE_UPLOAD_TERMS]:
    'To get the best result we allow the',
  [Message.LANDING_PAGE_FILE_UPLOAD_FORMAT]: 'PDF / PNG / JPEG up to 10MB',
  [Message.LANDING_PAGE_ACCEPT_TERMS]:
    'I agree to all the<link>Terms and conditions</link>',
  [Message.LANDING_PAGE_FINISH_BUTTON]: 'Finish',

  [Message.LANDING_PAGE_FOOTER_HEADING]: 'Something is not clear?',
  [Message.LANDING_PAGE_FOOTER_HEADING_TEXT]:
    'Contact us today or come visit us at one of our branches',

  [Message.LANDING_PAGE_PHONE_TEXT]: 'Phone',
  [Message.LANDING_PAGE_PHONE_NO]: '+972 52 656 7777',
  [Message.LANDING_PAGE_LOCATION]: 'Location',
  [Message.LANDING_PAGE_LOCATION_TEXT]: 'Lehi 34, Ramat Gan 131254',
  [Message.LANDING_PAGE_EMAIL]: 'Email',
  [Message.LANDING_PAGE_EMAIL_TEXT]: 'Moshemoshi@beitili.com',

  [Message.LANDING_PAGE_CUSTOMER_SERVICE]: 'Customer Service',
  [Message.LANDING_PAGE_CUSTOMER_SERVICE_WEEK]: 'Sunday - Thursday',
  [Message.LANDING_PAGE_CUSTOMER_SERVICE_WEEK_DAY]: 'Friday',
  [Message.LANDING_PAGE_CUSTOMER_SERVICE_WEEKEND]: 'Saturday',

  [Message.LANDING_PAGE_CUSTOMER_SERVICE_WEEK_TIME]: '10:00 - 20:00',
  [Message.LANDING_PAGE_CUSTOMER_SERVICE_WEEK_DAY_1_TIME]: '09:00 - 14:00',
  [Message.LANDING_PAGE_CUSTOMER_SERVICE_WEEKEND_STATUS]: 'Closed',
  [Message.LANDING_PAGE_CONTACT_US]: 'Contact Us:',
  [Message.LANDING_PAGE_FOOTER_TEXT]: 'Powered by Patricia-AI',
  [Message.LANDING_PAGE_THANKYOU_SUCCESS]:
    'Your floor plan has been successfully uploaded',
  [Message.LANDING_PAGE_THANKYOU_SUCCESS_SUBTITLE]:
    'Your plan will be ready within 24 hours{linebreak} We would love to see you in one of {mobileLineBreak} our branches',
  [Message.LANDING_PAGE_THANKYOU_SUCCESS_APPOINTMENT_TEXT]:
    '*For more questions / to make an appointment at a certain date and time, {contactName} - {contactNumber}',
  [Message.LANDING_PAGE_IMAGE_COPIED_NOTIFICATION]:
    'Image has been copied successfully.',
  [Message.LANDING_PAGE_IMAGE_DELETED_NOTIFICATION]:
    'Image has been deleted successfully.',
  [Message.VALIDATION_UPPER_CASE]: 'Code should only accept uppercase',
  [Message.VALIDATION_ALPHA_SPACE]: 'Please enter valid code characters',
  [Message.VALIDATION_SPACE]: 'Space is not allowed',
  [Message.SUPPLIER_LOGIN_TITLE]: 'Welcome to the Patricia Sellers Portal',
  [Message.SUPPLIER_LOGIN_WELCOME_TITLE]: 'Pleasure to meet you, {name}!',
  [Message.SUPPLIER_LOGIN_THANKS_TITLE]: 'Thanks for the info!',
  [Message.SUPPLIER_LOGIN_CONGRATULATIONS_TITLE]: 'Congratulations!',
  [Message.SUPPLIER_LOGIN_CONGRATULATIONS_STORE_OPEN_TITLE]:
    'Your store has been successfully opened.',
  [Message.SUPPLIER_LOGIN_CONGRATULATIONS_STORE_OPEN_TITLE_MOBILE]:
    'Your store has been{linebreak} successfully opened',
  [Message.SUPPLIER_LOGIN_CONGRATULATIONS_JOIN_TITLE]:
    'Thank you for joining us.',
  [Message.SUPPLIER_LOGIN_CONGRATULATIONS_PROVIDED_TITLE]:
    'Thanks for the information you provided.',
  [Message.SUPPLIER_LOGIN_CONGRATULATIONS_PROVIDED_TITLE_MOBILE]:
    'Thanks for the information{linebreak} you provided.',
  [Message.SUPPLIER_REGISTER_SUBTITLE]: 'Fill in all the details below',
  [Message.SUPPLIER_REGISTER_TITLE]: 'Please sign up to continue',
  [Message.SUPPLIER_LOGIN_SUBTITLE]: 'Please sign in or <span>sign up</span>',
  [Message.SUPPLIER_LOGIN_WELCOME_SUBTITLE]: `We're just a few steps away from seeing your{linebreak}products come to life in 3D. To get things started, we{linebreak}need to know you better.`,
  [Message.SUPPLIER_LOGIN_WELCOME_SUBTITLE_MOBILE]: `We're just a few steps away from seeing{linebreak} your products come to life in 3D. To get{linebreak} things started, we need to know you{linebreak} better.`,
  [Message.SUPPLIER_LOGIN_THANKS_FIRST_SUBTITLE]:
    'Now you can start creating your 3D-catalog.{linebreak}Need some assistance? You can schedule a free{linebreak}onboarding call with a Patricia team member.',
  [Message.SUPPLIER_LOGIN_THANKS_FIRST_SUBTITLE_MOBILE]:
    'Now we can start working on your new{linebreak} 3D catalog.',
  [Message.SUPPLIER_LOGIN_THANKS_SECOND_SUBTITLE]:
    'You can schedule meeting with one of Patricia representative {linebreak} helping you with your store',
  [Message.SUPPLIER_LOGIN_CONTINUE_BUTTON]: 'Continue',
  [Message.SUPPLIER_LOGIN_FIRST_CONGRATULATIONS_SUBTITLE]: `Our staff start to work on your 3D model,{linebreak} it’s will take up to <span>24 hours</span> {linebreak}`,
  [Message.SUPPLIER_LOGIN_SECOND_CONGRATULATIONS_SUBTITLE]: `We will let you know when your first model is ready {linebreak} In the meantime, you can log into your store dashboard and {linebreak} find out what things you can do in Patricia`,
  [Message.SUPPLIER_LOGIN_SECOND_CONGRATULATIONS_SUBTITLE_MOBILE]: `We will let you know when your{linebreak} first model is ready{linebreak} In the meantime, you can log into your store{linebreak} dashboard and find out what things you can{linebreak} do in Patricia`,
  [Message.SUPPLIER_LOGIN__CONGRATULATIONS_SUBTITLE_SKIP]: `One of our team members will contact you shortly to assist you in {linebreak} completing the onboarding process.{linebreak}
  In the meantime, you can view the Patricia app here or view{linebreak}your demo store.`,
  [Message.SUPPLIER_LOGIN__CONGRATULATIONS_SUBTITLE_SKIP_MOBILE]: `One of our team members will contact you{linebreak} shortly to assist you in completing the{linebreak} onboarding process.{linebreak}
  In the meantime, you can view the Patricia{linebreak} app here or view your demo store.`,
  [Message.SUPPLIER_LOGIN__CONGRATULATIONS_SUBTITLE_SCHEDULED]: `One of our team members will contact you shortly for additional{linebreak}information and will assist you in completing the onboarding process`,
  [Message.SUPPLIER_LOGIN__CONGRATULATIONS_SUBTITLE_SCHEDULED_MOBILE]: `One of our team members will contact you{linebreak} shortly for additional information and will{linebreak} assist you in completing{linebreak} the onboarding process`,
  [Message.SUPPLIER_LOGIN_NEW_TO_APP]: 'New to the Patricia app?',
  [Message.SUPPLIER_LOGIN_SIGN_UP_HERE]: 'Sign up here',
  [Message.SUPPLIER_LOGIN_EMAIL_PLACEHOLDER]: 'Enter your email address',
  [Message.SUPPLIER_LOGIN_EMAIL_LABEL]: 'Email address',
  [Message.SUPPLIER_LOGIN_PASSWORD_PLACEHOLDER]: 'Enter your password',
  [Message.SUPPLIER_REGISTRATION_PHONE_NUMBER_LABEL]: 'Phone Number*',
  [Message.SUPPLIER_WHAT_DO_YOU_SELL]: 'What do you sell?',
  [Message.SUPPLIER_SELECT_CATEGORY]: 'You can select more than one category',
  [Message.SUPPLIER_LOGIN_WELCOME_LETSDOTHIS_BUTTON]: `Let's do this`,
  [Message.SUPPLIER_LOGIN_WELCOME_CONGRATULATIONS_BUTTON]: `Go to your store`,
  [Message.SUPPLIER_LOGIN_WELCOME_GO_HOME_BUTTON]: `Go to home page`,
  [Message.SUPPLIER_LOGIN_FIRST_CONGRATULATIONS_RETURN_TO_HOME]: `Return to Home page`,
  [Message.SUPPLIER_LOGIN_THANKS_BUTTON]: `Start your shop`,
  [Message.SUPPLIER_STORE_INFO]: '<span>S</span>tore info',
  [Message.SUPPLIER_STORE_NAME_LABEL]: 'Store name',
  [Message.SUPPLIER_STORE_NAME_PLACEHOLDER]: 'Enter your store name',
  [Message.SUPPLIER_PHONE_NUMBER_PLACEHOLDER]: 'Enter your phone number',
  [Message.SUPPLIER_TAX_ID_LABEL]: 'Tax ID',
  [Message.SUPPLIER_TAX_ID_PLACEHOLDER]: 'Enter your tax number',
  [Message.SUPPLIER_WEBSITE_LABEL]: 'Website',
  [Message.SUPPLIER_WEBSITE_PLACEHOLDER]: 'Enter your website',
  [Message.SUPPLIER_MANDATORY_FIELDS]: '*Mandatory fields',
  [Message.SUPPLIER_UPLOAD_YOUR_LOGO_LABEL]: 'Upload your logo',
  [Message.SUPPLIER_TAX_ID_TOOLTIP_TEXT]:
    'Your lisence number the same{linebreak} number that you are pay taxes',
  [Message.SUPPLIER_MANUFACTURER_NAME_TOOLTIP_TEXT]:
    'The product manufacturer name',
  [Message.SUPPLIER_RETAILER_SKU_TOOLTIP_TEXT]:
    'Your SKU for the product as it written in{linebreak} your system - Has to be unique',
  [Message.SUPPLIER_MSRP_TOOLTIP_TEXT]: 'The original price',
  [Message.SUPPLIER_MEDIA_URLS_TOOLTIP_TEXT]: `Only images URL’s, don’t upload images here.{linebreak} Please use Comma ( , ) as a seperator between URL’s`,
  [Message.SUPPLIER_MEDIA_URLS_TOOLTIP_TEXT_MOBILE]: `Only images URL’s, don’t upload{linebreak} images here.{linebreak} Please use Comma ( , ) as a{linebreak} seperator between URL’s`,
  [Message.SUPPLIER_UPLOAD_YOUR_FIRST_PRODUCT]:
    'Upload your first product for <span>Free*</span>',
  [Message.SUPPLIER_MAIN_DETAILS]: 'Main details',
  [Message.SUPPLIER_PRODUCT_NAME_LABEL]: 'Product name',
  [Message.SUPPLIER_PRODUCT_NAME_PLACEHOLDER]: 'Enter your product name',
  [Message.SUPPLIER_RETAILER_SKU_LABEL]: 'Retailer SKU',
  [Message.SUPPLIER_RETAILER_SKU_PLACEHOLDER]: 'Enter your Retailer SKU',
  [Message.SUPPLIER_QUANTITY_LABEL]: 'Quantity',
  [Message.SUPPLIER_QUANTITY_PLACEHOLDER]: 'Enter your quantity',
  [Message.SUPPLIER_MSRP_LABEL]: 'MSRP',
  [Message.SUPPLIER_MSRP_PLACEHOLDER]: 'Enter your MSRP',
  [Message.SUPPLIER_SALES_PRICE_LABEL]: 'Sales price',
  [Message.SUPPLIER_SALES_PRICE_PLACEHOLDER]: 'Enter your product price',
  [Message.SUPPLIER_MEDIA_URL_LABEL]: `Media URL’s`,
  [Message.SUPPLIER_MEDIA_URL_PLACEHOLDER]:
    'Type somthing and then press enter...',
  [Message.SUPPLIER_UPLOAD_MEDIA_LABEL]: 'Upload media',
  [Message.SUPPLIER_ADDITIONAL_INFO]: 'Additional info',
  [Message.SUPPLIER_DESCRIPTION_LABEL]: 'Description',
  [Message.SUPPLIER_DESCRIPTION_PLACEHOLDER]:
    'Add a description and then press enter',
  [Message.SUPPLIER_WEBPAGE_LINK_LABEL]: 'Web page link',
  [Message.SUPPLIER_WEBPAGE_LINK_PLACEHOLDER]: 'Enter your shop name',
  [Message.SUPPLIER_TYPES_LABEL]: 'Types',
  [Message.SUPPLIER_TYPES_PLACEHOLDER]: 'Select type',
  [Message.SUPPLIER_TOTAL_LENGTH_LABEL]: 'Total length*',
  [Message.SUPPLIER_TOTAL_LENGTH_PLACEHOLDER]: 'Enter length',
  [Message.SUPPLIER_TOTAL_WIDTH_LABEL]: 'Total width*',
  [Message.SUPPLIER_TOTAL_WIDTH_PLACEHOLDER]: 'Enter width',
  [Message.SUPPLIER_TOTAL_HEIGHT_LABEL]: 'Total height*',
  [Message.SUPPLIER_TOTAL_HEIGHT_PLACEHOLDER]: 'Enter height',
  [Message.SUPPLIER_MEASUREMENT_UNITS_LABEL]: 'Measurement units*',
  [Message.SUPPLIER_MEASUREMENT_UNITS_PLACEHOLDER]: 'Select measurement units',
  [Message.SUPPLIER_MARKETING_INFO]: 'Marketing info - optional',
  [Message.SUPPLIER_UPLOAD_SPECIFICATION_FILE_LABEL]:
    'Upload specification files',
  [Message.SUPPLIER_SERIES_LABEL]: 'Series',
  [Message.SUPPLIER_SERIES_PLACEHOLDER]: 'Enter series number',
  [Message.SUPPLIER_COLOR_TAG_LABEL]: 'Color tag',
  [Message.SUPPLIER_COLOR_Tag_PLACEHOLDER]: 'Choose color',
  [Message.SUPPLIER_TAGS_LABEL]: 'Tags',
  [Message.SUPPLIER_TAGS_PLACEHOLDER]: 'Type somthing and then press enter...',
  [Message.SUPPLIER_REPEAT_PASSWORD]: 'Repeat Password',
  [Message.SUPPLIER_NOTE_TEXT]:
    '*Need some assistance? <span>Click here</span> to schedule a 1:1 onboarding session',
  [Message.SUPPLIER_SKIP_CONFIRMATION_SUBTITLE1]:
    'You are about to skip the product upload step, are you sure?',
  [Message.SUPPLIER_SKIP_CONFIRMATION_SUBTITLE2]:
    'Note that you can upload a product at any stage',
  [Message.UPLOAD_BANNER_TITLE]: 'UPLOAD',
  [Message.DESIGN_BANNER_TITLE]: 'DESIGN',
  [Message.SHOP_BANNER_TITLE]: 'SHOP',
  [Message.SUPPLIER_SCHEDULE_MEETING]: 'Schedule meeting',
  [Message.ONBOARDING_TOOLTIP_FLOOR_ROOM_CONFIRM]: 'Floor plan confirmation',
  [Message.PROPERTY_CARD_DESIGN_STYLE]: 'Design style',
  [Message.PROPERTY_SIZE]: 'Property area',
  [Message.MODERN]: 'Modern',
  [Message.CLASSIC]: 'Classic',
  [Message.SCANDINAVIAN]: 'Scandinavian',
  [Message.VIEW_SELECTION]: 'View selection',
  [Message.WHAT_ARE_LOOKING_FOR]: 'What are looking for?',
  [Message.PRIVATE]: 'Privates',
  [Message.PROJECT]: 'Projects',
  [Message.MYPROJECT]: 'My Projects',
  [Message.ADD_A_NEW_PROJECT]: 'Add a new project',
  [Message.CREATE_MANUALLY]: 'Create manually',
  [Message.PEROJECT_DETAILS]: 'Project details',
  [Message.PROJECT_NAME]: 'Project name',
  [Message.DEVELOPER_NAME_FORM]: 'Developer name*',
  [Message.PROJECT_NUMBER]: 'Project number',
  [Message.PROJECT_ADDRESS_FORM]: 'Project address',
  [Message.PROJECT_ADDRESS_FORM_MY_PROJECT_TITLE]: 'Project Address:',

  [Message.COVER_PHOTO]: 'Cover photo*',
  [Message.CSV_FILE]: 'CSV file (Optional)',
  [Message.REAL_ESTATE_DEVELOPER]: 'Real estate developer',
  [Message.UPLOAD_COVER_PHOTO]: 'Upload cover photo*',
  [Message.UPLOAD_MATRIX_FILE]: 'Upload Matrix file',
  [Message.REPLACE_FILE]: 'Replace file',
  [Message.NO_RESULTS]: 'There are no results for',
  [Message.ADD_NEW_DEVELOPER]: 'Add as new developer',
  [Message.PROJECT_SEARCH_PLACEHOLDER]: 'Enter project name',
  [Message.DELETE_PROJECT_TEXT]:
    'Are you sure you want to delete this project?',
  [Message.EDIT_PROJECT]: 'Edit project',
  [Message.PROJECT_ADDRESS]: 'Project address',
  [Message.DEVELOPER_NAME_MY_PROJECT_TITLE]: 'Developer name:',
  [Message.NUMBER_OF_PROPERTIES]: 'Number of properties:',
  [Message.PROPERTIES_SECTION_TITLE]: 'Properties <span>({properties})</span>',
  [Message.TYPES_SECTION_TITLE]: 'Types <span>({types})</span>',
  [Message.ENTER_APARTMENT_NUMBER_PLACEHOLDER]: 'Enter apartment number',
  [Message.TYPE_NAME]: 'Type name',
  [Message.BUILDING_NAME_TITLE]: 'Building ',
  [Message.TYPE_TITLE]: 'Type ',
  [Message.BUILDING]: 'Building:',
  [Message.TYPE]: 'Type:',
  [Message.ASSOCIATED_PROPERTIES]: 'Associated Properties:',
  [Message.ADD_NEW_UNIT]: 'Add new unit',
  [Message.ADD_CUSTOMER_LIST]: 'Add customer list',
  [Message.BUILDING_NAME]: 'Building name',
  [Message.FLOOR_PLAN]: 'Floor plan*',
  [Message.UPLOAD_FLOOR_PLAN]: 'Upload floor plan*',
  [Message.BROWSE]: 'Browse',
  [Message.NEW_UNIT_UPLOAD_NOTICE]:
    'We allow you to upload files{linebreak}<span>PDF / PNG / JPEG</span>{linebreak}up to 10MB or 4 pages',
  [Message.CREATE_NEW_UNIT]: 'Create new unit',
  [Message.APPLY_CHANGES]: 'Apply Changes',
  [Message.PROJECT_SEARCH]: 'Search',
  [Message.GREEN_HOUSE]: 'Green house',
  [Message.A]: 'A',
  [Message.PROJECT_LOADER_TEXT]: 'Please wait while the file uploading',
  [Message.FILE_UPLOADING_SUMMARY]: 'File uploading summary',
  [Message.SUCCESS]: 'Success',
  [Message.FAILED]: 'Failed',
  [Message.DOWNLOAD_CSV]: 'Download CSV',
  [Message.DOWNLOAD_PRODUCT_LIST]: 'Download product list',
  [Message.SHARE_PROPERTY_WITH_CUSTOMER]: 'Share property with customer',
  [Message.FINISH_AND_SEND]: 'Finish and send',
  [Message.UPLOAD_FILE_FAIL]: 'Upload failed. Please, contact admins.',
  [Message.JOHN]: 'John',
  [Message.DOE]: 'Doe',
  [Message.SUPPLIER_LOGIN_EMAIL_PLACEHOLDERS]: 'Enter your email address',
  [Message.MODY_BUTTON_TEXT]: 'לבחירת סוג הנכס לחצו כאן',
  [Message.MODY_HEADER_TEXT]: 'ברוכים הבאים',
  [Message.ALL_DETAILS_MANDATORY]: '*All details are mandatory',
  [Message.SCAN_YOUR_HOME]: 'Scan Your Home',
  [Message.PROJECT_NAME_FORM]: 'Green house',
  [Message.PROJECT_NUMBER_FORM]: '1158477',
  [Message.PROJECT_FORM_ADDRESS_FORM]: 'Herzel 57',
  [Message.ADDRESS_FORM_CITY_FORM]: 'Haifa',
  [Message.ADD_NEW_DEVELOPER_FORM]: 'Type or choose the name of developer',
  [Message.ADD_NEW_UNIT_PROJECT]: 'Add new unit',
  [Message.EDIT_UNIT]: 'Edit unit',
  [Message.MATRIX_FILTER_BY_SUB_CATEGORY]: 'Choose products category',
  [Message.MATRIX_PROJECT_SPECIFICATION]: 'Project specification',
  [Message.MATRIX_FILTER_BY_CATEGORY]: 'Filter By Subcategory',
  [Message.PROJECT_UNIT_CREATE_USER_FILE]: 'Creating a user file',
  [Message.PROJECT_UNIT_CREATE_USER_FILE_SUBTITLE]:
    'Please wait while the file creating',
  [Message.PROJECT_UNIT_CREATE_USER_FILE_SUCCESS_SUBTITLE]:
    'User file successfully created',
  [Message.PROPERTY_ADD_CO_OWNER]: 'Add a co-owner',
  [Message.UPLOAD_PLAN_ERROR_TYPE]: 'The file type is JPG, JPEG, PNG, or PDF',
  [Message.UPLOAD_PLAN_ERROR_RESOLUTION]:
    'The height or width is no more than 10,000',
  [Message.UPLOAD_PLAN_ERROR_SIZE]: 'The file size is no more than 10 MB',
  [Message.UPLOAD_PLAN_ERROR_RATIO]: 'The aspect ratio is no more than 3:1',
  [Message.PROPERTY_STATUS_ERROR]: 'Error in plan',
  [Message.PROPERTY_REUPLOAD_BUTTON]: 'Re-upload',
  [Message.PROPERTY_UPLOAD_PRODUCT_CSV]: 'Upload Product CSV',
  [Message.STATUS_TEXT]: 'Status',
  [Message.UNIT_STATUS_WAITING]: 'Waiting',
  [Message.UNIT_STATUS_TODO]: 'To do',
  [Message.UNIT_STATUS_ERR_IN_PLAN]: 'Error in plan',
  [Message.UNIT_STATUS_PUBLISHED]: 'Published',
  [Message.UNIT_STATUS_IN_PROGRESS]: 'In progress',
};

export default messages;
