import * as React from 'react';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
// import Button from 'components/@client/Button';
import { useMediaQuery } from '@material-ui/core';
// import { addToCartBulkProductAdd, AllQuoteComponents } from 'api/transform';
import {
  useAuthentication,
  // useBulkProductAddToCart,
  useFetch,
  useNotifications,
  useRouter,
} from 'hooks';
import { FormattedMessage, useIntl } from 'react-intl';
// import { METHODS } from 'api/client';
import { Message } from 'i18n';
// import { BulkAddToCartProduct } from 'types/products';
// import { sortBy } from 'utils/lodash.utils';
import SectionTitle from 'components/@client/SectionTitle/SectionTitle';
import {
  MAX_TABLET_RESOLUTION,
  MIN_TABLET_RESOLUTION,
  // MAX_TABLET_RESOLUTION,
  MOBILE_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
  // MIN_TABLET_RESOLUTION,
} from 'styles/sharedStyle';
import { ReactComponent as Checkmark } from 'components/@client/form-components/pick.svg';
import Modal, { ModalAction } from 'components/@client/Modal';
import type { Setter } from 'types/common.types';
// import { LogsAPI } from 'api/methods';
// import { userLogData } from 'mockData/userLogData';
import { ClientRouter } from 'router/routes';
import CustomTooltip from 'components/Tooltip';
import IconButton, { IconName } from 'components/Button/IconButton';
import { pdf } from '@react-pdf/renderer';
import saveAs from 'file-saver';
import PropertyProductList from 'documents/PropertyProductList';
import { Product } from 'types/products';
import Loader from 'components/Loader/Loader';
import FilterChip from './FilterChip';
import ChipsModal from './ChipsModal';

interface Props {
  productsCount: number | null;
  selectedRoom: Array<number>;
  setSelectedRoom: Setter<Array<number>>;
  getProducts: (room?: Array<number>, categories?: Array<number>) => void;
  propertyId: string;
  unitId?: string;
  selectedCategories: Array<number>;
  setSelectedCategories: Setter<Array<number>>;
  allMatrixComponents?: {
    count: number;
    results: Product[];
  } | null;
  isAllProductsLoading?: boolean;
}

const NUM_OF_DESKTOP_CHIPS = 4;
const Controller: React.FC<Props> = ({
  productsCount,
  selectedRoom,
  setSelectedRoom,
  getProducts,
  propertyId,
  unitId,
  selectedCategories,
  setSelectedCategories,
  allMatrixComponents,
  isAllProductsLoading = false,
}) => {
  // const onSuccess = React.useCallback(
  //   (rooms: { id: number; room: { name: string; id: number } }[] | null) => {
  //     rooms?.unshift({ id: 0, room: { name: 'All', id: 0 } });
  //     setSelectedRoom([rooms?.[0].id ?? 0]);
  //   },
  //   [setSelectedRoom]
  // );
  const { data: rooms } = useFetch<
    { id: number; room: { name: string; id: number } }[]
  >({
    initialUrl: `api/units/${unitId}/rooms/`,
    initialData: [],
    config: { params: { property: propertyId } },
    // onSuccess,
  });
  const { data: subCategories } = useFetch<
    Array<{ id: number; sub_category: { id: number; name: string } }>
  >({
    initialUrl: `api/units/${unitId}/sub_categories/`,
  });
  const [isChipModalOpen, setIsChipModalOpen] = React.useState(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = React.useState(false);
  const [isPdfGenerating, setIsPdfGenerating] = React.useState(false);
  const isMobile = useMediaQuery('(max-width: 800px)');
  const firstFourRooms = [...(rooms ?? [])].slice(0, NUM_OF_DESKTOP_CHIPS);
  const isOtherAreasSelected =
    !!selectedRoom &&
    !firstFourRooms.filter(room => selectedRoom.includes(room.room.id))
      .length &&
    selectedRoom.length > 0;
  const [isOpen, setIsOpen] = React.useState(false);
  const { token } = useAuthentication();
  const { history } = useRouter();
  const toggleModal = React.useCallback(() => {
    setIsChipModalOpen(false);
  }, []);
  const intl = useIntl();
  const { showNotification } = useNotifications();

  const filterByRoom = React.useCallback(
    (roomId: Array<number>, isCategory?: boolean) => {
      // LogsAPI.postUserLogs({
      //   area: userLogData.eventArea.myProperties,
      //   section: userLogData.eventSection.propertyPage,
      //   name: userLogData.eventName.roomsFilter,
      //   path: userLogData.eventPath.appPropertiesIdPath,
      //   metadata: {
      //     property_id: propertyId,
      //     room_name: rooms?.find(e => e.id === roomId)?.name,
      //   },
      // });
      if (!isCategory) {
        setSelectedRoom(roomId);

        if (roomId.includes(0)) {
          getProducts([], selectedCategories);
        } else {
          getProducts(roomId, selectedCategories);
        }
      } else if (selectedRoom.includes(0)) {
        getProducts([], roomId);
      } else getProducts(selectedRoom, roomId);
    },
    [getProducts, setSelectedRoom, selectedRoom, selectedCategories]
  );

  const actions: ModalAction[] = [
    {
      label: <FormattedMessage id={Message.CART_KEEP_SHOPPING_BUTTON} />,
      onClick: () => setIsOpen(false),
      variant: 'secondary',
    },
    {
      label: <FormattedMessage id={Message.CART_CHECKOUT_BUTTON} />,
      onClick: () =>
        token
          ? history.push(ClientRouter.CART)
          : history.push(`${ClientRouter.ADDITIONAL_INFO}?redirect=CART`),
      variant: 'primary',
    },
  ];

  const generateProductListPdf = async () => {
    if (isPdfGenerating) return;
    setIsPdfGenerating(true);
    const blob = await pdf(
      <PropertyProductList
        products={allMatrixComponents?.results.map(e => e)}
        docName={`Product List for Property ${propertyId}`}
      />
    )
      .toBlob()
      .catch(e => {
        showNotification({
          key: 'clients/pdfGenerationRejected',
          message: 'Failed to generate pdf',
          severity: 'error',
        });
        return null;
      });
    if (blob) saveAs(blob, 'product_list.pdf');
    setIsPdfGenerating(false);
  };

  return (
    <Container>
      <SectionTitle
        label={
          <FormattedMessage
            id={Message.MATRIX_PROJECT_SPECIFICATION}
            values={{
              count: `(${productsCount})`,
              span: (chunks: string) => (
                <span style={{ color: '#ff5241' }}>{chunks}</span>
              ),
            }}
          />
        }
      />
      <Wrapper>
        <>
          <FiltersContainer>
            <FilterChip
              label="All"
              onClick={() => {
                filterByRoom([]);
              }}
              selected={selectedRoom.length === 0}
            />
            {(isMobile ? rooms : firstFourRooms)?.map(room => {
              return (
                <FilterChip
                  key={room.room.id}
                  label={room.room.name}
                  onClick={() => {
                    if (selectedRoom.includes(room.room.id))
                      filterByRoom(
                        selectedRoom.filter(e => e !== room.room.id)
                      );
                    else filterByRoom([room.room.id]);
                  }}
                  selected={selectedRoom.includes(room.room.id)}
                />
              );
            })}
            {!isMobile && (rooms ?? []).length > NUM_OF_DESKTOP_CHIPS && (
              <FilterChip
                label={`+${
                  (rooms ?? []).length - NUM_OF_DESKTOP_CHIPS
                } More areas `}
                onClick={() => setIsChipModalOpen(true)}
                selected={isOtherAreasSelected}
                moreAreas={true}
              />
            )}
          </FiltersContainer>
          <ButtonFlexContainer>
            <ButtonContainer>
              <CustomTooltip
                title={
                  <FormattedMessage
                    id={
                      !isAllProductsLoading &&
                      allMatrixComponents?.count ===
                        allMatrixComponents?.results?.length
                        ? Message.DOWNLOAD_PRODUCT_LIST
                        : Message.LOADING
                    }
                  />
                }
                $variant="info"
              >
                {isPdfGenerating ||
                isAllProductsLoading ||
                allMatrixComponents?.count !==
                  allMatrixComponents?.results?.length ? (
                  <Loader />
                ) : (
                  <span className="icon-clients-action">
                    <StyledIconButton
                      customIcon="download"
                      $size="large"
                      iconSize={24}
                      iconName={IconName.custom_icon}
                      onClick={() => generateProductListPdf()}
                      disabled={
                        isPdfGenerating ||
                        isAllProductsLoading ||
                        allMatrixComponents?.count !==
                          allMatrixComponents?.results?.length
                      }
                    />
                  </span>
                )}
              </CustomTooltip>
            </ButtonContainer>

            {!isMobile && (
              <FilterChip
                label={intl.formatMessage({
                  id: Message.MATRIX_FILTER_BY_SUB_CATEGORY,
                })}
                onClick={() => setIsCategoryModalOpen(true)}
              />
            )}
          </ButtonFlexContainer>
        </>
      </Wrapper>
      {isMobile && (
        <div style={{ margin: '24px auto', width: 'fit-content' }}>
          <FilterChip
            label={intl.formatMessage({
              id: Message.MATRIX_FILTER_BY_SUB_CATEGORY,
            })}
            onClick={() => setIsCategoryModalOpen(true)}
          />
        </div>
      )}
      {!isMobile && isChipModalOpen && (
        <ChipsModal
          isModalOpen={isChipModalOpen}
          rooms={rooms?.map(e => ({ id: e.room.id, name: e.room.name })) ?? []}
          selectedRoom={selectedRoom}
          setSelectedRoom={setSelectedRoom}
          close={toggleModal}
          filterByRoom={filterByRoom}
        />
      )}

      <ChipsModal
        isModalOpen={isCategoryModalOpen}
        rooms={
          subCategories?.map(e => ({
            id: e.sub_category.id,
            name: e.sub_category.name,
          })) ?? []
        }
        selectedRoom={selectedCategories}
        setSelectedRoom={setSelectedCategories}
        close={() => setIsCategoryModalOpen(false)}
        filterByRoom={values => filterByRoom(values, true)}
        title={intl.formatMessage({ id: Message.MATRIX_FILTER_BY_CATEGORY })}
      />

      {isOpen && (
        <ConfirmModal
          className="confirm-modal"
          onClose={() => setIsOpen(false)}
          actions={actions}
          open={isOpen}
        >
          <Content>
            {/* {`${data?.length}`} <FormattedMessage id={Message.PRODUCTS} /> */}
          </Content>
          <Content>
            <Checkmark className="checkmark" />
            <FormattedMessage id={Message.ADDED_TO_CART} />
          </Content>
        </ConfirmModal>
      )}
    </Container>
  );
};

export default Controller;

const Container = styled.div`
  margin: 0 160px;
  min-height: 100px;

  @media ${MOBILE_TABLET_RESOLUTION} {
    margin: 0 24px;
  }
  .section-title-wrapper {
    margin: 48px 0 24px 0 !important;
    @media (max-width: 1600px) and (min-width: 800px) {
      padding: 0 0;
    }
  }
  .section-title-label {
    position: relative;
    padding: 0 24px 0 0;
    ${rtl`
        padding: 0;
    `}
  }
`;
const Wrapper = styled.div`
  justify-content: space-between;
  display: flex;
`;

const ButtonFlexContainer = styled.div`
  display: flex;
  gap: 12px;
`;
const StyledIconButton = styled(IconButton)`
  ${rtl`margin-left: 24px !important;`}
`;

const ButtonContainer = styled.div`
  text-align: end;
  top: -42px;
  @media ${MAX_TABLET_RESOLUTION} {
    text-align: center;
  }

  @media ${MIN_TABLET_RESOLUTION} {
    & .buyNow-button {
      width: 201px;
    }
  }

  @media ${MOBILE_TABLET_RESOLUTION} {
    & .buyNow-button {
      text-align: center;
      margin-top: 24px;
      padding: 12px 36px !important;
      font-size: 16px;
      font-weight: 500;
      border-radius: 15px;
      height: 48px;
      text-align: right;
      min-width: 235px;
      background-color: #282929;
    }
  }
  @media ${MOBILE_RESOLUTION} {
    width: 100%;
  }
  .cart-icon {
    ${rtl` margin-right: 10px;`}
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  overflow-x: auto;
  @media (min-width: 1024px) and (max-width: 1200px) {
    max-width: 45vw;
  }
  @media (max-width: 1200px) and (min-width: 800px) {
    max-width: 35vw;
  }
  ${({ theme }) => theme.utils.scrollbar}
  padding-top: 6px;
  & > div:not(:last-of-type) {
    ${rtl`
        margin-right: 16px;
    `}
    @media (max-width: 800px) {
      ${rtl`
        margin-right: 8px;
    `}
    }
    @media (max-width: 800px) {
      display: flex;
    }
  }
`;
const ConfirmModal = styled(Modal)`
  & .MuiDialogActions-root {
    @media ${MOBILE_RESOLUTION} {
      display: block;
      text-align: center;
      & > button {
        margin: 10px !important;
      }
    }
    & > button {
      min-width: 150px;
    }
  }
`;

const Content = styled.p`
  text-align: center;
  img {
    max-width: 80px;
    max-height: 80px;
    margin-bottom: 10px;
  }
  margin-bottom: 20px;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.black0};
  svg {
    path {
      fill: ${({ theme }) => theme.colors.primary.lightMobile};
    }
    ${rtl`
      margin-right: 10px;
    `}
  }
`;
