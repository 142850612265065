import * as React from 'react';
import ComingSoon from 'components/@client/ComingSoon/ComingSoon';
import * as Page from 'pages/appClient';
import AddressForm from 'pages/appClient/profile/addresses';
import { getRoute } from 'utils/sidebar.utils';
import { ClientRouter, IRouteConfig } from './routes';

export const clientRoutes = [
  {
    path: ClientRouter.APP,
    exact: true,
    component: React.lazy(() => import('../pages/appClient/home')),
    routes: [] as IRouteConfig[],
    isPublic: true,
  },
  {
    path: ClientRouter.MY_ACCOUNT,
    exact: true,
    component: Page.MyAccount,
    routes: [] as IRouteConfig[],
  },
  {
    path: ClientRouter.PROFILE,
    exact: true,
    component: Page.Profile,
    routes: [] as IRouteConfig[],
  },
  {
    path: `${ClientRouter.PROFILE}/addresses/:id`,
    component: AddressForm,
    exact: true,
    routes: [] as IRouteConfig[],
  },
  {
    path: `${ClientRouter.PROFILE}/addresses`,
    component: AddressForm,
    exact: false,
    routes: [] as IRouteConfig[],
  },
  {
    path: ClientRouter.PROPERTIES,
    exact: true,
    component: Page.Properties,
    routes: [] as IRouteConfig[],
  },
  {
    path: ClientRouter.PROPERTIES_SELLER,
    exact: true,
    component: Page.SellerProperty,
    routes: [] as IRouteConfig[],
  },
  {
    path: ClientRouter.PROPERTIES_SELLER_LISTING,
    exact: true,
    component: Page.SellerPropertyListing,
    routes: [] as IRouteConfig[],
  },
  {
    path: ClientRouter.PROPERTIES_SELLER__MY_PROJECT,
    exact: true,
    component: Page.MyProjects,
    isPublic: true,
    routes: [] as IRouteConfig[],
  },
  {
    path: getRoute([ClientRouter.PROPERTIES, '/:id']),
    exact: true,
    component: Page.Property,
    routes: [] as IRouteConfig[],
  },
  {
    path: ClientRouter.CART,
    exact: false,
    component: Page.Cart,
    routes: [] as IRouteConfig[],
    isPublic: true,
  },
  {
    path: ClientRouter.CHECKOUT_SUCCESS,
    exact: true,
    component: Page.CheckoutSuccess,
    routes: [] as IRouteConfig[],
    isPublic: true,
  },
  {
    path: ClientRouter.CHECKOUT_FAILURE,
    exact: true,
    component: Page.CheckoutFailure,
    routes: [] as IRouteConfig[],
    isPublic: true,
  },
  {
    path: ClientRouter.CHECKOUT,
    exact: true,
    component: Page.Checkout,
    routes: [] as IRouteConfig[],
    isPublic: true,
  },
  {
    path: ClientRouter.ORDERS,
    exact: true,
    component: Page.Orders,
    routes: [] as IRouteConfig[],
  },
  {
    path: ClientRouter.ORDER_DETAILS,
    exact: true,
    component: Page.Order,
    routes: [] as IRouteConfig[],
  },
  {
    path: ClientRouter.SKINS,
    exact: true,
    isPublic: true,
    component: Page.Skins,
    routes: [] as IRouteConfig[],
  },
  {
    path: ClientRouter.SKIN,
    exact: true,
    isPublic: true,
    component: Page.Skin,
    routes: [] as IRouteConfig[],
  },
  {
    path: ClientRouter.SKIN_CHECKOUT,
    exact: true,
    component: Page.SkinCheckout,
    routes: [] as IRouteConfig[],
  },
  {
    path: ClientRouter.PRODUCTS,
    exact: true,
    component: Page.Products,
    routes: [] as IRouteConfig[],
    isPublic: true,
  },
  {
    path: ClientRouter.PRODUCT_CATEGORY,
    exact: true,
    component: Page.Products,
    routes: [] as IRouteConfig[],
    isPublic: true,
  },
  {
    path: ClientRouter.PRODUCT_SUB_CATEGORY,
    exact: true,
    component: Page.Products,
    routes: [] as IRouteConfig[],
    isPublic: true,
  },
  {
    path: ClientRouter.PRODUCT_PAGE,
    exact: true,
    component: Page.ProductPage,
    routes: [] as IRouteConfig[],
    isPublic: true,
  },
  {
    path: ClientRouter.SEARCH_PAGE,
    exact: true,
    component: Page.SearchPage,
    routes: [] as IRouteConfig[],
    isPublic: true,
  },
  {
    path: ClientRouter.MAGAZINE,
    exact: true,
    component: Page.Magazine,
    routes: [] as IRouteConfig[],
    isPublic: true,
  },
  {
    path: ClientRouter.MAGAZINE_PAGE,
    exact: true,
    component: Page.MagazinePage,
    routes: [] as IRouteConfig[],
    isPublic: true,
  },
  {
    path: ClientRouter.SEARCH,
    exact: true,
    component: ComingSoon,
    routes: [] as IRouteConfig[],
    isPublic: true,
  },
  {
    path: ClientRouter.ONBOARDING,
    exact: false,
    component: Page.Onboarding,
    isPublic: true,
    routes: [
      {
        path: ClientRouter.UPLOAD_PLAN,
        exact: true,
        component: Page.NewUploadPlan,
        routes: [] as IRouteConfig[],
      },
      {
        path: ClientRouter.SELECT_FLOOR,
        exact: true,
        component: Page.SelectFloor,
        routes: [] as IRouteConfig[],
      },
      {
        path: ClientRouter.PLAN_CONFIRMED,
        exact: true,
        component: Page.PlanConfirmed,
        routes: [] as IRouteConfig[],
      },
      {
        path: ClientRouter.QUIZ_QUESTION,
        exact: true,
        component: Page.QuizQuestion,
        routes: [] as IRouteConfig[],
      },
      {
        path: ClientRouter.QUIZ_SUMMARY,
        exact: true,
        component: Page.QuizSummary,
        routes: [] as IRouteConfig[],
      },
      {
        path: ClientRouter.ADDITIONAL_INFO,
        exact: true,
        component: Page.AdditionalInfo,
        routes: [] as IRouteConfig[],
      },
      {
        path: ClientRouter.DONE,
        exact: true,
        component: Page.Done,
        routes: [] as IRouteConfig[],
      },
      {
        path: ClientRouter.CREATE_KEY_CONFIRMATION,
        exact: true,
        component: Page.CreateKeyConfirmation,
        routes: [] as IRouteConfig[],
      },
    ] as IRouteConfig[],
  },
  {
    path: ClientRouter.TOUR /* UNITY */,
    exact: true,
    component: Page.TourPlayer,
    routes: [] as IRouteConfig[],
  },
  {
    path: ClientRouter.TOUR_UNITS /* UNITY */,
    exact: true,
    component: Page.TourPlayer,
    routes: [] as IRouteConfig[],
  },
  {
    path: ClientRouter.HELP,
    exact: true,
    component: Page.Help,
    routes: [] as IRouteConfig[],
    isPublic: true,
  },
  {
    path: ClientRouter.CONTACT,
    exact: true,
    component: Page.Contact,
    routes: [] as IRouteConfig[],
    isPublic: true,
  },
  {
    path: ClientRouter.BECOME_AN_EXPERT,
    exact: true,
    component: Page.BecomeAnExpert,
    routes: [] as IRouteConfig[],
    isPublic: true,
  },
  {
    path: ClientRouter.SELL_YOUR_PRODUCTS,
    exact: true,
    component: Page.SellYourProducts,
    routes: [] as IRouteConfig[],
    isPublic: true,
  },
  {
    path: ClientRouter.AUTH,
    exact: true,
    component: Page.Auth,
    routes: [] as IRouteConfig[],
    isPublic: true,
  },
  {
    path: ClientRouter.NOT_FOUND,
    exact: true,
    component: Page.NotFound,
    routes: [] as IRouteConfig[],
    isPublic: true,
  },
  {
    path: ClientRouter.PROPERTY_IN_PROGRESS,
    exact: true,
    component: Page.PropertyInProgress,
    routes: [] as IRouteConfig[],
    isPublic: true,
  },
  {
    path: ClientRouter.TERMS_OF_USE,
    exact: true,
    component: Page.TermsOfUse,
    routes: [] as IRouteConfig[],
    isPublic: true,
  },
  {
    path: ClientRouter.PRIVACY_POLICY,
    exact: true,
    component: Page.PrivacyPolicy,
    routes: [] as IRouteConfig[],
    isPublic: true,
  },
  {
    path: ClientRouter.COMING_SOON,
    exact: true,
    component: Page.ComingSoon,
    routes: [] as IRouteConfig[],
    isPublic: true,
  },
  {
    path: ClientRouter.ACCESSIBILITY_PAGE,
    exact: true,
    component: Page.Accessibility,
    routes: [] as IRouteConfig[],
    isPublic: true,
  },
  {
    path: ClientRouter.BECOME_A_SELLER,
    exact: true,
    component: Page.BecomeSeller,
    routes: [] as IRouteConfig[],
    isPublic: true,
  },
  {
    path: ClientRouter.BUY_MORE_PATRICIA_COINS,
    exact: true,
    component: Page.BuyMorePatriciaCoins,
    routes: [] as IRouteConfig[],
    isPublic: true,
  },
  {
    path: ClientRouter.SALES_PAGE,
    exact: true,
    component: Page.ProductSalesPage,
    routes: [] as IRouteConfig[],
    isPublic: true,
  },
  {
    path: ClientRouter.IDEAS_PAGE,
    exact: true,
    component: Page.Ideas,
    isPublic: true,
  },
  {
    path: ClientRouter.IDEA_PAGE,
    exact: true,
    component: Page.IdeasDetail,
    isPublic: true,
  },
  {
    path: ClientRouter.SELLER_LANDING_PARENT,
    exact: false,
    component: Page.SellerLandingParent,
    isPublic: true,
    routes: [
      {
        path: ClientRouter.SELLER_LANDING,
        exact: true,
        component: Page.SellerLanding,
        isPublic: true,
      },
      {
        path: getRoute([ClientRouter.SELLER_LANDING, '/thankyou']),
        exact: true,
        component: Page.SellerLandingThankYou,
        isPublic: true,
      },
    ],
  },
  {
    path: ClientRouter.SELLER_IFRAME_FROM,
    exact: true,
    component: Page.SellerUserForm,
    isPublic: true,
  },
  {
    path: ClientRouter.SUPPLIER_LOGIN,
    exact: true,
    component: Page.SupplierLogin,
    isPublic: true,
    routes: [] as IRouteConfig[],
  },
  {
    path: ClientRouter.SUPPLIER_REGISTER,
    exact: true,
    component: Page.SupplierRegistration,
    isPublic: true,
    routes: [] as IRouteConfig[],
  },
  {
    path: ClientRouter.SUPPLIER_LOGIN_WELCOME,
    exact: true,
    component: Page.SupplierLoginWelcome,
    isPublic: true,
    routes: [] as IRouteConfig[],
  },
  {
    path: ClientRouter.SUPPLIER_CONGRATULATIONS,
    exact: true,
    component: Page.SupplierLoginCongratulations,
    isPublic: true,
    routes: [] as IRouteConfig[],
  },
  {
    path: ClientRouter.SUPPLIER_INFO,
    exact: false,
    component: Page.SupplierSelectCategory,
    isPublic: true,
    routes: [
      {
        path: ClientRouter.SUPPLIER_LOGIN_THANKS,
        exact: false,
        component: Page.SupplierLoginThanks,
        isPublic: true,
        routes: [] as IRouteConfig[],
      },
      {
        path: ClientRouter.SUPPLIER_STORE_INFO,
        exact: false,
        component: Page.SupplierInfoStore,
        isPublic: true,
        routes: [] as IRouteConfig[],
      },
      {
        path: ClientRouter.SUPPLIER_MAIN_DETAILS,
        exact: false,
        component: Page.SupplierMainDetails,
        routes: [] as IRouteConfig[],
        isPublic: true,
      },
      {
        path: ClientRouter.SUPPLIER_ADDITIONAL_INFO,
        exact: false,
        component: Page.SupplierAdditionalInfo,
        routes: [] as IRouteConfig[],
        isPublic: true,
      },
      {
        path: ClientRouter.SUPPLIER_MARKETING_INFO,
        exact: false,
        component: Page.SupplierMarketingInfo,
        routes: [] as IRouteConfig[],
        isPublic: true,
      },
    ] as IRouteConfig[],
  },
  {
    path: ClientRouter.PROPERTY_MEDIA,
    exact: true,
    component: Page.PropertyMedia,
    routes: [] as IRouteConfig[],
    isPublic: true,
  },
  // {
  //   path: ClientRouter.MYPROJECTS,
  //   exact: true,
  //   component: Page.MyProjects,
  //   isPublic: true,
  //   routes: [] as IRouteConfig[],
  // },
] as IRouteConfig[];
