import { useRouter } from 'hooks';
import React from 'react';
import styled from 'styled-components';

const PropertyMedia: React.FC = () => {
  const {
    query: { mediaUrl, mediaType },
  } = useRouter();
  return (
    <MediaContainer>
      {mediaType === 'video' ? (
        <video controls style={{ width: '100%', height: 'auto' }}>
          <track kind="captions" />
          <source
            src={typeof mediaUrl === 'string' ? mediaUrl : undefined}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      ) : (
        <img
          src={typeof mediaUrl === 'string' ? mediaUrl : undefined}
          alt="Property Media"
          style={{ width: '100%', height: 'auto' }}
        />
      )}
    </MediaContainer>
  );
};

export default PropertyMedia;

const MediaContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  height: 100%;
`;
