import * as React from 'react';
import { oliveGreen } from 'styles/sharedStyle';
import ShowMoreButton from 'components/@client/Button/ShowMoreButton';
import { useProductsMatrix, useRouter } from 'hooks';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import { size, isEmpty } from 'utils/lodash.utils';
import styled from 'styled-components';
import { LogsAPI } from 'api/methods';
import { userLogData } from 'mockData/userLogData';
import List from 'components/@client/ProductsSection/List';
import Controller from './Controller';

interface Props {
  propertyId: string;
}

const ProductsSection: React.FC<Props> = ({ propertyId }) => {
  const [selectedRooms, setSelectedRooms] = React.useState<Array<number>>([]);
  const [selectedCategories, setSelectedCategories] = React.useState<
    Array<number>
  >([]);
  const {
    quoteComponents,
    productsCount,
    isLoading,
    getProducts,
    getMoreProducts,
    isGetMoreRef,
    allMatrixComponents,
    isAllProductsLoading,
  } = useProductsMatrix(propertyId, selectedRooms);
  const isShowMoreButton =
    !isLoading &&
    !isEmpty(quoteComponents) &&
    size(quoteComponents) < productsCount;

  const { query } = useRouter<{
    backTo?: string;
    id: string;
    projectid: string;
    name: string;
  }>();
  return (
    <>
      <Controller
        productsCount={productsCount}
        selectedRoom={selectedRooms}
        setSelectedRoom={setSelectedRooms}
        getProducts={getProducts}
        propertyId={query.id}
        unitId={propertyId}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        allMatrixComponents={allMatrixComponents}
        isAllProductsLoading={isAllProductsLoading}
      />

      <StyledList
        propertyId={propertyId}
        quoteComponents={quoteComponents}
        isLoading={!isGetMoreRef.current && isLoading}
        selectedRoom={0}
        isMatrix={true}
      />

      {/* Skeleton list for new added products */}
      {isGetMoreRef.current && isLoading && (
        <StyledList isLoading={isLoading} isMatrix={true} />
      )}

      {isShowMoreButton && (
        <Container>
          <ShowMoreButton
            className="show-more-button"
            fullWidth
            onClick={() => {
              LogsAPI.postUserLogs({
                area: userLogData.eventArea.myProperties,
                section: userLogData.eventSection.propertyPage,
                name: userLogData.eventName.showMore,
                path: userLogData.eventPath.appPropertiesIdPath,
                metadata: {
                  property_id: propertyId,
                  room_id: selectedRooms,
                },
              });
              getMoreProducts(selectedRooms, selectedCategories);
            }}
          >
            <FormattedMessage id={Message.PROPERTY_BUTTON_SHOW_MORE} />
          </ShowMoreButton>
        </Container>
      )}
    </>
  );
};

export default ProductsSection;

const StyledList = styled(List)``;

const Container = styled.div`
  padding: 0 15%;
  ${({ theme }) => theme.utils.centerFlex}
  margin-top: 28px;
  && .show-more-button {
    background-color: ${oliveGreen};
    border-radius: 12px;
    font-weight: 400;

    @media (min-width: 1150px) {
      max-width: 252px;
      height: 48px;
      font-size: 22px;
      line-height: 26px;
      padding: 11px 64px;
    }

    &:hover {
      background-color: #2e3f31;
    }
  }
`;
