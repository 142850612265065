import * as React from 'react';
import {
  Font,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import { PageContent, Divider } from 'documents/components';
import { VarelaRound } from 'documents/documents.config';
import theme from 'styles/theme';
import { Product } from 'types/products';
import { toCurrencyString } from 'utils/string.utils';
import { Supplier } from 'types/suppliers';

Font.register(VarelaRound);

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: theme.colors.bodyGray,
  },
  content: {
    padding: 8,
    flexDirection: 'row',
    gap: 24,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    display: 'flex',
  },
  productCard: {
    flexDirection: 'column',
    padding: 15,
    backgroundColor: 'white',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#DDD',
    width: '40%',
    height: '200px',
    marginTop: 4,
  },
  image: {
    width: 'auto',
    height: 'auto',
    maxHeight: 200,
    objectFit: 'contain',
    borderRadius: 8,
  },
  category: {
    fontSize: 10,
    color: '#888',
    marginTop: 5,
    fontFamily: 'Varela Round',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Varela Round',
    marginTop: 5,
    color: theme.colors.primary.primaryTextColor,
    textAlign: 'center',
  },
  description: {
    fontSize: 10,
    color: '#5D5D5D',
    marginTop: 5,
  },
  price: {
    fontSize: 12,
    color: '#E63946',
    fontWeight: 'bold',
    marginTop: 5,
    fontFamily: 'Varela Round',
  },
  sectionDivider: {
    marginTop: 20,
    marginBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#CCCCCC',
  },
});

interface Props {
  docName?: string;
  products?: Product[];
}

const ProductListDocument: React.FC<Props> = ({ products, docName }) => {
  return (
    <Document
      subject={docName ?? 'Product List'}
      title={docName ?? 'Product List'}
    >
      <PageContent>
        <Text style={styles.title}>{docName}</Text>
        <Divider style={{ marginBottom: 20 }} />
        <View style={styles.content}>
          {products?.map((product, index) => (
            <View key={product.id} style={styles.productCard} wrap={false}>
              {product && (
                <Image
                  src={product.thumbnail?.file ?? product.media?.[0]?.file}
                  style={styles.image}
                />
              )}
              <Text style={styles.category}>
                {product.subCategoryAltName ?? product.subCategory}
              </Text>
              <Text style={styles.title}>
                {product.altName ?? product.name}
              </Text>
              <Text style={styles.price}>
                {toCurrencyString({
                  value: product.price,
                  country: (product.supplier as Supplier).country,
                })}
              </Text>
            </View>
          ))}
        </View>
      </PageContent>
    </Document>
  );
};

export default ProductListDocument;
