import type { SortingRule } from 'react-table';
import * as Response from 'api/responses';
import type {
  BaseUser,
  BaseUserResponse as ResponseBaseUser,
  Handler,
  HandlerResponse,
  IAddress,
  MediaFile,
} from 'types/common.types';
import type { DateRange } from 'components/DateRange';
import type { BaseQuiz, Color, QuizResult } from './quizzes';
import { PriceRange, Product } from './products';
import { Units } from './projects';

export enum PropertyMediaType {
  video = 'video',
  floor_plan = 'Floor plan',
  screenshot = 'Screenshot',
  installation = 'Installation',
  hd_renders = 'HD renders',
  panorama = 'panorama',
}

export type PropertyMedia = {
  id: number;
  file: string;
  name: string;
  type: PropertyMediaType | string;
  is_primary?: boolean;
  floorType?: string;
  metadata?:
    | string
    | {
        position: {
          x: number;
          y: number;
          z: number;
        };
        rotation?: {
          x: number;
          y: number;
          z: number;
        };
      };
};

export type Property = {
  id: string;
  name: string;
  comment?: string | null;
  createdAt?: string | number;
  productsAmount?: number;
  purchasesAmount?: number;
  media?: PropertyMedia[];
  owner?: PropertyOwner | null;
  link?: string;
  quiz?: BaseQuiz;
  quizResult?: QuizResult;
  updatedAt?: string;
  status?: string;
  unit?: Units;
  co_owner?: number[];
  is_primary?: boolean;
};

export type developer = {
  created_at?: string;
  description?: string;
  id?: number;
  logo?: string;
  name?: string;
  updated_at?: string;
  website?: string;
};

export type Project = {
  id: number;
  name: string;
  number: number;
  supplier: number;
  unit_file?: string | null;
  description?: string | null;
  createdAt?: string;
  updatedAt?: string;
  cover_photo?: string;
  address?: IAddress | null;
  developer?: developer | null;
};

export type PropertyRequestParams = {
  page: number;
  size: number;
  sortBy?: SortingRule<Property>;
  search?: string;
  dateRange?: DateRange;
  withMedia?: boolean;
};

export type PropertyOwner = {
  id: string;
  firstName: string;
  lastName: string;
};

export enum PropertyPurchaseColors {
  BELOW_HALF = '#FFC5B7',
  HALF = '#FDEBC9',
  ABOVE_HALF = '#B0E3B3',
}

export enum PROPERTIES_STATUS {
  'To do' = 'to_do',
  'In progress' = 'in_progress',
  'Error in plan' = 'error_in_plan',
  Published = 'published',
  Archive = 'archive',
  'Waiting' = 'waiting',
}
export enum PROPERTIES_PRIORITY {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export type PropertyTaskType = {
  id: string;
  name: string;
  status: PROPERTIES_STATUS;
  priority: PROPERTIES_PRIORITY;
  createdAt: string;
  created_at: string;
  comment: string | null;
  files: PropertyMedia[];
  tour_file: {
    id: number;
    file: string;
  } | null;
  tourfile: {
    id: number;
    file: string;
  } | null;
};

export type ArchivedPropertyTaskType = {
  id: string;
  name: string;
  comment?: string | null;
  archive_duration?: string;
  archived_date: Date | string;
};

export type ArchivedPropertyTask = ArchivedPropertyTaskType & {
  owner: BaseUser;
  assignee: Handler;
  media: PropertyMedia[];
};

export type ArchivedPropertyTaskResponse = ArchivedPropertyTaskType & {
  owner: ResponseBaseUser;
  assignee: HandlerResponse;
  media: PropertyMedia[];
};

export type PublishedPropertyTaskType = {
  id: string;
  name: string;
  comment?: string | null;
  publish_duration?: string;
  time_published: Date | string;
};

export type PublishedPropertyTask = PublishedPropertyTaskType & {
  owner: BaseUser;
  assignee: Handler;
  media: PropertyMedia[];
};

export type PublishedPropertyTaskResponse = PublishedPropertyTaskType & {
  owner: ResponseBaseUser;
  assignee: HandlerResponse;
  media: PropertyMedia[];
};

export type PropertyTask = Omit<PropertyTaskType, 'created_at'> & {
  owner: BaseUser;
  assignee: Handler;
};

export type UnitTask = Omit<Units, 'created_at'> & {
  assignee: Handler;
};

export type PropertyTaskResponse = Omit<PropertyTaskType, 'createdAt'> & {
  owner: ResponseBaseUser;
  assignee: HandlerResponse;
};

export type CartItem = {
  id: string;
  product: {
    id: string;
    altName: string;
    colors: Color[];
    englishName: string;
    sku: string;
    supplier: {
      id: string;
      logo: MediaFile;
      companyName: string;
    };
    originalPrice: number;
    finalPrice: number;
    quantity: number;
    technicalInfo: Record<string, unknown>;
    thumbnail: MediaFile;
    subCategory: {
      hebrewName: string;
      id: number;
      name: string;
    };
    notForSale: boolean;
    type?: string;
  };
  quantity: number;
  checkoutQuantity: number;
};

export type Cart = {
  id?: string;
  property?: { id: string; name: string; link: string };
  items: CartItem[];
  subTotal: number;
  vat: number;
  finalPrice: number;
  hs?: string;
  taxRate?: number;
};

export type PropertyCart = {
  id: string;
  carts: Cart[];
  user: string;
};

export type CheckoutCart = {
  id: string;
  items: CartItem[];
  address: IAddress | null;
  finalPrice: number;
  subTotal: number;
  vat: number;
  totalPrice: number;
  totalDiscount: number;
  coupon?: string;
  hs: string;
  taxRate: number;
};

export enum SkinCategory {
  ASIAN = 'asian',
  BEACH_STYLE = 'beach_style',
  CONTEMPORARY = 'contemporary',
  CRAFTSMAN = 'craftsman',
  ECLECTIC = 'eclectic',
  FARMHOUSE = 'farmhouse',
  INDUSTRIAL = 'industrial',
  MEDITERRANEAN = 'mediterranean',
  MIDCENTURY = 'midcentury',
  MODERN = 'modern',
  RUSTIC = 'rustic',
  SCANDINAVIAN = 'scandinavian',
  SOUTHWESTERN = 'southwestern',
  TRADITIONAL = 'traditional',
  TRANSITIONAL = 'transitional',
  TROPICAL = 'tropical',
  VICTORIAN = 'victorian',
  CLASSIC = 'Classic',
  NORDIC = 'Nordic',
}

export type SkinImage = {
  id: number;
  image?: string;
  position?: number;
};

export type SkinCreator = {
  id?: number;
  description?: string;
  logo?: string;
  name?: string;
  skinImages?: SkinImage[];
};

export type Skin = Omit<
  Response.Skin,
  | 'price_range'
  | 'is_public'
  | 'skin_category'
  | 'rooms'
  | 'number_of_downloads'
  | 'is_locked'
  | 'is_popular'
  | 'sub_total'
  | 'final_price'
  | 'desktop_thumbnail'
  | 'mobile_thumbnail'
> & {
  priceRange?: PriceRange;
  isPublic: boolean;
  isLocked: boolean;
  isPopular: boolean;
  finalPrice: number;
  subTotal: number;
  downloadCount: number;
  skinCategory: SkinCategory;
  desktopThumbnail?: string;
  mobileThumbnail?: string;
  rooms?: {
    id: number;
    roomId: number;
    name: string;
    subCategories: {
      id: number;
      subCategoryId: number;
      name: string;
      product: Product;
    }[];
  }[];
  room?: { alt_name?: string; id: number; name: string };
  chipsData: {
    style: string;
    room: string;
    cost: string;
    color: string;
  };
  country: string;
  thumbnail?: string;
  createdAt?: string;
  user: {
    id: number;
    name: string;
    link?: string;
    rating?: string;
    skinCreator: SkinCreator;
  };
};

export type PaginatedSkin = {
  results: Skin[];
  count: number;
};

export type GroupedSkinListItems = { [key in SkinCategory]: Skin[] };

export type SkinFilter = {
  user?: string[];
  color?: string[];
  room?: string[];
  style?: string[];
  filterBy?: string;
  ordering?: string;
  direction?: string;
  value?: string[] | string;
  budget_from?: number;
  budget_to?: number;
};

export const SkinFilterType = {
  SORT: 'Sort',
  BUDGET: 'Budget',
  DESIGNED_BY: 'Designed By',
  DESIGN_STYLE: 'Design Style',
  POPULAR: 'Popular',
  ROOM: 'Room',
  COLOR: 'COLOR',
};

export type FilterButtonMobile = {
  name: string;
  value: string;
};

export interface PaginatedCart extends Response.TableData<CartItem> {
  subTotal?: number;
  vat?: number;
  finalPrice?: number;
  taxRate?: number;
}
