import * as React from 'react';
import * as Response from 'api/responses';
import * as Transform from 'api/transform';
import { removeEmptyValues } from 'utils/common.utils';
import type { Product } from 'types/products';
import useFetch from './useFetch';

import useNotifications from './useNotifications';

const productsSize = 8;

type QuoteComponentResults = { results: Product[]; count: number };

const useProductMatrix = (
  propertyId: string,
  selectedRoom: Array<number> = [1]
) => {
  const [currentQuotesComponents, setCurrentQuoteComponent] = React.useState<
    Product[] | null
  >(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [allMatrixComponents, setAllMatrixComponents] = React.useState<{
    count: number;
    results: Product[];
  } | null>({ count: 0, results: [] });
  const { showNotification } = useNotifications();
  const isGetMoreRef = React.useRef(false);
  const initialParamsRef = React.useRef({
    size: productsSize,
    page: 1,
    room: selectedRoom ?? undefined,
  });

  const onSuccess = React.useCallback((data: QuoteComponentResults | null) => {
    if (isGetMoreRef.current) {
      setCurrentPage(prevPage => prevPage + 1);
    } else {
      setCurrentPage(2);
    }

    setCurrentQuoteComponent(prevProducts => [
      ...(isGetMoreRef.current ? prevProducts ?? [] : []),
      ...(data?.results ?? []),
    ]);

    isGetMoreRef.current = false;
  }, []);

  const onFailure = React.useCallback(
    () =>
      showNotification({
        key: 'products/fetchClientProducts',
        message:
          'We had a problem fetching suggested products, please try again later',
        severity: 'error',
      }),
    [showNotification]
  );

  const { data: productsData, isLoading, callFetch } = useFetch<
    { count: number; results: Product[] },
    Response.Products
  >({
    initialUrl: `/api/units/${propertyId}/products/`,
    initialData: { count: 0, results: [] },
    config: { params: initialParamsRef.current },
    transform: Transform.MatrixComponents,
    onFailure,
    onSuccess,
  });

  const {
    callFetch: loadAllProducts,
    isLoading: isAllProductsLoading,
  } = useFetch<{ count: number; results: Product[] }, Response.Products>({
    initialUrl: `/api/units/${propertyId}/products/`,
    initialData: { count: 0, results: [] },
    skipOnStart: true,
    transform: Transform.MatrixComponents,
    onSuccess: data => {
      setAllMatrixComponents(
        prev =>
          ({
            count: data?.count ?? prev?.count,
            results: [...(prev?.results ?? []), ...(data?.results ?? [])],
          } as { count: number; results: Product[] })
      );
    },
  });

  React.useEffect(() => {
    setAllMatrixComponents({ count: 0, results: [] });
    loadAllProducts({
      params: removeEmptyValues({
        room: selectedRoom,
        size: 100,
      }),
    });
  }, [loadAllProducts, selectedRoom, initialParamsRef]);

  React.useEffect(() => {
    if (
      (allMatrixComponents?.count ?? 0) >
        (allMatrixComponents?.results.length ?? 0) &&
      !isLoading
    ) {
      loadAllProducts({
        params: removeEmptyValues({
          room: selectedRoom,
          size: 100,
          page: Math.ceil((allMatrixComponents?.results.length ?? 0) / 100) + 1,
        }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMatrixComponents, isLoading, loadAllProducts]);

  const { count: productsCount, results: fetchedProducts } = productsData as {
    count: number;
    results: Product[];
  };

  const getMoreProducts = React.useCallback(
    (room: Array<number>, categories: Array<number>) => {
      isGetMoreRef.current = true;
      if (room.includes(0))
        callFetch({
          params: removeEmptyValues({
            ...initialParamsRef.current,
            page: currentPage,
            sub_category_id: categories,
          }),
        });
      else
        callFetch({
          params: removeEmptyValues({
            ...initialParamsRef.current,
            page: currentPage,
            sub_category_id: categories,
            room_id: room,
          }),
        });
    },
    [callFetch, currentPage]
  );

  const getProducts = React.useCallback(
    (room: Array<number> = selectedRoom, categories?: Array<number>) => {
      setCurrentQuoteComponent([]);
      callFetch({
        params: removeEmptyValues({
          ...initialParamsRef.current,
          room_id: room,
          sub_category_id: categories,
        }),
      });
    },
    [callFetch, selectedRoom]
  );

  return {
    quoteComponents: currentQuotesComponents ?? fetchedProducts,
    productsCount,
    isGetMoreRef,
    isLoading,
    getProducts,
    getMoreProducts,
    allMatrixComponents,
    isAllProductsLoading,
  };
};

export default useProductMatrix;
