import * as React from 'react';
import ReactDOM from 'react-dom';
import Backdrop, { BackdropProps } from '@material-ui/core/Backdrop';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import {
  useDisableOverflow,
  useDownloadFile,
  useFetch,
  useNotifications,
} from 'hooks';
import {
  MOBILE_TABLET_RESOLUTION,
  TextContent,
  MOBILE_RESOLUTION,
  SMALL_TABLET_HEIGHT_RESOLUTION,
} from 'styles/sharedStyle';
import { Close } from '@material-ui/icons';
import CloseButton from 'components/Modal/CloseButton';
import { Message } from 'i18n';
import { Grid, Modal } from '@material-ui/core';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { ReactComponent as CopyIcon } from 'assets/socialIcons/Copy.svg';
import { ReactComponent as ResizeIcon } from 'assets/socialIcons/Resize.svg';
import { ReactComponent as FBIcon } from 'assets/socialIcons/Facebook.svg';
import { ReactComponent as WPIcon } from 'assets/socialIcons/Whatsapp.svg';
import { ReactComponent as TrashIcon } from 'assets/socialIcons/Trash.svg';
import MuiSlider from '@mui/material/Slider';
import { Setter } from 'types/common.types';
import { ClientConfig, METHODS } from 'api/client';
import rtl from 'styled-components-rtl';
// import IndicatorRenderer from './Indicator';
import { ViewInArOutlined } from '@mui/icons-material';
import { PropertyMedia } from 'types/properties';
import { Custom3DViewModal } from 'pages/common/layout/@client/ClientLayout';
import { ClientRouter } from 'router/routes';
import Slide, { SlideItem } from './Slide';
import { renderArrowNext, renderArrowPrev } from './Arrows';

interface Props<T extends SlideItem> extends BackdropProps {
  items: T[];
  tourLink?: string;
  onClick?: VoidFunction;
  selectedIndex?: number;
  setSelectedIndex?: Setter<number>;
  callFetch?: (config?: ClientConfig) => void;
  isFromProperty?: boolean;
}

const SlideshowModal = <T extends SlideItem>({
  items,
  open,
  tourLink,
  onClick,
  selectedIndex,
  setSelectedIndex,
  callFetch,
  isFromProperty,
  ...props
}: Props<T>) => {
  const { showNotification } = useNotifications();
  const [currentIndex, setCurrentIndex] = React.useState<number>(
    selectedIndex ?? 0
  );
  const type = items?.[currentIndex]?.type;
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);
  const [fullScreen, setFullScreen] = React.useState<string | null>(null);
  const thumbnailRef = React.useRef<HTMLUListElement>(null);
  const intl = useIntl();
  const carouselProps = React.useMemo(
    () => ({
      autoFocus: true,
      infiniteLoop: false,
      swipeable: true,
      useKeyboardArrows: true,
      showThumbs: false,
      showStatus: false,
      showArrows: !isMobile,
      emulateTouch: true,
      interval: 1000000000,
      dots: false,
      onChange: (index: number) => setCurrentIndex(index),
      renderIndicator: () => <></>,
    }),
    [isMobile]
  );
  const { handleDownload } = useDownloadFile({
    url: items[currentIndex]?.file,
  });

  const onSuccess = React.useCallback(
    _ => {
      callFetch?.();
      showNotification({
        key: 'seller/deletePhoto',
        message: intl.formatMessage({
          id: Message.LANDING_PAGE_IMAGE_DELETED_NOTIFICATION,
        }),
        severity: 'success',
      });
    },
    [showNotification, callFetch, intl]
  );

  const onCopySuccess = React.useCallback(() => {
    showNotification({
      key: 'seller/copyPhoto',
      message: intl.formatMessage({
        id: Message.LANDING_PAGE_IMAGE_COPIED_NOTIFICATION,
      }),
      severity: 'success',
    });
  }, [showNotification, intl]);

  useDisableOverflow(open);
  React.useEffect(() => {
    thumbnailRef.current?.scrollTo(currentIndex * 80, 0);
  }, [currentIndex]);

  const { callFetch: deleteFile } = useFetch({
    initialUrl: `/api/property_files/${items[currentIndex]?.file}`,
    config: {
      method: METHODS.DELETE,
    },
    skipOnStart: true,
    onSuccess,
  });
  const [isPanoramaOpen, setIsPanoramaOpen] = React.useState(false);
  const iframe3DViewRef = React.useRef<HTMLIFrameElement>(null);

  return (
    <>
      {ReactDOM.createPortal(
        <CustomBackdrop
          className="slideshow-backdrop"
          open={open}
          {...props}
        />,
        document.body
      )}
      {ReactDOM.createPortal(
        open && (
          <SlidesContainer onClick={e => e.stopPropagation()} isFromProperty>
            <>
              <CloseButton
                onClick={() => onClick?.()}
                className="close-button"
              />

              <Title isFromProperty>
                <FormattedMessage id={Message.PROPERTY_MEDIA_MODAL_TITLE} />
              </Title>
              {!isMobile && (
                <ControlContainer>
                  <Label>{type}</Label>
                </ControlContainer>
              )}
              <Grid
                className="icon-grid"
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                style={{ marginTop: '24px' }}
              >
                <TrashIcon
                  className="image-icon"
                  onClick={() =>
                    deleteFile({
                      url: `/api/property_files/${items[currentIndex].id}`,
                    })
                  }
                />
                <DownloadOutlinedIcon
                  className="image-icons"
                  onClick={() =>
                    handleDownload({ url: items[currentIndex].file })
                  }
                />
                <CopyIcon
                  className="image-icon"
                  onClick={e => {
                    e.stopPropagation();
                    // navigator.clipboard.writeText(items[currentIndex].file);
                    const hostName = `${window.location.protocol}//${window.location.host}`;
                    const encodedUrl = encodeURIComponent(
                      items[currentIndex].file
                    );
                    navigator.clipboard.writeText(
                      `${hostName}${
                        ClientRouter.PROPERTY_MEDIA
                      }?mediaUrl=${encodedUrl}&mediaType=${items[
                        currentIndex
                      ].file.replaceAll(' ', '--')}`
                    );
                    onCopySuccess();
                  }}
                />
                <FBIcon
                  className="image-icon"
                  onClick={e => {
                    e.stopPropagation();
                    const hostName = `${window.location.protocol}//${window.location.host}`;
                    const mediaUrl = encodeURIComponent(
                      items[currentIndex].file
                    );
                    const mediaType = encodeURIComponent(
                      items[currentIndex]?.type?.replaceAll(' ', '--') ?? ''
                    );

                    // Ensure correct query parameters usage
                    const shareUrl = `${hostName}${ClientRouter.PROPERTY_MEDIA}?mediaUrl=${mediaUrl}&mediaType=${mediaType}`;
                    // console.log('shareUrl: ', shareUrl);

                    // Encode the entire URL before passing it to Facebook
                    const encodedShareUrl = encodeURIComponent(shareUrl);

                    window.open(
                      `https://www.facebook.com/sharer/sharer.php?u=${encodedShareUrl}`,
                      '_blank'
                    );
                    // window.open(
                    //   `https://www.facebook.com/sharer/sharer.php?u=${items[currentIndex].file}`,
                    //   '_blank'
                    // );
                  }}
                />
                <WPIcon
                  className="image-icon"
                  onClick={e => {
                    e.stopPropagation();
                    const hostName = `${window.location.protocol}//${window.location.host}`;
                    const mediaUrl = encodeURIComponent(
                      items[currentIndex]?.file ?? ''
                    );
                    const mediaType = encodeURIComponent(
                      items[currentIndex]?.type?.replaceAll(' ', '--') ?? ''
                    );

                    // Ensure correct query parameters usage
                    const shareUrl = `${hostName}${ClientRouter.PROPERTY_MEDIA}?mediaUrl=${mediaUrl}&mediaType=${mediaType}`;
                    const encodedShareUrl = encodeURIComponent(shareUrl);
                    window.open(
                      `https://wa.me/?text=${encodedShareUrl}`,
                      '_blank'
                    );
                    // window.open(
                    //   `https://wa.me/?text=${items[currentIndex].file}`,
                    //   '_blank'
                    // );
                  }}
                />

                {(items[currentIndex] as PropertyMedia)?.type ===
                  'panorama' && (
                  <SeeIn3D onClick={() => setIsPanoramaOpen(true)} />
                )}
              </Grid>
              <Carousel
                renderArrowNext={renderArrowNext}
                renderArrowPrev={renderArrowPrev}
                selectedItem={selectedIndex}
                {...carouselProps}
              >
                {items.map(slide => (
                  <Slide key={slide.id} {...slide} />
                ))}
              </Carousel>
              {isMobile && items[currentIndex].type !== 'video' && (
                <>
                  <CustomResizeIcon
                    onClick={() => setFullScreen(items[currentIndex].file)}
                  />
                </>
              )}
              {fullScreen && (
                <FullScreenModal
                  open={Boolean(fullScreen)}
                  onClose={() => setFullScreen(null)}
                >
                  <div className="fullscreen-container">
                    <Close
                      onClick={() => setFullScreen(null)}
                      style={{
                        position: 'absolute',
                        left: '89.33%',
                        right: '6.4%',
                        top: '20px',
                        bottom: '90.15%',
                      }}
                    />
                    <img
                      src={fullScreen}
                      alt="property media"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                        margin: 'auto',
                      }}
                    />
                  </div>
                </FullScreenModal>
              )}

              {items.length >= 2 && (
                <>
                  <div
                    style={{
                      width: isMobile ? '167px' : '520px',
                      margin: '24px auto 32px auto',
                      height: '4px',
                    }}
                  >
                    <MuiSlider
                      max={items.length - 1}
                      min={0}
                      value={currentIndex}
                      onChange={(e, value) => {
                        setCurrentIndex(value as number);
                        setSelectedIndex?.(value as number);
                      }}
                      componentsProps={{
                        root: {
                          style: {
                            padding: '0',
                            display: 'block',
                          },
                        },
                        rail: {
                          style: {
                            width: isMobile ? '167px' : '520px',
                            height: '2px',
                            background: '#C4C4C4',
                            borderRadius: '30px',
                          },
                        },
                        thumb: {
                          style: {
                            width: isMobile
                              ? `${167 / items.length}px`
                              : `${520 / items.length}px`,
                            height: '2px',
                            borderRadius: '30px',
                            background: 'black',
                          },
                        },
                        track: {
                          style: {
                            width: isMobile ? '167px' : '520px',
                            height: '2px',
                            background: '#C4C4C4',
                            borderRadius: '30px',
                            border: 'none',
                          },
                        },
                      }}
                    />
                  </div>
                  {isMobile && (
                    <ThumbnailsContainer>
                      <Thumbnails id="thumbnail-list" ref={thumbnailRef}>
                        {items.map((photos, i) => (
                          <ImageThumbnail
                            key={photos.id}
                            onClick={() => setSelectedIndex?.(i)}
                          >
                            <img src={photos.file} alt={photos.name} />
                          </ImageThumbnail>
                        ))}
                      </Thumbnails>

                      {/* {showArrows && images && (
                      <ArrowButton
                        onClick={increasePhotoIndex}
                        disabled={images?.indexOf(selectedImage) === images?.length - 1}
                      >
                        <Arrow />
                      </ArrowButton>
                    )} */}
                    </ThumbnailsContainer>
                  )}
                </>
              )}
            </>
            {isPanoramaOpen && (
              <Custom3DViewModal
                open={isPanoramaOpen}
                close={() => setIsPanoramaOpen(false)}
                show={true}
                fullScreen={isMobile}
                width="100vw"
                height="100vh"
              >
                <iframe
                  ref={iframe3DViewRef}
                  title="Panorama View"
                  src={`https://cdn.pannellum.org/2.5/pannellum.htm#panorama=${items[currentIndex].file}`}
                  style={{ height: '100vh', width: '100%', border: 'none' }}
                  id="3dviewframe"
                />
              </Custom3DViewModal>
            )}
          </SlidesContainer>
        ),
        document.getElementById('overlay') as HTMLDivElement
      )}
    </>
  );
};

export default SlideshowModal;

const ImageThumbnail = styled.div`
  width: 76px;
  height: 64px;
  margin: 0 8px;
  border-radius: 5px;
  img {
    height: 64px;
    width: 76px;
    object-fit: cover;
    border-radius: 5px;
  }
`;

const SeeIn3D = styled(ViewInArOutlined)`
  && {
    width: 33px;
    height: 33px;
    margin-top: 5px;
    /* stroke: #3e3e3e; */
    color: #3e3e3e;
    fill: #3e3e3e;
    :hover {
      stroke: #fc5c42;
      width: 38px;
      height: 38px;
      background: #fc5c42;
      border-radius: 3rem;
      fill: #ffffff;
    }
  }
`;

const FullScreenModal = styled(Modal)`
  z-index: 999999 !important;
  div:first-child {
    background-color: white !important;
  }
  .fullscreen-container {
    height: 100vh;
    display: flex;
    margin: auto;
  }
`;

const CustomResizeIcon = styled(ResizeIcon)`
  stroke: rgba(255, 255, 255, 0.9);
  fill: #ffffff;
  position: relative;
  float: right;
  right: 24px;
  z-index: 9999;
  width: 32px;
  height: 32px;
  background: #3e3e3e;
  border-radius: inherit;
`;

const CustomBackdrop = styled(Backdrop)`
  background: rgba(92, 87, 87, 0.78);
  z-index: 1100;
  && {
    .close-button {
      position: absolute;
      top: 24px;
      right: 30px;

      .close-icon {
        font-size: 38px;
        opacity: 1;
      }
    }
  }
`;
const ThumbnailsContainer = styled.div``;
const Thumbnails = styled.ul`
  ${({ theme }) => theme.utils.centerFlex}
  margin: initial;
  gap: 8px;
  height: 64px;
  margin: -12px 24px 24px;
  overflow: overlay;
  justify-content: start;
  ${({ theme }) => theme.utils.scrollbar};

  @media (min-width: 1500px) {
    max-width: 377px;
    gap: 20px;
    margin: inherit;
  }

  & li {
    display: block;
  }
`;

const Title = styled.h2<{ isFromProperty: boolean }>`
  margin: 0 auto !important;
  padding: 24px 50px;
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  font-weight: 500;
  color: #282929;
  margin: 0;
  ${({ theme }) => theme.utils.centerFlex};
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
  @media ${MOBILE_RESOLUTION} {
    font-size: ${({ isFromProperty }) => isFromProperty && '22px'};
    line-height: ${({ isFromProperty }) => isFromProperty && '26px'};
    padding: ${({ isFromProperty }) => isFromProperty && '24px 0 16px 0'};
  }
`;
const ControlContainer = styled.div`
  color: #282929;
  width: 96%;
  margin: 24px auto 0;
`;

const SlidesContainer = styled.div.attrs({
  className: 'slideshow-slides-container',
})<{ isFromProperty: boolean }>`
  max-height: calc(100vh - 100px);
  overflow: auto;
  z-index: 1200;
  position: fixed;
  top: 88px;
  margin: 0 160px;
  background: #ffffff;
  border-radius: 25px;
  @media ${MOBILE_RESOLUTION} {
    ${({ isFromProperty }) => isFromProperty && '15px'};
  }
  width: -webkit-fill-available;
  svg.image-icon {
    fill: #3e3e3e;
  }
  svg.image-icons {
    fill: #2d2d2dab;
    width: 38px;
    height: 38px;
    stroke: rgba(255, 255, 255, 0.9);
    margin: 3px;
  }
  svg.image-icons:hover {
    stroke: #fc5c42;
    width: 38px;
    height: 38px;
    background: #fc5c42;
    border-radius: 3rem;
    fill: #ffffff;
  }
  svg.image-icon:hover {
    stroke: rgba(255, 255, 255, 0.9);
    background: #fc5c42;
    border-radius: 3rem;
    fill: #ffffff;
  }
  .prev-arrow svg {
    /* ${rtl`right: 5px`}; */
    ${rtl`margin-right:  8px`}
  }
  .next-arrow svg {
    ${rtl`margin-left:  8px`}
  }
  /* width: 50vw; */
  .icon-grid {
    direction: ltr;
    margin-top: 4px;
    @media (max-width: 800px) {
      margin-top: 24px;
    }
  }
  @media (max-width: 1024px) {
    width: 90vw;
  }
  @media (max-width: 800px) {
    width: 100%;
  }

  @media (max-width: 500px) {
    top: 5vh;
  }
  @media ${SMALL_TABLET_HEIGHT_RESOLUTION} {
    top: 3vh;
  }
  & .carousel.carousel-slider .control-arrow,
  & .carousel.carousel-slider .control-arrow.control-prev {
    z-index: 1205;
  }
  /* & .carousel.carousel-slider {
    @media (max-width: 800px) {
      top: 24px;
    }
  } */
  & .slider {
    z-index: 1200;
  }
  .animated {
    direction: ltr;
  }

  & .carousel.carousel-slider {
    overflow: visible;
  }

  & .control-dots {
    /* bottom: -50px; */
    @media (max-width: 800px) {
      visibility: hidden;
    }
  }
  & .slide,
  .selected {
    z-index: 1200;
  }
  .slide {
    @media (max-width: 800px) {
      padding-bottom: 0px;
    }
    .action-wrapper {
      height: 0;
    }
    .img-container {
      margin-top: 24px;
    }
  }

  && {
    .close-button {
      position: absolute;
      top: 24px;
      ${rtl`right: 30px`};
      /* color: #3E3E3E  */

      .close-icon {
        font-size: 38px;
        opacity: 1;
        color: #696969;
        @media ${MOBILE_RESOLUTION} {
          font-size: ${({ isFromProperty }) => isFromProperty && '27.43px'};
        }
      }
    }
  }
  .MuiPaper-root,
  .MuiDialog-paper {
    width: 100%;
    max-width: unset;
  }
`;

const Label = styled(TextContent).attrs({
  className: 'slideshow-label',
  size: 24,
  lineHeight: 28,
  weight: 500,
})`
  min-height: 24px;
  ${rtl`text-align: left`}
  letter-spacing: -0.01px;
  color: #282929;
  margin: 0;
`;
