import React, { useState } from 'react';
import Title from 'components/@client/TopTitle/Title';
import { Property } from 'types/properties';
import styled, { css } from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import Icon from 'components/Icon';
import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'i18n';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { GenericCarouselImage } from 'styles/sharedStyle';
import { ReactComponent as CopyIcon } from 'assets/socialIcons/Copy.svg';
import { ReactComponent as ResizeIcon } from 'assets/socialIcons/Resize.svg';
import { ReactComponent as FBIcon } from 'assets/socialIcons/Facebook.svg';
import { ReactComponent as WPIcon } from 'assets/socialIcons/Whatsapp.svg';
import { ReactComponent as TrashIcon } from 'assets/socialIcons/Trash.svg';
import { ArrowBase } from 'components/@client/SlideshowModal/Arrows';
import { sortBy } from 'lodash';
import { useDownloadFile, useFetch, useNotifications } from 'hooks';
import { ClientConfig, METHODS } from 'api/client';
import { ClientRouter } from 'router/routes';

interface Props {
  property: Property;
  onMediaClick?: (index: number) => void;
  callFetch?: (config?: ClientConfig) => void;
}

const MediaSection: React.FC<Props> = ({
  property,
  onMediaClick,
  callFetch,
}) => {
  const intl = useIntl();
  const { media } = property;
  const [currentIndex] = React.useState<number>(0);
  const [prevIndex, setPrevIndex] = useState<number>(0);
  const mediaInfo = React.useMemo(() => sortBy(media ?? [], 'type'), [media]);
  const { showNotification } = useNotifications();

  const currentValue = React.useMemo(() => {
    if (window.innerWidth <= 320) return 1;
    if (window.innerWidth <= 480) return 1.2;
    if (window.innerWidth >= 480 && window.innerWidth <= 600) return 2;
    if (window.innerWidth >= 600 && window.innerWidth <= 820) return 3;
    if (window.innerWidth > 820 && window.innerWidth < 1200) return 4;
    if (window.innerWidth >= 1200 && window.innerWidth < 1600) return 4;
    if (window.innerWidth > 1920 && window.innerWidth < 2600) return 7;
    return 5.7;
  }, []);

  const imageURL = mediaInfo.map(item => {
    return item;
  });
  const NextArrow = (props: {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
  }) => {
    const { onClick } = props;
    return (
      <ArrowContainer
        role="button"
        isOpen={true}
        onClick={onClick}
        prev={false}
        prevIndex={Math.floor(prevIndex)}
        mediaLength={media && media?.length}
        currentValue={currentValue}
      >
        <ArrowIcon className="next" />
      </ArrowContainer>
    );
  };

  const PrevArrow = (props: {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
  }) => {
    const { onClick } = props;

    return (
      <ArrowContainer
        role="button"
        isOpen={true}
        onClick={onClick}
        prev={true}
        prevIndex={Math.floor(prevIndex)}
        mediaLength={media && media?.length}
        currentValue={currentValue}
      >
        <ArrowIcon className="prev" />
      </ArrowContainer>
    );
  };
  const { handleDownload } = useDownloadFile({
    url: media?.[currentIndex]?.file,
  });

  const onSuccess = React.useCallback(
    _ => {
      callFetch?.();
      showNotification({
        key: 'seller/deletePhoto',
        message: intl.formatMessage({
          id: Message.LANDING_PAGE_IMAGE_DELETED_NOTIFICATION,
        }),
        severity: 'success',
      });
    },
    [showNotification, callFetch, intl]
  );

  const onCopySuccess = React.useCallback(() => {
    showNotification({
      key: 'seller/copyPhoto',
      message: intl.formatMessage({
        id: Message.LANDING_PAGE_IMAGE_COPIED_NOTIFICATION,
      }),
      severity: 'success',
    });
  }, [showNotification, intl]);

  const { callFetch: deleteFile } = useFetch({
    initialUrl: `/api/property_files/${imageURL}`,
    config: {
      method: METHODS.DELETE,
    },
    skipOnStart: true,
    onSuccess,
  });
  const adjustedSlidesToShow = Math.floor(currentValue) - 0.2;
  const settings = {
    slidesToShow: adjustedSlidesToShow,
    slidesToScroll: 1,
    infinite: false,
    afterChange: (index: number) => {
      setPrevIndex(index);
    },
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4.2,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3.8,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Container>
      <HeaderContainer>
        <Title
          title={
            <FormattedMessage
              id={Message.MEDIA_SECTION_TITLE}
              values={{
                mediaCount: mediaInfo.length,
                span: (chunks: string) => <span>{chunks}</span>,
              }}
            />
          }
        />
      </HeaderContainer>
      <MediaContainer>
        <Slider
          {...settings}
          slidesToShow={currentValue}
          slidesToScroll={Math.floor(currentValue)}
          swipeToSlide={false}
          nextArrow={<NextArrow />}
          prevArrow={<PrevArrow />}
          className="slider variable-width"
        >
          {mediaInfo &&
            mediaInfo?.map((item, index) => {
              return item.type === 'video' ? (
                <ListItem key={item.id}>
                  <CustomVideo src={item?.file} autoPlay loop muted>
                    <track kind="captions" />
                  </CustomVideo>
                  <div className="overlay">
                    <div className="icon-container">
                      <TopIconContainer>
                        <DownloadOutlinedIcon
                          className="image-icon download"
                          onClick={() =>
                            handleDownload({
                              url: media?.[currentIndex].file,
                            })
                          }
                        />
                        <TrashIcon
                          className="image-icon"
                          onClick={() => {
                            deleteFile({
                              url: `/api/property_files/${item.id}`,
                            });
                          }}
                        />
                      </TopIconContainer>
                      <MiddleIconContainer>
                        <ResizeIcon
                          className="image-icon-resize"
                          onClick={() => onMediaClick?.(index)}
                        />
                      </MiddleIconContainer>
                      <BottomIconContainer>
                        <CopyIcon
                          className="image-icon"
                          onClick={e => {
                            e.stopPropagation();
                            const hostName = `${window.location.protocol}//${window.location.host}`;
                            const encodedUrl = encodeURIComponent(item?.file);
                            navigator.clipboard.writeText(
                              `${hostName}${
                                ClientRouter.PROPERTY_MEDIA
                              }?mediaUrl=${encodedUrl}&mediaType=${item?.type.replaceAll(
                                ' ',
                                '--'
                              )}`
                            );
                            onCopySuccess();
                          }}
                        />
                        <FBIcon
                          className="image-icon"
                          onClick={e => {
                            e.stopPropagation();
                            const hostName = `${window.location.protocol}//${window.location.host}`;
                            const mediaUrl = encodeURIComponent(item?.file);
                            const mediaType = encodeURIComponent(
                              item?.type.replaceAll(' ', '--')
                            );

                            // Ensure correct query parameters usage
                            const shareUrl = `${hostName}${ClientRouter.PROPERTY_MEDIA}?mediaUrl=${mediaUrl}&mediaType=${mediaType}`;
                            // console.log('shareUrl: ', shareUrl);

                            // Encode the entire URL before passing it to Facebook
                            const encodedShareUrl = encodeURIComponent(
                              shareUrl
                            );

                            window.open(
                              `https://www.facebook.com/sharer/sharer.php?u=${encodedShareUrl}`,
                              '_blank'
                            );
                          }}
                        />
                        <WPIcon
                          className="image-icon"
                          onClick={e => {
                            e.stopPropagation();
                            const hostName = `${window.location.protocol}//${window.location.host}`;
                            const mediaUrl = encodeURIComponent(item?.file);
                            const mediaType = encodeURIComponent(
                              item?.type.replaceAll(' ', '--')
                            );

                            // Ensure correct query parameters usage
                            const shareUrl = `${hostName}${ClientRouter.PROPERTY_MEDIA}?mediaUrl=${mediaUrl}&mediaType=${mediaType}`;
                            const encodedShareUrl = encodeURIComponent(
                              shareUrl
                            );
                            window.open(
                              `https://wa.me/?text=${encodedShareUrl}`,
                              '_blank'
                            );
                          }}
                        />
                      </BottomIconContainer>
                    </div>
                  </div>
                </ListItem>
              ) : (
                <ListItem key={item.id}>
                  <Image imgUrl={item?.file} className="imageContainer" />
                  <div className="overlay">
                    <div className="icon-container">
                      <TopIconContainer>
                        <DownloadOutlinedIcon
                          className="image-icon download"
                          onClick={() =>
                            handleDownload({
                              url: media?.[currentIndex].file,
                            })
                          }
                        />
                        <TrashIcon
                          className="image-icon"
                          onClick={() => {
                            deleteFile({
                              url: `/api/property_files/${item.id}`,
                            });
                          }}
                        />
                      </TopIconContainer>
                      <MiddleIconContainer>
                        <ResizeIcon
                          className="image-icon-resize"
                          onClick={() => onMediaClick?.(index)}
                        />
                      </MiddleIconContainer>
                      <BottomIconContainer>
                        <CopyIcon
                          className="image-icon"
                          onClick={e => {
                            e.stopPropagation();
                            const hostName = `${window.location.protocol}//${window.location.host}`;
                            const encodedUrl = encodeURIComponent(item?.file);
                            navigator.clipboard.writeText(
                              `${hostName}${
                                ClientRouter.PROPERTY_MEDIA
                              }?mediaUrl=${encodedUrl}&mediaType=${item?.type.replaceAll(
                                ' ',
                                '--'
                              )}`
                            );
                            onCopySuccess();
                          }}
                        />
                        <FBIcon
                          className="image-icon"
                          onClick={e => {
                            e.stopPropagation();
                            const hostName = `${window.location.protocol}//${window.location.host}`;
                            const mediaUrl = encodeURIComponent(item?.file);
                            const mediaType = encodeURIComponent(
                              item?.type.replaceAll(' ', '--')
                            );

                            // Ensure correct query parameters usage
                            const shareUrl = `${hostName}${ClientRouter.PROPERTY_MEDIA}?mediaUrl=${mediaUrl}&mediaType=${mediaType}`;
                            // console.log('shareUrl: ', shareUrl);

                            // Encode the entire URL before passing it to Facebook
                            const encodedShareUrl = encodeURIComponent(
                              shareUrl
                            );

                            window.open(
                              `https://www.facebook.com/sharer/sharer.php?u=${encodedShareUrl}`,
                              '_blank'
                            );
                          }}
                        />
                        <WPIcon
                          className="image-icon"
                          onClick={e => {
                            e.stopPropagation();
                            const hostName = `${window.location.protocol}//${window.location.host}`;
                            const mediaUrl = encodeURIComponent(item?.file);
                            const mediaType = encodeURIComponent(
                              item?.type.replaceAll(' ', '--')
                            );

                            // Ensure correct query parameters usage
                            const shareUrl = `${hostName}${ClientRouter.PROPERTY_MEDIA}?mediaUrl=${mediaUrl}&mediaType=${mediaType}`;
                            const encodedShareUrl = encodeURIComponent(
                              shareUrl
                            );
                            window.open(
                              `https://wa.me/?text=${encodedShareUrl}`,
                              '_blank'
                            );
                          }}
                        />
                      </BottomIconContainer>
                    </div>
                  </div>
                </ListItem>
              );
            })}
        </Slider>
      </MediaContainer>
    </Container>
  );
};

export default MediaSection;

const CustomVideo = styled.video`
  border-radius: 10px;
  height: 166px;
  margin: 24px 0 0 0;
  width: inherit;
  padding: 0;
  object-fit: cover;
`;

const Container = styled.section`
  @media (min-width: 800px) {
    margin: 42px 160px 0px 160px;

    & .gradient-image-container {
      height: 446px;
      flex: 1;
    }
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .client-top-title {
    width: auto;
  }
  .title {
    font-size: 24px;
    line-height: 28px;
  }
  > span {
    margin: auto 0;
  }
  span {
    color: ${({ theme }) => theme.colors.primary.lightMobile};
    font-size: 20px;
  }
`;

const TopIconContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
`;

const MiddleIconContainer = styled.div`
  width: 60%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
`;

const BottomIconContainer = styled.div`
  width: 60%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
`;

const MediaContainer = styled.div`
  position: relative;
  margin-top: 10px;
  // && .horizontal-carousel {
  //   gap: 24px !important;
  // }
  && .slick-slide {
    padding: 0 12px; /* Half of the gap (24px total) */
  }

  && .slick-track {
    margin-left: -12px;
    margin-right: -12px;
  }
`;

const ArrowContainer = styled(ArrowBase)<{
  prev: boolean;
  prevIndex: number;
  mediaLength?: number;
  currentValue: number;
}>`
  position: absolute;
  top: -40px;
  top: -74px;
  ${({ theme, prev }) =>
    theme.dir === 'rtl'
      ? css`
          left: ${prev ? '62' : '0'}px;
          transform: rotate(${prev ? 0 : 180}deg);
        `
      : css`
          right: ${prev ? '62' : '0'}px;
          transform: rotate(${prev ? 180 : 0}deg);
        `}

  & .prev {
    fill: ${({ prevIndex }) => (prevIndex === 0 ? '#D9D9D9' : '#292929E5')};
    margin-right: 0px;
  }
  & .next {
    fill: ${({ prevIndex, mediaLength, currentValue }) =>
      Math.ceil(prevIndex + currentValue) === mediaLength
        ? '#D9D9D9'
        : '#292929E5'};
    margin-left: 0px;
  }
`;

const ArrowIcon = styled(Icon).attrs({
  name: 'nav-arrow-right',
})<{ isOpen: boolean }>`
  position: relative;
  left: ${({ isOpen }) => (isOpen ? -4 : 4)}px;
  ${({ theme, isOpen }) => theme.utils.rotateIcon({ isOpen })}
`;

const ListItem = styled.li`
  position: relative;
  cursor: pointer;
  // width: 256px !important;
  height: 190px;
  gap: 24px !important;
  border-radius: 10px;

  :hover {
    // width: 256px !important;
    height: 190px;
    .imageContainer {
      height: 190px;
      margin: 0 !important;
    }
    video {
      height: 190px;
      margin: 0 !important;
    }
  }
  .imageContainer {
    width: 100%;
    height: 166px;
    margin: 24px 0 0 0 !important;
    border-radius: 10px !important;
  }
  && .imageContainer {
    position: unset !important;
  }

  && .overlay {
    z-index: 999999;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    transition: 0.3s ease;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
  }
  && .icon-container {
    margin: auto;
    flex-direction: column;
    height: 90%;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .download {
      width: 32px;
      height: 32px;
    }
    svg.image-icon {
      fill: rgba(255, 255, 255, 0.9);
    }
    svg.image-icon:hover {
      fill: #fc5c42;
    }
    svg.image-icon-resize > path {
      stroke: rgba(255, 255, 255, 0.9);
    }
    svg.image-icon-resize:hover > path {
      stroke: #fc5c42;
    }
  }
  && .icon {
    color: white;
    font-size: 100px;
    /* position: absolute; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  :hover .overlay {
    opacity: 1;
  }
`;

const Image = styled(GenericCarouselImage)``;
