import Message, { LanguageMessages } from './messages.config';

const messages: LanguageMessages = {
  [Message.APP_NAME]: 'Patricia',
  [Message.LOADING]: 'טוען...',
  [Message.PRIMARY_LANGUAGE]: 'עברית',
  [Message.SECONDARY_LANGUAGE]: 'English',
  [Message.AUTH_PROVIDERS_BUTTON]: 'כניסה באמצעות {provider}',
  [Message.OR]: 'או',
  [Message.ORCONNECTWITH]: 'או התחבר עם',
  [Message.YES]: 'כן',
  [Message.NO]: 'לא',
  [Message.NO_VALUE]: 'לא זמין',
  [Message.DONE]: 'סיים',
  [Message.NOT_SUPPORTED_REGION]: 'המערכת עוד לא זמינה במדינה שלך',
  [Message.COUNTRY_SEARCH_PLACEHOLDER]: 'חפש מדינה...',
  [Message.SEARCH_NO_RESULTS]: 'לא נמצאו תוצאות מתאימות',
  [Message.LOGOUT_CONFIRM_CAPTION]:
    'האם אתם בטוחים שאתם רוצים להתנתק מ-Patricia?',
  [Message.SEARCH_PLACEHOLDER]: 'מצאו מוצר',
  [Message.MENU_ITEM_LOGIN]: 'התחברות',
  [Message.MENU_ITEM_TEAM_MEMBER_LOGIN]: 'כניסת ספקים',
  [Message.MENU_ITEM_MENU_ITEM_REGISTRATION]: 'הרשמה',
  [Message.MENU_ITEM_PROFILE]: 'הפרופיל שלי',
  [Message.MENU_ITEM_BUY_MORE_PATRICIA_COINS]: 'Buy more Patricia coins',
  [Message.MENU_ITEM_PROPERTIES]: 'הדירות שלי',
  [Message.MENU_ITEM_ORDERS]: 'ההזמנות שלי',
  [Message.MENU_ITEM_PRODUCTS]: 'קטלוג מוצרים',
  [Message.MENU_ITEM_CART]: 'סל הקניות שלי',
  [Message.MENU_ITEM_SUPPORT]: 'שירות לקוחות',
  [Message.MENU_ITEM_CONTACT]: 'צור קשר',
  [Message.CONTACT_INFO_TITLE]: 'שירות לקוחות:',
  [Message.CONTACT_INFO_DEPARTMENT]: 'מחלקה*',
  [Message.CONTACT_INFO_DEPARTMENT_OPTION1]: 'שירות לקוחות',
  [Message.CONTACT_INFO_DEPARTMENT_OPTION2]: 'תמיכה טכנית',
  [Message.CONTACT_INFO_PUBLIC_AREA]: 'אזור בעיה*',
  [Message.CONTACT_INFO_PUBLIC_AREA_OPTION1]: 'אתר ראשי - מחשב',
  [Message.CONTACT_INFO_PUBLIC_AREA_OPTION2]: 'אתר ראשי - פלאפון',
  [Message.CONTACT_INFO_PUBLIC_AREA_OPTION3]: 'אתר ראשי - טאבלט',
  [Message.CONTACT_INFO_PUBLIC_AREA_OPTION4]: 'אתר ראשי - אפליקציה',
  [Message.CONTACT_INFO_PUBLIC_AREA_OPTION5]: '3D- מחשב3D',
  [Message.CONTACT_INFO_PUBLIC_AREA_OPTION6]: '3D- טאבלט',
  [Message.CONTACT_INFO_PUBLIC_AREA_OPTION7]: '3D- פלאפון',
  [Message.CONTACT_INFO_COUNTRY_USA]: 'ארה”ב',
  [Message.CONTACT_INFO_COUNTRY_ISRAEL]: 'ישראל',
  [Message.CONTACT_INFO_WORK_DAY_TITLE]: 'ימי עבודה:',
  [Message.CONTACT_INFO_WORK_HOUR_TITLE]: 'שעות פעילות:',
  [Message.CONTACT_INFO_WORK_DAYS_USA]: 'שני - שישי',
  [Message.CONTACT_INFO_WORK_HOURS_USA]: '18:00 - 9:00 EST',
  [Message.CONTACT_INFO_WORK_DAYS_ISRAEL]: 'ראשון - חמישי',
  [Message.CONTACT_INFO_WORK_HOURS_ISRAEL]: '18:00 - 9:00 GMT+2',
  [Message.CONTACT_INFO_WORK_EMAIL]: 'team@patricia-ai.com',
  [Message.CONTACT_INFO_WORK_PHONE_USA]: '5492 691 415 1+',
  [Message.CONTACT_INFO_WORK_PHONE_ISRAEL]: '7777 656 52 972+',
  [Message.CONTACT_INFO_DEVICE_INFO]: 'מידע על המכשיר*',
  [Message.CONTACT_INFO_DEVICE_INFO_OPTION1]: 'אייפון',
  [Message.CONTACT_INFO_DEVICE_INFO_OPTION2]: 'אנדרואיד',
  [Message.CONTACT_INFO_DEVICE_INFO_OPTION3]: 'אייפד ',
  [Message.CONTACT_INFO_DEVICE_INFO_OPTION4]: 'אנדרואיד טאבלט',
  [Message.CONTACT_INFO_DEVICE_INFO_VERSION]: 'גרסת המכשיר',
  [Message.CONTACT_INFO_DESCRIPTION]: 'פירוט',
  [Message.CONTACT_INFO_THANK_YOU]: 'תודה רבה ',
  [Message.CONTACT_INFO_BACK]: 'ניצור איתך קשר בקרוב :)',
  [Message.CONTACT_SEND]: 'שלח',
  [Message.CONTACT_EMAIL_ADDRESS]: 'הכניסו כתובת אימייל',
  [Message.CONTACT_ENTER_YOUR_MESSAGE]: 'הקלידו את ההודעה פה...',
  [Message.MENU_ITEM_LOGOUT]: 'התנתק',
  [Message.FOOTER_APP_DESCRIPTION]:
    'חוויה וירטואלית מותאמת אישית לעיצוב קנייה והלבשת הבית שלך.',
  [Message.FOOTER_COPYRIGHT_STATEMENT]:
    '{currentYear} כל הזכויות שמורות © פטרישיה איי.איי',
  [Message.FOOTER_ABOUT_MENU_TITLE]: 'עלינו',
  [Message.FOOTER_ABOUT_MENU_OUR_STORY]: 'הסיפור שלנו',
  [Message.FOOTER_ABOUT_MENU_TEAM]: 'הצוות',
  [Message.FOOTER_ABOUT_MENU_CAREERS]: 'קריירה ומשרות',
  [Message.FOOTER_ABOUT_MENU_NEWS]: 'חדשות',
  [Message.FOOTER_ABOUT_MENU_MAGAZINE]: 'המגזין שלנו',
  [Message.FOOTER_SUPPORT_MENU_TITLE]: 'תמיכה טכנית',
  [Message.FOOTER_SUPPORT_MENU_QA]: 'שאלות ותשובות',
  [Message.FOOTER_SUPPORT_MENU_TERMS]: 'תנאי שימוש',
  [Message.FOOTER_SUPPORT_MENU_PRIVACY]: 'פרטיות',
  [Message.FOOTER_SUPPORT_MENU_CONTACT]: 'צור קשר',
  [Message.FOOTER_SUPPORT_MENU_ACCESSIBILITY]: 'נְגִישׁוּת',
  [Message.FOOTER_COMMUNITY_MENU_TITLE]: 'קהילה',
  [Message.FOOTER_COMMUNITY_MENU_EXPERT]: 'הצטרף למועדון המומחים',
  [Message.FOOTER_COMMUNITY_MENU_SUPPLIER]: 'הפוך לספק בפטרישיה',
  [Message.FOOTER_COMMUNITY_BECOME_A_SUPPLIER]: 'הפוך למוכר',
  [Message.FOOTER_COMMUNITY_MENU_SUPPLIER_INTERFACE]: 'כניסת ממשק ספקים',
  [Message.FOOTER_CONTACT_MENU_TITLE]: 'צור קשר',
  [Message.FOOTER_CONTACT_MENU_CUSTOMER_SERVICE]: 'שירות לקוחות:',
  [Message.FOOTER_CONTACT_MENU_PRIMARY_OFFICE_TITLE]: 'ישראל',
  [Message.FOOTER_CONTACT_MENU_SECONDARY_OFFICE_TITLE]: 'ארה"ב',
  [Message.FOOTER_CONTACT_MENU_WORK_DAYS]: 'ימי עבודה:',
  [Message.FOOTER_CONTACT_MENU_WORK_HOURS]: 'שעות עבודה:',
  [Message.FOOTER_CONTACT_MENU_PRIMARY_OFFICE_WORK_DAYS]: 'ראשון עד חמישי',
  [Message.FOOTER_CONTACT_MENU_SECONDARY_OFFICE_WORK_DAYS]: 'שני עד שישי',
  [Message.FOOTER_CONTACT_MENU_PRIMARY_OFFICE_WORK_HOURS]:
    '09:00 - 18:00 (שעון ישראל)',
  [Message.FOOTER_CONTACT_MENU_SECONDARY_OFFICE_WORK_HOURS]:
    '09:00 - 18:00 EST',
  [Message.FOOTER_CONTACT_MENU_PRIMARY_OFFICE_PHONE]: '+972 3 844 6888',
  [Message.FOOTER_CONTACT_MENU_SECONDARY_OFFICE_PHONE]: '+972 52 656 7777',
  [Message.FOOTER_CONTACT_MENU_PRIMARY_OFFICE_EMAIL]: 'team@patricia-ai.com',
  [Message.FOOTER_CONTACT_MENU_SECONDARY_OFFICE_EMAIL]: 'team@patricia-ai.com',
  [Message.FOOTER_CONTACT_QUICK_SERVICE]: 'כתוב לנו',
  [Message.FOOTER_CONTACT_WEEKLY_HOURS]: 'שעות שבועיות',
  [Message.FREE_DELIVERY]: 'משלוח חינם',
  [Message.FREE_DELIVERY_CONTENT_1]:
    'רצינו לחגוג איתכם את ההשקה של Patricia בישראל עם מבצע מטורף.',
  [Message.FREE_DELIVERY_CONTENT_2]: 'רק עכשיו ולזמן מוגבל, ',
  [Message.FREE_DELIVERY_CONTENT_3]: 'משלוחים חינם לכל חלקי הארץ!',
  [Message.HOME_HERO_TITLE]: 'ברוכים הבאים ל-{linebreak}<span>Patricia</span>',
  [Message.HOME_HERO_TITLE_WELCOME]: 'ברוך שובך',
  [Message.HOME_HERO_CAPTION]:
    'העלו תכנית דירה, עצבו את הבית{linebreak}<span>ורכשו את כל המוצרים שאהבתם.</span>',
  [Message.HOME_WATCH_DEMO]: 'איך זה עובד?',
  [Message.HOME_HERO_CAPTION_SHOP]: 'ורכשו את כל המוצרים שאהבתם.',
  [Message.HOME_HERO_SUMMARY]: 'בית החלומות שלך נמצא במרחק קליק אחד בלבד.',
  [Message.CREATE_HOME_BUTTON]: 'צור את הבית שלך',
  [Message.ENTER_HOME_BUTTON]: 'הכנס לבית שלך',
  [Message.HOME_STORIES_TITLE]: 'דברים שכדאי לדעת',
  [Message.HOME_STORIES_MEET_PATRICIA]: 'הכירו את Patricia',
  [Message.HOME_STORIES_HOW_TO_START]: 'איך מתחילים',
  [Message.HOME_STORIES_BRANDS]: 'מותגים שעובדים איתנו',
  [Message.HOME_STORIES_SKINS]: 'הכירו את Patricia skins',
  [Message.HOME_STORIES_PRODUCTS_DESIGNS]: 'העיצובים והמוצרים שלנו',
  [Message.HOME_SKINS_TITLE]: 'הסקינים של Patricia',
  [Message.HOME_SKINS_TITLE_BUTTON]: 'ראה עוד',
  [Message.HOME_SKINS_EMPTY_STATE_MESSAGE]: 'אין סקינים זמינים ברגע זה',
  [Message.HOME_MAGAZINE_TITLE]: 'מגזין',
  [Message.HOME_EXPERTS_TITLE]: 'המומחים שלנו',
  [Message.HOME_BECOME_A_SUPPLIER_TITLE]: 'הצטרפו לנבחרת המותגים שלנו',
  [Message.HOME_PRODUCTS_TITLE]: 'קנייה לפי קטגוריה',
  [Message.DISCOUNT_PERCENT]: '{percentage}% הנחה',
  [Message.HOME_PRODUCTS_TITLE_BUTTON]: 'ראה עוד',
  [Message.HOME_MAGAZINE_BANNER_TITLE]: 'מה חדש?',
  [Message.HOME_MAGAZINE_BANNER_CAPTION]:
    'בואו ללמוד את הטיפים החשובים ביותר לעיצוב הבית החלומי שלכם ולקבל השראה מהמומחים בתחום.',
  [Message.HOME_MAGAZINE_BANNER_ACTION]: 'למד עוד',
  [Message.HOME_SUPPLIER_CMS_TITLE]: 'החנות שלי',
  [Message.HOME_MANAGEMENT_TITLE]: 'הַנהָלָה',
  [Message.HOME_EXPERTS_BANNER_TITLE]: 'המומחים שלנו',
  [Message.HOME_EXPERTS_BANNER_CAPTION]:
    '.הכירו את מועדון המומחים של Patricia.{linebreak} בואו להכיר מקרוב (יותר) את מעצבי הפנים, האדריכלים, המשפיענים והמותגים המובילים שעושים את ההבדל בעולם עיצוב הפנים.',
  [Message.HOME_BECOME_A_SUPPLIER_BANNER_CAPTION]:
    'יש לך מותג לעיצוב הבית? מוזמן ליצור יחד עם פטרישיה {linebreak} קטלוג תלת מימד ולשווק את המוצרים שלך {linebreak} בפלטפורמה של פטרישיה לאלפי לקוחות פוטנציאליים.',
  [Message.HOME_BECOME_A_SUPPLIER_BANNER_CAPTION_MOBILE]:
    'יש לך מותג לעיצוב הבית? מוזמן ליצור יחד {linebreak} עם פטרישיה קטלוג תלת מימד ולשווק את {linebreak} המוצרים שלך בפלטפורמה של פטרישיה {linebreak} לאלפי לקוחות פוטנציאליים.',
  [Message.HOME_EXPERTS_BANNER_ACTION]: 'למד עוד',
  [Message.HOME_BECOME_A_SUPPLIER_BANNER_ACTION]: 'בואו נתחיל',
  [Message.HOME_ENTER_YOUR_HOME_TOOLTIP_TEXT]: `הדירה שלך אינה מוכנה עדיין לסיור בתלת מימד`,
  [Message.HOME_SKINS_BANNER_TITLE]: 'הסקינים של Patricia',
  [Message.HOME_SKINS_BANNER_CAPTION]:
    'בפעם הראשונה בעולם, אפשר להלביש עיצוב על הבית שלך בלחיצת כפתור!',
  [Message.HOME_SKINS_BANNER_ACTION]: 'לכל הסקינים',
  [Message.SHOP_NOW]: 'קנה {linebreak} עכשיו',
  [Message.SEARCH_RESULT_FOR]: 'תוצאות חיפוש עבור',
  [Message.YOUR_SEARCH_RESULT_FOR]: 'תוצאת החיפוש שלך עבור:',
  [Message.PROPERTIES_TITLE]: 'הדירות שלי',
  [Message.PROPERTIES_BUTTON]: 'הוסף דירה חדשה',
  [Message.PROPERTIES_INFO_BOX_TITLE]: 'דירות',
  [Message.PROPERTIES_INFO_BOX_TEXT]:
    'ריכזנו עבורך את כל מה שאתה צריך בכדי לעצב את הבית הוירטואלי שלך. על ידי לחיצה על אחת מהכרטיסיות בצד שמאל, תוכל לצלול אל עמוד הדירה הספציפי שלך בו תוכל לצאת.',
  [Message.PROPERTIES_EMPTY_STATE_CAPTION]:
    'אין לך דירה עדיין? {linebreak} כדי שנייצר לך דירה, {linebreak} תצטרך לעבור שאלון השראה',
  [Message.PROPERTIES_EMPTY_STATE_BUTTON_LABEL]: 'התחל לבנות את הבית שלך',
  [Message.PROPERTY_CARD_NAME]: 'שם הדירה',
  [Message.PROPERTY_CARD_QUIZ_NAME]: 'שם השאלון',
  [Message.PROPERTY_CARD_LAST_MODIFIED]: 'שינוי אחרון',
  [Message.PROPERTY_NOT_FOUND]: 'הדירה שלך לא נמצאה',
  [Message.PROPERTY_ADD_PROPERTY_MODAL_TITLE]:
    'על מנת ש Patricia תוכל ליצור עבורך מודל תלת מימדי נוסף של הבית שלך, יש להעלות תוכנית דירה מדויקת וברורה ככל האפשר.',
  [Message.PROPERTY_ADD_CLIENT_MODAL_TITLE]: 'Client Information',
  [Message.PROPERTY_ADD_PROPERTY_BUTTON_NEXT]: 'הבא',
  [Message.PROPERTY_ADD_PROPERTY_BUTTON_UPLOAD]: 'העלה',
  [Message.PROPERTY_ADD_PROPERTY_BUTTON_PREVIOUS]: 'אחורה',
  [Message.PROPERTY_ADD_PROPERTY_BUTTON_SKIP]: 'דלג',
  [Message.PROPERTY_ADD_PROPERTY_MODAL_QUIZ_TITLE]:
    'גילינו שכבר יש לנו שאלונים שעשית בעבר, אתה יכול להשתמש באחד מהם או להתחיל מחדש',
  [Message.PROPERTY_ADD_PROPERTY_MODAL_SELECT_QUIZ]: 'בחר שאלה',
  [Message.PROPERTY_ADD_PROPERTY_MODAL_QUIZ_RESULT]:
    '{quizName} (תוצאה: {quizResult})',
  [Message.PROPERTY_ADD_PROPERTY_MODAL_START_QUIZ]: 'התחל שאלון חדש',
  [Message.PROPERTY_ADD_PROPERTY_ADD_BUTTON]: 'הוסף דירה',
  [Message.PROPERTY_EDIT_PROPERTY]: 'הדירה עריכה',
  [Message.PROPERTY_BUTTON_TAKE_TOUR]: 'צאו לסיור',
  [Message.BUTTON_SHOW_ALL_MEDIA]: 'הצג את כל המדיה',
  [Message.PROPERTY_PRODUCTS_TITLE]: 'מוצרים {count}',
  [Message.PROPERTY_PRODUCTS_NO_ITEMS]: 'אין מדיה',
  [Message.PROPERTY_BUTTON_SHOW_MORE]: 'הצג עוד מוצרים',
  [Message.PROPERTY_ORDERS_TITLE]: 'הזמנות',
  [Message.PROPERTY_MEDIA_MODAL_TITLE]: 'מדיה',
  [Message.PROPERTY_CHIP_MODAL_TITLE]: 'Filter By Room',
  [Message.PROPERTY_MODAL_TITLE_SELLER]: 'הוספת דירה חדשה',
  [Message.PROPERTY_MODAL_UPLOAD_FLOOR_PLAN]: 'העלאת תכנית דירה',
  [Message.PROPERTY_MODAL_UPLOAD_FLOOR_PLAN_SUBTITLE]:
    'אנחנו נדאג לכל השארונודיע לך כשנסיים',
  [Message.PROPERTY_MODAL_DO_IT_YOURSELF_TITLE]: 'עשה זאת בעצמך',
  [Message.PROPERTY_MODAL_DO_IT_YOURSELF_SUBTITLE]:
    'העלה תכנית דירה או{linebreak}צור דירה מאפס',
  [Message.PROPERTY_MODAL_DEMO_FLOOR_PLANS_TITLE]: 'תכניות דירה מוכנות מראש',
  [Message.PROPERTY_MODAL_DEMO_FLOOR_PLANS_SUBTITLE]:
    'בחר אחת מתכניות ה{linebreak}דירה המוכנות מראש שלנו',
  [Message.PROPERTY_MODAL_COMING_SOON]: 'בקרוב',
  [Message.PROPERTY_MODAL_CUSTOMER_DETAILS]: 'פרטי לקוח',
  [Message.PROPERTY_MODAL_VALIDATION_MESSAGE]: '*השדות המסומנים הינם חובה',
  [Message.PROPERTY_CANCEL_ORDERS_TITLE]: 'ביטול הזמנה',
  [Message.PROPERTY_CANCEL_ORDERS_DESCRIPTION_LABLE]: 'תיאור :',
  [Message.PROPERTY_CANCEL_ORDERS_CONTACTINFO_LABLE]: 'פרטים ליצירת קשר :',
  [Message.PROPERTY_CANCEL_ORDERS_DESCRIPTION]:
    'לחיצה על לחצן שלח תגיש בקשה לביטול הזמנה עבור מספר הזמנה {ORDER_NUMBER}. {linebreak}ביטול ההזמנה אינו אומר שיינתן החזר כספי{linebreak}. לאחר שליחת הטופס צוות תמיכת הלקוחות שלנו ייצור איתך קשר לגבי סיום ביטול ההזמנה והחזר התשלום.{linebreak}אנא מלא את מספר הטלפון וכתובת האימייל שלך כדי שתמיכת הלקוחות שלנו תוכל ליצור איתך קשר לגבי הביטול.',
  [Message.ORDERS_CARD_ORDER_NUMBER]: 'מספר משלוח:',
  [Message.ORDERS_CARD_TRACKING_NUMBER]: 'מספר מעקב למשלוח',
  [Message.PROPERTY_ORDERS_CARD_ORDER_DATE]: 'נשלח בתאריך:',
  [Message.PROPERTY_ORDERS_NO_ORDERS_TITLE]: 'איך עוד לא הזמנתם כלום?',
  [Message.PROPERTY_ORDERS_NO_ORDERS_TEXT1]: 'מזמינים אתכם לראות ',
  [Message.PROPERTY_ORDERS_NO_ORDERS_SKINS]: 'בסקינים של Patricia',
  [Message.PROPERTY_ORDERS_NO_ORDERS_TEXT2]: 'את כל מה שחדש ',
  [Message.MORE_PROPERTIES_MODAL_TITLE]: 'נראה שיש לך יותר מדירה אחת',
  [Message.MORE_PROPERTIES_MODAL_TEXT]:
    'על ידי לחיצה על שמות הדירות השונים, תוכל לראות את סל הקניות הרלוונטי ולהמשיך לקניה מאובטחת',
  [Message.MORE_PROPERTIES_MODAL_BUTTON]: 'הבנת!',
  [Message.CAROUSEL_MEDIA_TITLE]: 'מדיה <span>({mediaCount})</span>',
  [Message.MEDIA_SECTION_TITLE]: 'מדיה <span>({mediaCount})</span>',
  [Message.CART_TITLE]: 'סל קניות',
  [Message.CART_TOTAL_ITEMS_LABEL]: '{value} מוצרים בסה"כ',
  [Message.CART_TOTAL_ITEMS_LABEL_MOBILE]: '{value} מוצרים',
  [Message.CART_ITEM_OUT_OF_STOCK_LABEL]: 'אזל במלאי',
  [Message.CART_ITEM_STOCK_LABEL]: 'במלאי',
  [Message.CART_ITEM_STOCK_LABEL_FULL]: 'מקסימום {quantity} במלאי',
  [Message.CART_ITEM_STOCK_LABEL_FEW_IN_STOCK]: 'יח’ אחרונות במלאי',
  [Message.CART_MAXIMUM_TEXT]: 'מקסימום',
  [Message.CART_ADDITIONAL_TEXT]:
    '*שימו לב כי ייתכן ויגבו עלויות נוספות בגין התקנה מוצר זה ע"י הספק',
  [Message.CART_CHECKOUT_BUTTON]: 'לתשלום',
  [Message.CART_PLACE_ORDER_BUTTON]: 'מעבר לתשלום',
  [Message.CART_KEEP_SHOPPING_BUTTON]: 'המשיכו לקניות',
  [Message.ADDED_TO_CART]: 'נוסף לעגלה',
  [Message.CHECKOUT_MISSING_ADDRESS_CAPTION]: 'אנא {action} כדי להמשיך ברכישה.',
  [Message.CHECKOUT_MISSING_ADDRESS_ACTION]: 'הוסף כתובת למשלוח',
  [Message.CART_RESTRICTION_MODAL_TITLE]: 'שניה לפני קניה',
  [Message.CART_RESTRICTION_MODAL_MISSING_ITEMS_ERROR]:
    'מצטערים, חלק מן הפרטים שבחרת אזלו מהמלאי',
  [Message.CART_RESTRICTION_MODAL_MISSING_ITEMS_SUBTITLE]:
    'ניתן לערוך את עגלת הקניות ולמצוא חלופות למוצרים',
  [Message.CART_RESTRICTION_MODAL_CONTINUE_BUTTON]: 'המשך ללא המוצרים',
  [Message.CART_RESTRICTION_MODAL_EDIT_CART_BUTTON]: 'חזור אל עגלת הקניות',
  [Message.CART_EMPTY_STATE_TITLE]:
    'איך זה שלא הוספת עדיין אף מוצר לעגלת הקניות שלך?',
  [Message.CART_EMPTY_STATE_BUTTON]: 'להתחיל לעצב',
  [Message.FREE_DELIVERY_BUTTON]: 'התחילו לעצב',
  [Message.CART_OUT_OF_STOCK_CAPTION]:
    'סל הקניות שלך מכיל רק מוצרים שחסרים כרגע במלאי, אנא עדכן את תכולת הסל כדי להמשיך ברכישה.',
  [Message.CART_DELETE_CONFIRMATION_MODAL_TITLE]: 'רגע לפני שאתה מוחק...',
  [Message.CART_DELETE_CONFIRMATION_MODAL_CAPTION]:
    'האם אתה בטוח שאתה רוצה למחוק מוצר זה?',
  [Message.CART_ALL_DELETE_CONFIRMATION_MODAL_CAPTION]:
    'האם אתה בטוח שאתה רוצה למחוק מוצר זה?',
  [Message.CART_DELETE_CONFIRMATION_MODAL_OK]: 'מחק מוצר',
  [Message.CHECKOUT_DISCOUNT_PLACEHOLDER]: 'הכנס קוד קופון',
  [Message.CHECKOUT_ITEM_QUANTITY_LABEL]: 'כמות',
  [Message.CHECKOUT_INFO_TITLE]: 'פרטים נוספים',
  [Message.CHECKOUT_ORDER_SUMMARY_TITLE]: 'סיכום הזמנה',
  [Message.CHECKOUT_YOUR_CART_TITLE]: 'העגלה שלך',
  [Message.BILLING_SHIPPING_ADDRESS_SAME_LABEL]: 'כתובת חיוב זהה לכתובת משלוח',
  [Message.CHECKOUT_PERSONAL_DETAIL_TITLE]: 'פרטים אישיים',
  [Message.CHECKOUT_PERSONAL_DETAIL_FULLNAME]: 'שם מלא',
  [Message.CHECKOUT_SHIPPING_ADDRESS_TITLE]: 'כתובת למשלוח',
  [Message.CHECKOUT_SUB_TOTAL_LABEL]: 'סכום ביניים',
  [Message.BUYCOIN_SUB_TOTAL_LABEL]: 'סכום ביניים',
  [Message.CHECKOUT_VAT_LABEL]: 'מע"מ ({value})',
  [Message.CHECKOUT_TAX_LABEL]: 'מס ({value})',
  [Message.CHECKOUT_SHIPPING_LABEL]: 'משלוח',
  [Message.CHECKOUT_DISCOUNT_LABEL]: 'הנחה',
  [Message.CHECKOUT_TOTAL_LABEL]: 'סה"כ',
  [Message.CHECKOUT_PROCEED_BUTTON]: 'רכישה',
  [Message.CHECKOUT_DISCOUNT_BUTTON]: 'החל',
  [Message.CHECKOUT_ADD_NEW_ADDRESS_BUTTON]: 'הוסף כתובת חדשה',
  [Message.CHECKOUT_ADD_NEW_ADDRESS_TEXT]: 'הוסף כתובת חדשה',
  [Message.CREDIT_CARD_PROVIDER_TITLE]: 'אנחנו מקבלים',
  [Message.CREDIT_CARD_PROVIDER_TEXT]: 'המשך לתשלום עם השירות המאובטח שלנו',
  [Message.CHECKOUT_TITLE]: 'סיכום הזמנה',
  [Message.CHECKOUT_SHIPPING_OPTIONS_TITLE]: 'אפשרויות משלוח',
  [Message.CHECKOUT_STANDARD_SHIPPING_TITLE]: ' - חגיגת השקה - משלוחים חינם',
  [Message.CHECKOUT_LATE_SHIPPING_TITLE]:
    ' - משלוח מאוחר יותר - בחר מתי לקבל את המשלוח שלך',
  [Message.CHECKOUT_SHIPPING_LABEL_DESCRIPTION]: 'צפי הגעה:',
  [Message.CHECKOUT_LATE_SHIPPING_LABEL_DESCRIPTION]: 'נשלח ב ',
  [Message.CHECKOUT_EXPRESS_SHIPPING_TITLE]: 'משלוח אקספרס',
  [Message.CHECKOUT_SUCCESS_CAPTION]:
    'קיבלנו את ההזמנה שלך! הכנסו למסך ההזמנות כדי לקבל עוד פרטים.',
  [Message.CHECKOUT_SUCCESS_BUTTON]: 'ההזמנות שלי',

  [Message.CHECKOUT_TOOLTIP_INVALID_NOTFOUND_TEXT]:
    'קוד זה אינו חוקי. נא לוודא שהעתקת את כל הקוד.',
  [Message.CHECKOUT_TOOLTIP_EXPIRED_TEXT]: 'תוקף הקוד פג. אנא נסה קוד אחר.',
  [Message.CHECKOUT_TOOLTIP_INVALID_MAX_USES_TEXT]:
    'קוד זה הגיע לשימושים המרביים שלו. אנא נסה קוד אחר.',
  [Message.BREADCRUMBS_HOME_BUTTON]: 'בית',
  [Message.USER_SHIPPING_ADDRESSES]: 'כתובת למשלוח',
  [Message.USER_DEFAULT_SHIPPING_ADDRESS]: 'כתובת משלוח נבחרת להזמנות',
  [Message.USER_SET_DEFAULT_ADDRESS_BUTTON]: 'כתובת משלוח נבחרת להזמנות',

  [Message.USER_DELETE_ACCOUNT_HEADING_1]: 'מחק את הנתונים והחשבון שלך',
  [Message.USER_DELETE_ACCOUNT_HEADING_2]: 'מחק את חשבונך ואת נתוני החשבון שלך',
  [Message.USER_DELETE_ACCOUNT_BUTTON]: 'מחיקת חשבון',
  [Message.USER_DELETE_ACCOUNT_GREETING_TEXT]: 'היי',
  [Message.USER_DELETE_ACCOUNT_ENTER_EMAIL_SUBTITLE]:
    'אנא הזן את כתובת הדוא"ל {email} כדי להמשיך בתהליך המחיקה',
  [Message.USER_DELETE_ACCOUNT_EMAIL_ADDRESS]: 'כתובת אימייל',
  [Message.USER_DELETE_ACCOUNT_CANCEL_BUTTON]: 'ביטול',
  [Message.USER_DELETE_ACCOUNT_NEXT_BUTTON]: 'הבא',
  [Message.USER_DELETE_ACCOUNT_DELETE_BUTTON]: 'מחק',
  [Message.USER_DELETE_ACCOUNT_SECOND_MODAL_TITLE]:
    'שים לב  במידה ותלחץ על מחק, החשבון שלך יימחק לצמיתות ולא ניתן יהיה לשחזר אותו.',
  [Message.USER_DELETE_ACCOUNT_SECOND_MODAL_SUBTITLE]:
    'אם אינך רוצה למחוק את חשבונך, לחץ על ביטול',

  [Message.USER_ADD_NEW_ADDRESS_BUTTON]: 'הוספת כתובת חדשה',
  [Message.EDIT_BUTTON]: 'עריכה',
  [Message.USER_DETAILS]: 'היי {displayName}',
  [Message.ACCOUNT_SUGGESTED_PRODUCTS_TITLE]: 'הצעות למוצרים שלנו',
  [Message.ACCOUNT_SUGGESTED_PRODUCTS_EMPTY_STATE]: 'לא נמצאו מוצרים',
  [Message.ACCOUNT_SUGGESTED_SKINS_TITLE]: 'הצעות לסקינים של Patricia',
  [Message.ACCOUNT_SUGGESTED_SKINS_EMPTY_STATE]: 'לא נמצאו סקינים',
  [Message.PROFILE_INFO_BOX_TITLE]: 'הפרטים האישיים שלך',
  [Message.PROFILE_INFO_BOX_DETAILS]:
    'בעוד שכתובת הדוא"ל שלך שומרת את נתוני המשתמש הייחודיים שלך ב Patricia ואי אפשר לשנות אותה, אל תהסס לשנות את השם הפרטית שם המשפחה או תמונת הפרופיל שלך בכל עת.',
  [Message.PROFILE_INFO_BOX_SHIPPING_TITLE]: 'כתובת למשלוח',
  [Message.PROFILE_INFO_BOX_SHIPPING_DETAILS]:
    'נהל את הכתובות שלך כרצונך. הוסף כתובת למשלוח חדשה להזמנה הבאה שלך או מחק כתובת ישנה',
  [Message.FIRST_NAME]: 'שם פרטי',
  [Message.LAST_NAME]: 'שם משפחה',
  [Message.PHONE_NUMBER]: 'מספר טלפון',
  [Message.EMAIL_TITLE]: 'דואר אלקטרוני',
  [Message.SAVE_TITLE]: 'שמירה',
  [Message.SAVE_CHANGES]: 'שמור',
  [Message.DISCARD]: 'ביטול',
  [Message.CANCEL]: 'ביטול',
  [Message.SUBMIT]: 'שלח בקשה',
  [Message.ADDRESS_FORM_EDIT]: 'עריכת כתובת',
  [Message.ADDRESS_FORM_EDIT_TO_SHIP]: 'עריכת כתובת למשלוח',
  [Message.ADDRESS_FORM_EDIT_INFO_BOX_TEXT]:
    'עברתם דירה או קומה? {linebreak} רק רוצה לעדכן משהו? {linebreak} שימו לב שכל הנתונים נכונים, כך שתוכל לקבל את המשלוח שלך בדיוק היכן שהזמנת אותו.',
  [Message.ADDRESS_FORM_ADD_INFO_BOX_TEXT]:
    'כאשר אתה מוסיף כתובת חדשה, תוכל להגדיר אותה ככתובת הראשית שלך להזמנות עתידיות.{linebreak} אל תדאג - תוכל  להוסיף/להסיר כתובת בכל עת.',
  [Message.ADDRESS_FORM_ADD_NEW_ADDRESS]: 'הוספת כתובת חדשה',
  [Message.ADDRESS_FORM_COUNTRY]: 'מדינה',
  [Message.ADDRESS_FORM_SELECT_COUNTRY]: 'בחר מדינה',
  [Message.ADDRESS_FORM_CITY]: 'עיר',
  [Message.ADDRESS_FORM_SELECT_CITY]: 'בחר עיר',
  [Message.ADDRESS_FORM_STREET_ADDRESS]: 'כתובת',
  [Message.ADDRESS_FORM_ADDRESS_LINE1]: 'שורת כתובת 1',
  [Message.ADDRESS_FORM_ADDRESS_LINE2]: 'שורת כתובת 2',
  [Message.ADDRESS_FORM_HOUSE_NO]: 'מספר בניין',
  [Message.ADDRESS_FORM_ENTRANCE]: "מס' דירה",
  [Message.ADDRESS_FORM_FLOOR]: 'קומה',
  [Message.ADDRESS_FORM_CHECKBOX_ELEVATOR]: 'מעלית',
  [Message.ADDRESS_FORM_POSTCODE]: 'מיקוד',
  [Message.ADDRESS_FORM_OPTIONAL]: 'לא חובה',
  [Message.ADDRESS_FORM_DEFAULT_ADDRESS]: 'כתובת משלוח נבחרת',
  [Message.ADDRESS_FORM_DEFAULT_ADDRESS_NEW]: 'כתובת משלוח נבחרת',
  [Message.ADDRESS_FORM_DELETE_ADDRESS]: 'מחיקת הכתובת',
  [Message.ADDRESS_FORM_BEFORE_LEAVE_DELETE_ADDRESS_TITLE]:
    ' לפני שאתה עוזב...',
  [Message.ADDRESS_FORM_BEFORE_LEAVE_DELETE_ADDRESS_TEXT]:
    'אתה בטוח שתרצה למחוק את הכתובת?',
  [Message.ADDRESS_FORM_CONFIRM_MODAL_CHANGES_TITLE]: 'תרצה לשמור שינויים?',
  [Message.ADDRESS_FORM_CONFIRM_MODAL_CHANGES_TEXT]:
    'זיהינו שביצעת שינויים.{linebreak} האם אתה בטוח שתרצה לשמור אותם?',
  [Message.INPUT_NAME_ERROR]: 'תווים לא ניתנים לשימוש',
  [Message.CLIENT_AUTH_TITLE]: 'הירשם או התחבר',
  [Message.CLIENT_AUTH_ONBOARDING_TITLE]: `היי, נעים להכיר!`,
  [Message.CLIENT_AUTH_ONBOARDING_DESCRIPTION_1]: `אני פטרישיה`,
  [Message.CLIENT_AUTH_ONBOARDING_DESCRIPTION_2]: `, עוזרת הקניות הוירטואלית הראשונה על מנת להכיר אותך יותר, חיברתי כמה שאלות כדי להגיע לתוצאה הטובה והמדוייקת`,
  [Message.CLIENT_AUTH_ONBOARDING_DESCRIPTION_3]: `ביותר עבורך`,
  [Message.CLIENT_AUTH_ONBOARDING_UPLOAD_PLAN]: `העלאת דירה`,
  [Message.CHOOSE_PLAN_CONTAINER_TITLE_1]: `העלאת תכנית הדירה`,
  [Message.CHOOSE_PLAN_CONTAINER_TITLE_2]: `<span>א</span>ו`,
  [Message.CHOOSE_PLAN_CONTAINER_TITLE_3]: `R`,
  [Message.CHOOSE_PLAN_CONTAINER_TITLE_4]: `בחירה מתכניות דירה מוכנות מראש`,
  [Message.CLIENT_AUTH_ONBOARDING_BUTTON]: 'צרו את הדירה הראשונה שלכם',
  [Message.CLIENT_AUTH_ONBOARDING_GO_BUTTON]: `בואו נתחיל`,
  [Message.CLIENT_AUTH_EMAIL_PASSWORD_BUTTON]:
    'כניסה באמצעות דואר אלקטרוני וסיסמא',
  [Message.PRODUCT_PAGE]: 'קטלוג מוצרים',
  [Message.PRODUCT_PAGE_SEARCH]: 'חפש מה שאתה רוצה',
  [Message.TOUR_PLAYER_LOADING_TEXT]: 'טוען... {linebreak} אתם כמעט בבית',
  [Message.AUTH_LOGIN_SUBMIT_BUTTON]: 'התחבר',
  [Message.AUTH_LOGIN_FORGOT_PASSWORD_BUTTON]: 'שכחת סיסמא?',
  [Message.AUTH_LOGIN_CAPTION]:
    'כדי להתחיל להשתמש במערכת Patricia תצטרך להתחבר באמצעות דואר אלקטרוני וסיסמא. אנא הכנס את כתובת הדוא"ל והסיסמא כדי להמשיך.',
  [Message.AUTH_LOGIN_REMEMBER_ME]: 'זכור אותי',
  [Message.AUTH_LOGIN_EMAIL_FIELD]: 'דואר אלקטרוני',
  [Message.AUTH_LOGIN_EMAIL_FIELD_PLACEHOLDER]:
    'לדוגמא example@patricia.com...',
  [Message.AUTH_REGISTRATION_VERIFY_EMAIL_FIELD]: 'אימות דואר אלקטרוני',
  [Message.AUTH_REGISTRATION_VERIFY_PASSWORD_FIELD]: 'וידוא סיסמא',
  [Message.AUTH_LOGIN_PASSWORD_FIELD]: 'סיסמה',
  [Message.AUTH_LOGIN_PASSWORD_FIELD_PLACEHOLDER]:
    '8-10 אותיות כולל תווים מיוחדים...',
  [Message.AUTH_LOGIN_TITLE]: 'ברוך הבא ל-Patricia',
  [Message.AUTH_REGISTRATION_READ_TERMS_AND_POLICY]:
    'קראתי והסכמתי ל{termsOfUse} וה{privacyPolicy}',
  [Message.AUTH_FORGOT_PASSWORD_PASSWORD_FIELD]: 'סיסמה חדשה',
  [Message.AUTH_FORGOT_PASSWORD_VERIFY_FIELD]: 'אימות סיסמה חדשה',
  [Message.AUTH_FORGOT_PASSWORD_TITLE]: 'איפוס סיסמה',
  [Message.AUTH_FORGOT_PASSWORD_INFO]:
    'על מנת לאפס את סיסמתך, אנא הזן את כתובת הדואר האלקטרונית אשר נרשמת איתה. בהמשך, ישלחו הוראות מפורטות לתיבת הדואר שלך, על מנת להמשיך בתהליך איפוס הסיסמה',
  [Message.AUTH_FORGOT_PASSWORD_BUTTON_LABEL]: 'אפס סיסמה',
  [Message.AUTH_FORGOT_PASSWORD_BACK_LINK]: 'חזרה לתהליך ההתחברות',
  [Message.AUTH_RESET_LINK_SENT_TITLE]: 'אימייל נשלח בהצלחה',
  [Message.AUTH_RESET_LINK_SENT_INFO]:
    'שלחנו לתיבת הדואר האלקטרונית שלך אימייל, עם פרטים נוספים להמשך תהליך איפוס הסיסמה.',
  [Message.AUTH_RESET_PASSWORD_TITLE]: 'יצירת סיסמה חדשה',
  [Message.AUTH_RESET_PASSWORD_BUTTON_LABEL]: 'שנה סיסמה',
  [Message.AUTH_RESET_PASSWORD_SUCCESS_TITLE]:
    'סיסמתך שונתה בהצלחה! {successIcon}',
  [Message.SHOP_BY_DEPARTMENT]: 'קניות לפי מחלקה',
  [Message.ORDERS_TITLE]: 'ההזמנות שלי',
  [Message.ORDER_TITLE_NUMBER]: 'הזמנה #{idNumber}',
  [Message.ORDERS_INFO_BOX_TITLE]: 'עמוד פרטי הזמנה',
  [Message.ORDERS_INFO_BOX_TEXT]:
    'להלן תקציר ההזמנה שלך.{linebreak}תוכלו למצוא מידע על הזמנות כגון המוצרים הכוללים.{linebreak}סטטוס הזמנה, מספר מעקב אחר משלוח ועוד.{linebreak}לכל בעיה אנא צור קשר עם שירות הלקוחות.',
  [Message.ORDER_DATE]: 'הוזמן בתאריך',
  [Message.ORDER_DATE_SHIPPING]: 'תאריך משלוח',
  [Message.ORDER_INFO_BOX_TITLE]: 'עמוד פרטי ההזמנה',
  [Message.ORDER_INFO_BOX_TEXT]:
    'להלן תקציר ההזמנה שלך.{linebreak}תוכלו למצוא מידע על הזמנות כגון המוצרים הכוללים.{linebreak}סטטוס הזמנה, מספר מעקב אחר משלוח ועוד.{linebreak}לכל בעיה אנא צור קשר עם שירות הלקוחות.',
  [Message.ORDER_SHIPPING_TITLE]: 'משלוח',
  [Message.ORDER_PRODUCT_COLOR_LABEL]: 'צבע',
  [Message.ORDER_SUMMARY]: 'סיכום ההזמנה',
  [Message.DISCOUNT_CODE]: 'קוד הנחה',
  [Message.ORDER_TAXES]: 'מע"מ',
  [Message.ORDER_DOWNLOAD_RECEIPT]: 'הורד קבלה',
  [Message.ORDER_ITEMS]: 'מוצרים שהוזמנו',
  [Message.ORDER_DELIVERED_ON]: 'המשלוח הגיע ב-',
  [Message.ORDER_DELIVERY_ON]: 'המשלוח יגיע ב-',
  [Message.ORDER_STATUS_WAITING_SHIPPING]: 'מחכה למשלוח',
  [Message.ORDER_STATUS_SHIPPING]: 'במשלוח',
  [Message.ORDER_STATUS_ARRIVED]: 'הגיעה',
  [Message.ORDER_STATUS_PENDING]: 'ממתין',
  [Message.ORDER_STATUS_IN_PROGRESS]: 'מכינים לכם את ההזמנה',
  [Message.ORDER_STATUS_ITS_DELIVERED]: 'זה כבר אצלכם!',
  [Message.ORDER_STATUS_NOT_APPROVED]: 'ההזמנה לא אושרה',
  [Message.ORDER_STATUS_IN_DISPUTE]: 'בתהליך ביטול הזמנה',
  [Message.THINGS_THAT_ARE_ALWAYS_WORTHWHILE_AT_HOME]: 'דברים שתמיד כדאי בבית',
  [Message.PATRICIA_BEST_RECOMMENDED]:
    'המוצרים המומלצים ביותר של פטרישיה עבורך',
  [Message.SKINS_TITLE]: 'סקינים',
  [Message.SKINS_HEADER_TITLE]: 'הכירו את<span>  הסקינים </span> של',
  [Message.SKINS_HEADER_TITLE_MOBILE]:
    'הכירו את<span>  הסקינים </span>{linebreak} של Patricia',
  [Message.SINGLE_SKINS_HEADER_TITLE]: 'קצת על {value}',
  [Message.SINGLE_SKINS_HEADER_SUB_TEXT_FOR_WEB]: `ב{name} אנו מאמינות בעיצוב בתים יפים למראה ונוחים למגורים.{linebreak}
  בהשראת אורח חיים מרחבי העולם - מניו יורק ועד לונדון וכל הדרך לטוקיו - אנו נותנות {linebreak} לתרבויות אלו ביטוי עם כשרון ישראלי מקומי.{linebreak}
  כל רכיב בעיצובים שלנו מאזן אחד את השני, עושה שימוש בכל חריץ מהרצפה ועד {linebreak} התקרה, תוך תשומת לב רבה לפרטים. {linebreak}
  אנחנו אוהבות לרבד חומרים ומרקמים, ליצור תחושה של תלת מימד, ולהשתמש בפלטות {linebreak} צבעים מרגיעות המאפשרות לאסתטיקה אישית לזרוח. נרגשים מארכיטקטורה מדויקת {linebreak} המעוררת רגשות ומשלבת יצירתיות עם ארגון, אנו מחפשות כל הזמן את מה ששונה, {linebreak} ייחודי וחדש.`,
  [Message.SINGLE_SKINS_HEADER_SUB_TEXT_FOR_WEB_READ_LESS]: `ב{name} אנו מאמינות בעיצוב בתים יפים למראה ונוחים למגורים.{linebreak}
  בהשראת אורח חיים מרחבי העולם - מניו יורק ועד לונדון וכל הדרך לטוקיו - אנו נותנות {linebreak} לתרבויות אלו ביטוי עם כשרון ישראלי מקומי.`,
  [Message.SINGLE_SKINS_ABOUT_TITLE]: 'מידע על הסקין',
  [Message.SINGLE_SKINS_ABOUT_SUB_TITLE]:
    'אנחנו רוצים להפוך את חייכם לקלים ומעוצבים.{linebreak} לכן, ריכזנו ממש כאן את כל בחירות העיצוב שאנו עושים עבור לקוחותינו. עכשיו כל מה שאתה צריך לעשות הוא לראות איך הכל משתלב בבית שלך.',
  [Message.SINGLE_SKINS_SKIN_NAME]: 'שם הסקין: {name}',
  [Message.SINGLE_SKINS_ABOUT_PRICE]: 'מחיר: {price}',
  [Message.SINGLE_SKINS_PRODUCTS_SKIN]: 'מוצרים שתוכל למצוא בסקין',
  [Message.SINGLE_SKINS_FREE]: 'חינם',
  [Message.SINGLE_SKINS_ABOUT_THE_DESIGNER]: 'קצת על המעצב',
  [Message.SINGLE_SKINS_PRODUCTS_IN_THIS_SKIN]: 'מוצרים שתוכל למצוא בסקין',
  [Message.SINGLE_SKINS_READ_MORE]: 'ראה פחות',
  [Message.SINGLE_SKINS_READ_LESS]: 'ראה פחות',
  [Message.SKINS_HEADER_TEXT_BOLD]:
    'בפעם הראשונה בעולם, אפשר להלביש עיצוב על הבית שלך בלחיצת כפתור!',
  [Message.SKINS_HEADER_TEXT]:
    '</span> מעצבי הפנים המדהימים שעובדים איתנו, משפיענים בתחום ומותגים מובילים יצרו בשבילכם סקינים - עיצובי פנים מלאים לבית הכוללים רהיטים ואקססוריז בסגנונות ועיצובים שונים ומלאי השראה.',
  [Message.SKINS_HEADER_TEXT_FOR_WEB]:
    'מעצבי הפנים המדהימים שעובדים{linebreak} איתנו, משפיענים בתחום ומותגים מובילים יצרו בשבילכם סקינים <span>עיצובי פנים מלאים לבית הכוללים רהיטים ואקססוריז בסגנונות ועיצובים {linebreak}שונים ומלאי השראה.</span>',
  [Message.SKINS_HEADER_TEXT_FOR_MOBILE]:
    'בפעם הראשונה בעולם,{linebreak} אפשר להלביש עיצוב על הבית שלך{linebreak}  <span>בלחיצת כפתור!{linebreak}</span>  מעצבי הפנים המדהימים שעובדים איתנו,{linebreak} משפיענים בתחום ומותגים מובילים יצרו{linebreak} בשבילכם סקינים{linebreak}  <span>עיצובי פנים מלאים לבית הכוללים רהיטים{linebreak} ואקססוריז בסגנונות ועיצובים שונים{linebreak} ומלאי השראה.</span>',
  [Message.SKINS_HEADER_SUB_TEXT_FOR_WEB]:
    'בפעם הראשונה בעולם,{linebreak} אפשר להלביש עיצוב על הבית שלך <a>בלחיצת כפתור!</a>',
  [Message.SKINS_SHOW_MORE_BUTTON]: 'הצג עוד',
  [Message.SKIN_CARD_PRICE]: 'מחיר',
  [Message.SKIN_CARD_PRICE_FREE]: 'זמין כעת',
  [Message.SKIN_DESIGNED_BY_TEXT]: 'Skin design by {value}',
  [Message.SKIN_NOT_FOUND]: 'העיצוב לא נמצא',
  [Message.SKIN_NO_DESCRIPTION_AVAILABLE]: '.תיאור המוצר לא זמין',
  [Message.SKIN_APPLY_SKIN]: 'החל סקין',
  [Message.SKIN_BUY_NOW]: 'קנה עכשיו',
  [Message.SKIN_FILTER_BY_SORT]: 'מיין',
  [Message.SKIN_FILTER_BY_BUDGET]: 'תַקצִיב',
  [Message.SKIN_FILTER_BY_DESIGNED_BY]: 'עוצב ע”י',
  [Message.SKIN_FILTER_BY_DESIGN_STYLE]: 'סגנון עיצוב',
  [Message.SKIN_FILTER_BY_POPULAR]: 'פופולרי',
  [Message.SKIN_FILTER_BY_ROOM]: 'חֶדֶר',
  [Message.SKIN_FILTER_BY_COLOR]: 'צבע',
  [Message.SKIN_FILTER_MODAL_TITLE]: 'סינון',
  [Message.SKIN_FILTER_MODAL_ALL]: 'את כל',
  [Message.SKIN_FILTER_MODAL_CLEAR]: 'נקה',
  [Message.SKIN_FILTER_MODAL_SHOW_FILTER]: 'הראה תוצאות',
  [Message.SKIN_GOOD_TO_KNOW_TITLE]: 'כדאי לדעת',
  [Message.SKIN_MORE_FROM]: 'עוד עיצובים של {user}',
  [Message.SKIN_FULL_HOUSE]: 'בית מלא',
  [Message.SKIN_LOCK_TEXT]: 'המוצרים יופיעו מיד לאחר רכישת הסקין',
  [Message.SKIN_BUY_IT_NOW]: 'רכוש כעת',
  [Message.SKINS_YOU_MAY_ALSO_LIKE]: 'אולי יעניין אותך גם',
  [Message.SUPPLIER_HEADER_VIDEO]: 'לצפות בסרטון',
  [Message.SUPPLIER_HEADER_BUTTON]: `בוא נדבר`,
  [Message.SUPPLIER_HEADER_MAIN_TITLE]: 'כבר בפטרישיה מרקטפלייס.',
  [Message.SUPPLIER_HEADER_TITLE]: 'הצטרף ל',
  [Message.SUPPLIER_HEADER_TITLE_CAPTION]: 'עם פטרישיה.',
  [Message.SUPPLIER_HEADER_TITLE_CAPTION_BOLD]: 'מהפכת המסחר בתלת מימד',
  [Message.SUPPLIER_INTEGRATION_WEEK]: 'שָׁבוּעַ',
  [Message.GET_STARTED_SUPPLIER]: 'להתחיל',
  [Message.SUPPLIER_ACTION_BUTTON]: 'הפוך למוכר - היום',
  [Message.SUPPLIER_OFFERS_MAIN_TITLE]:
    'What will my customer gain from working with Patricia?',

  [Message.TOUR_INSTRUCTIONS_MODAL_FIRST_PAGE_TITLE]: 'איך זזים בתוך הבית?',
  [Message.TOUR_INSTRUCTIONS_MODAL_CLOSE_BUTTON_TEXT]: 'אוקיי, הבנתי',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_WALK]: 'ניווט',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL]: 'כללי',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN]: 'עיצוב',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_WALK1]:
    'לחצו על הכפתור הימני בעכבר וגררו אותו בכדי להסתכל מסביב',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_WALK2]:
    'לחצו פעמיים רצוף על כל משטח כדי לעבור אליו',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_WALK3]:
    'השתמשו בגלגלת העכבר בכדי לנוע קדימה ואחורה',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_WALK4]:
    'או השתמש במקשי החצים כדי לפנות ימינה ושמאלה',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_WALK5]:
    'או השתמשו במקשי החצים כדי לנוע קדימה ואחורה',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN1]: 'עגלת קניות',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN2]: 'תצוגה בתלת מימד',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN3]: 'סיבוב מוצר',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN4]: 'מידע נוסף',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN5]: 'שכפול מוצר',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN6]: 'מחיקת מוצר',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_DESIGN1]:
    'לחצו על כפתור זה על מנת להוסיף את המוצר לעגלת הקניות שלכם',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_DESIGN2]:
    'לחצו על כפתור זה בכדי לראות את המוצר ב3D',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_DESIGN3]:
    'השתמשו בסליידר בכדי לסובב את המוצר שנבחר',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_DESIGN4]:
    'לחצו בכדי לראות את המידע המפורט של המוצר שנבחר',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_DESIGN5]:
    'לחצו בכדי ליצור עותק של המוצר שנבחר',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_DESIGN6]:
    'לחצו בכדי למחוק את המוצר שנבחר',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL1]: 'הסקינים של פטרישיה',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL2]: 'הוספת פריט',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL3]: 'עגלת קניות',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL4]: 'הגדרות תצוגה',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL5]: 'מיני מפה',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL6]: 'צילום מסך',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL7]: 'שמירה',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL8]: 'הגדרות',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL9]: 'הקודם',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL10]: 'הבא',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL11]: 'גובה הדמות',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL12]: 'בחזרה להתחלה',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL13]: 'צפיה במצב עילי',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL14]: 'עזרי מדידה',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL1]:
    'בחרו בסקין מתוך רשימת העיצוב שלנו והטמיעו אותו בביתכם בלחיצת כפתור',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL2]:
    'חפשו מוצרים חדשים והוסיפו אותם לביתכם על ידי גרירתם לתוך הבית שלך',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL3]:
    'לחצו על כפתור זה כדי להוסיף את המוצר הנבחר לעגלת הקניות שלך',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL4]:
    'שלטו והשפיעו על אור השמש | אורות מלאכותיים | הנוף מבחוץ',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL5]:
    'לחצו בכדי לראות את תוכנית הקומה של הבית. גררו את הדמות על המפה כדי לנוע במרחב',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL6]:
    'קחו צילום מסך של התצוגה הנוכחית',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL7]:
    'שמרו את העיצוב הנוכחי עם כל השינויים שביצעתם גם לכניסה הבאה שלכם',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL8]:
    'שליטה באיכת התמונה, מהירות התנועה ויחידת המדידה',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL9]:
    'לחצו בכדי לבטל את הפעולה האחרונה',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL10]:
    'לחצו בכדי לחזור על הפעולה האחרונה שבוטלה',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL11]: 'שנו את גובה המצלמה',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL12]:
    'חזרו לעמדת ההתחלה (דלת הכניסה)',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL13]:
    'הסתכלו על הבית שלכם ממבט על',
  [Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL14]:
    'השתמשו באחד משלושת כלי המדידה כדי להבין טוב יותר את מידות החלל',
  [Message.TOUR_INSTRUCTIONS_MODAL_SECOND_PAGE_TITLE]: 'החלפת מוצרים',
  [Message.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_TITLE]:
    'כיצד מוסיפים/מחפשים מוצר חדש?',
  [Message.TOUR_INSTRUCTIONS_MODAL_FOURTH_PAGE_TITLE]: 'איך מחליפים סקינים?',
  [Message.TOUR_INSTRUCTIONS_MODAL_FIFTH_PAGE_TITLE]:
    'כיצד לשמור את כל העיצוב?',
  [Message.TOUR_INSTRUCTIONS_MODAL_SIXTH_PAGE_TITLE]:
    'הגדרות כלליות ותכונות אחרות',
  [Message.TOUR_INSTRUCTIONS_MODAL_FIRST_PAGE_BULLET_ONE]:
    'על מנת להסתכל מסביבך, לחצ/י על הלחצן הימני בעכבר תוך כדי גרירות על המסך לאיזור הרצוי.',
  [Message.TOUR_INSTRUCTIONS_MODAL_FIRST_PAGE_BULLET_TWO]:
    'השתמש/י במקשי החצים במקלדת כדי להתקדם בתוך סיור.',
  [Message.TOUR_INSTRUCTIONS_MODAL_SECOND_PAGE_BULLET_ONE]:
    'לחצ/י על המוצר/רהיט שברצונך להחליף. לאחר הלחיצה, תופיעה רשימת מוצרים דומים להחלפה מיידית בלחיצת כפתור.',
  [Message.TOUR_INSTRUCTIONS_MODAL_SECOND_PAGE_BULLET_TWO]:
    'לאחר לחיצה, תקבל/י רשימה של מוצרים דומים שניתן להחליף עם הפריט שבחרת על ידי לחיצה על פריט חלופי.',
  [Message.TOUR_INSTRUCTIONS_MODAL_SECOND_PAGE_BULLET_THREE]:
    'תוכל/י תמיד גם לחפש מוצר חדש/אחר בתפריט השמאלי.',
  [Message.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_ONE]:
    'לחצ/י עם סמן העכבר על כפתור החיפוש בכדי להתחיל לחפש מוצרים מקטגוריות שונות.',
  [Message.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_TWO]:
    'בחר/י את הקטגוריה הרצויה ולאחר מכן את תת-הקטגוריה, כדי לראות את המוצרים הזמינים.',
  [Message.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_THREE]:
    'באמצעות לחיצה ממושכת על סמן העכבר, ניתן לגרור את המוצר הרצוי למקום, ולשחרר את הלחיצה היכן שתרצה/י למקם את המוצר.',
  [Message.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_FOUR]:
    'תוכל/י גם לחפש מוצר לפי שם, תגיות, צבעים ועוד בתוך כל תת-קטגוריה.',
  [Message.TOUR_INSTRUCTIONS_MODAL_FOURTH_PAGE_BULLET_ONE]:
    'לחצ/י על הכפתור האדום הראשי שנמצא במרכז התפריט העליון.',
  [Message.TOUR_INSTRUCTIONS_MODAL_FOURTH_PAGE_BULLET_TWO]:
    'ניתן לבחור Skin רצוי ליישום בחינם או לרכוש את Skins מתוך המאגר הזמין.',
  [Message.TOUR_INSTRUCTIONS_MODAL_FIFTH_PAGE_BULLET_ONE]:
    'פשוט לחצ/י על כפתור השמירה(אייקון הדיסק).',
  [Message.TOUR_INSTRUCTIONS_MODAL_SIXTH_PAGE_BULLET_ONE]:
    'ניתן לשלוט ולשנות את ההגדרות השונות של הסיור על ידי לחיצה על כפתור גלגל השיניים בפינה הימנית העליונה של המסך.',
  [Message.TOUR_INSTRUCTIONS_MODAL_SIXTH_PAGE_BULLET_TWO]:
    'לאפליקציה יש עוד הרבה תכונות מעניינות ושימושיות. קח/י כמה דקות לחקור את הכפתורים השונים בממשק המשתמש ולגלות מה הם עושים :)',
  [Message.TOUR_INSTRUCTIONS_MODAL_DO_NOT_SHOW_AGAIN_CHECKBOX]:
    'אל תציג בפעם הבאה',
  [Message.TOUR_INSTRUCTIONS_MODAL_DONE_BUTTON]: 'סיים',
  [Message.SHOP_BEST_SELLERS]: 'הנמכרים ביותר',
  [Message.FILE_DROP_ZONE_TITLE]: 'העלאת קובץ',
  [Message.FILE_DROP_ZONE_DESCRIPTION]:
    'כדי לקבל את התוצאות הכי טובות, כדאי להעלות קבצי {linebreak} PDF / PNG / JPEG,{linebreak}עד 10MB או </4 עמודים',
  [Message.UPLOAD_PLAN_CONTAINER_TITLE]: '1. העלאת תוכנית דירה',
  [Message.CHOOSE_PLAN_CONTAINER_TITLE]: '1. בחר את תוכנית הדירה שלך',
  [Message.MOBILE_UPLOAD_PLAN_CONTAINER_TITLE]: 'העלה את שלך ',
  [Message.MOBILE_CHOOSE_PLAN_CONTAINER_TITLE]:
    'בחרו אחת מתכניות הדירה המוכנות שלנו',
  [Message.MOBILE_UPLOAD_PLAN_CONTAINER_BOLD_TITLE]: 'שֶׁלוֹ ',
  [Message.MOBILE_UPLOAD_PLAN_CONTAINER_BOLD_TITLE_HEBREW]: 'שלכם',
  [Message.MOBILE_UPLOAD_PLAN_CONTAINER_TITLE_HEBREW]: 'העלו את תכנית הדירה ',
  [Message.MOBILE_UPLOAD_PLAN_CONTAINER_FLOOR_PLAN_TITLE]: 'תוכנית קומה',
  [Message.CHOOSE_PLAN_CONTAINER_TEXT]:
    'אין לכם תוכנית קומה? אל תדאג :){linebreak}פשוט בחר אחד מהבתים שהוכנו מראש ותהנה{linebreak}ניסיון העיצוב של פטרישיה',
  [Message.CHOOSE_PLAN_TEXT_1]: 'אין לי תוכנית דירה,',
  [Message.CHOOSE_PLAN_TEXT_2]: 'מה עלי לעשות?',
  [Message.PLAN_BEDROOMS]: '{name} חדרי שינה',
  [Message.UPLOAD_PLAN_CONTAINER_TEXT]:
    'על מנת ש Patricia תוכל ליצור עבורך מודל תלת מימדי של הבית שלך,{linebreak} יש להעלות תוכנית דירה מדויקת וברורה ככל האפשר.',
  [Message.UPLOAD_PLAN_ALREADY_USER]: 'רשומים כבר?',
  [Message.UPLOAD_PLAN_LOGIN_LINK]: 'מעבר כניסה לאתר',
  [Message.SELECT_FLOOR_CAPTION]:
    'מצאנו שהקובץ שלך מכיל יותר מעמוד אחד. אנא בחר את העמוד המבוקש',
  [Message.UPLOAD_FILE_SUCCESS]: '2. הקובץ הועלה בהצלחה!',
  [Message.UPLOAD_LETS_START_TITLE]:
    'כעת נתחיל בשאלון עיצובי קצר שיעזור ל Patricia להכיר אותך טוב יותר :)',
  [Message.UPLOAD_LETS_START_BUTTON]: 'הבא',
  [Message.UPLOAD_HELP_PATRICIA_TITLE]:
    '3. זה הזמן להכיר את העדפות העיצוביות שלך טוב יותר',
  [Message.UPLOAD_CHOOSE_YOUR_STYLE_TITLE]:
    'בכל שאלה, בוחרים את התמונה שהכי{linebreak}<span>מדברת אליך:</span>',
  [Message.UPLOAD_BUDGET_QUESTION]: 'בחרו את <span>התקציב</span> שלכם',
  [Message.UPLOAD_BUDGET_BUTTON]: 'בחר תקציב',
  [Message.UPLOAD_ROOMS_QUESTION]: 'בחר את החדרים שתרצה <span>לעצב</span>',
  [Message.UPLOAD_ROOM_QUESTION]: 'בחר את החדרים שתרצה <span>לעצב</span>',
  [Message.UPLOAD_COLOR_QUESTION]: 'בחרו את <span>פלטת הצבעים</span> שלכם',
  [Message.DESIGN_STYLES_QUESTION]:
    'בחרו את שלכם {linebreak}<span> סגנון העיצוב</span>',
  [Message.UPLOAD_WERE_DONE_TITLE]: 'סיימנו!',
  [Message.UPLOAD_THANKS_FOR_QUIZ_TITLE]:
    '4. תודה שעזרת ל-Patricia להבין את סגנון העיצוב שלך!',
  [Message.UPLOAD_WERE_DONE_TITLE]: 'זהו סיימנו!',
  [Message.UPLOAD_WERE_DONE_TEXT]:
    'כעת Patricia יוצרת ומעצבת את הבית שלך בתלת מימד,{linebreak}אנו נעדכן בהקדם באמצעות אימייל, ברגע שיהיה מוכן.{linebreak}',
  [Message.EMAIL_GO_TO_PROMOTIONS]:
    '*שימו לב שהמייל יכול להגיע לתיקיית קידום מכירות בתיבת המייל שלכם*',
  [Message.UPLOAD_TAKE_24_TEXT]: ' זה יכול לקחת עד 24 שעות',
  [Message.DONE_GOTO_EXAMPLE_HOME_TEXT]:
    'בינתיים, נשמח להציע לך ללמוד את המערכת דרך סיור בדירה לדוגמה שהכנו בשבילך',
  [Message.UPLOAD_BUTTON_BACK_TO_HOME]: 'חזרה למסך ראשי',
  [Message.BROWSE_PRODUCT_CATALOG]: 'לקטלוג המוצרים שלנו',
  [Message.START_DEMO_TOUR]: 'לסיור בדירה לדוגמה',
  [Message.ONBOARDING_REGISTRATION_TITLE]: 'יצירת <span>מפתח</span>',
  [Message.ONBOARDING_REGISTRATION_SUBTITLE]:
    'רק עוד כמה פרטים לפני שאתה מקבל את הבית התלת מימדי שלך',

  [Message.ADD_TO_CART]: 'הוסף לעגלה',
  [Message.PRODUCT_MORE_FROM]: 'מוצרים נוספים ב {cat}',
  [Message.HOME_IDEAS_TITLE]: 'רעיונות',
  [Message.HOME_SECTION_HEADER_1]:
    'העלו את תוכנית הבית או הדירה שלכם בפורמט PDF או PNG',
  [Message.HOME_SECTION_HEADER_2]: 'גלו את סגנון העיצוב האישי שלכם',
  [Message.HOME_SECTION_HEADER_3]: 'צאו לחווית קניות חדשה בביתכם החדש - בחינם',
  [Message.HOME_SECTION_TEXT_2]:
    'ענו על שאלון השראה קצר ובחרו תמונות שגורמות לכם להרגיש בבית כדי לעזור ל Patricia להכיר אתכם.',
  [Message.HOME_SECTION_TEXT_3]:
    'לראשונה בעולם, פטרישיה תיקח אתכם לסיור תלת-מימדי ללא עלות בבית הוירטואלי המעוצב שלכם',
  [Message.NO_PRODUCT_AVAILABLE]: 'אין מוצר זמין',
  [Message.VIEW_DETAILS]: 'הצג פרטים',
  [Message.CLEAR_CART]: 'רוקן עגלה',
  [Message.NO_CATEGORY]: 'אין קטגוריה זמינה',
  [Message.NO_SUB_CATEGORY]: 'אין קטגוריית משנה זמינה',
  [Message.FREE_SHIPPING]: 'חגיגת השקה - משלוחים חינם!',
  [Message.GET_IT_BY]: 'תאריך הגעה משוער',
  [Message.SHIP_TO]: 'משלוח ל',
  [Message.GOOD_TO_KNOW]: 'טוב לדעת',
  [Message.DIMENSIONS]: 'מידות',
  [Message.DESCRIPTION]: 'תיאור',
  [Message.HEIGHT_CM]: 'גובה (ס"מ)',
  [Message.LENGTH_CM]: 'אורך (ס"מ)',
  [Message.DEPTH_CM]: 'רוחב (ס"מ)',
  [Message.HEIGHT_INCH]: 'גובה (אִינְטשׁ)',
  [Message.LENGTH_INCH]: 'אורך (אִינְטשׁ)',
  [Message.DEPTH_INCH]: 'רוחב (אִינְטשׁ)',
  [Message.CATEGORIES]: 'קטגוריות',
  [Message.SUB_CATEGORIES]: 'קטגוריות משנה',
  [Message.PRODUCTS]: 'מוצרים',
  [Message.BRANDS]: 'מותגים',
  [Message.NO_RESULT]: 'אין תוצאה זמינה',
  [Message.THREED_BUTTON_TEXT]: 'ראה מוצר בתלת מימד',
  [Message.TOUR_DEVICE_DENIED_HEADING]: 'המכשיר שלך אינו נתמך',
  [Message.TOUR_SUPPORTED_DEVICE_TITLE]:
    'נסו לוודא שאתם משתמשים במכשירים והדפדפנים הבאים',
  [Message.TOUR_SUPPORTED_DEVICES]: 'Mac | Laptop | PC',
  [Message.TOUR_SUPPORTED_BROWSER]: 'Chrome | Firefox',
  [Message.TOUR_WE_ARE_SORRY]: 'אנחנו מצטערים,',
  [Message.TOUR_ADD_ENTIRE_ROOM_TO_CART]: 'הוסף את כל החדר',
  [Message.DELETE_PROPERTY_TEXT]:
    'אתם עומדים למחוק את הדירה הזו לצמיתות. האם אתם בטוחים?',
  [Message.GO_TO_MY_PROPERTIES]: 'לדירות שלי',
  [Message.PROPERTY_NOTIFICATION_MODAL_TITLE]: 'הבית החדש שלך ב3D מוכן',
  [Message.WEBSITE_ACCESSIBILITY_TITLE]:
    'מדריך לבניית דף הסדרי נגישות - רכיב נגישות אוטומטי    ',
  [Message.WEBSITE_ACCESSIBILITY_INTRO_TITLE]: 'מבוא',
  [Message.WEBSITE_ACCESSIBILITY_INTRO_SECTION]:
    '<p>האינטרנט הוא היום המאגר הגדול ביותר של חופש המידע לכל המשתמשים, ולמשתמשים עם מוגבלויות בפרט. {linebreak} ככזה, אנו מייחסים חשיבות רבה למתן הזדמנות שווה לאנשים עם מוגבלות להשתמש במידע המוצג באתר, ולאפשר חווית גלישה טובה יותר. {linebreak} אנו שואפים להבטיח שהשירותים הדיגיטליים יהיו נגישים לאנשים עם מוגבלות, ועל כן הושקעו משאבים רבים בהקלת השימוש באתר לאנשים עם מוגבלות, ככל שניתן, מתוך אמונה שלכל אדם מגיעה הזכות לחיות בו. שוויון, כבוד, נוחות ועצמאות.</p><p> על מנת לקיים הבטחה זו אנו שואפים לעמוד ככל הניתן בהמלצות התקן הישראלי (ת"י 5568) להנגשת תכנים באינטרנט ברמת AA ולמסמך WCAG2.1 הבינלאומי.</p>',
  [Message.WEBSITE_ACCESSIBILITY_SITE_ACCESSABILITY_TITLE]:
    'איך עובדת נגישות האתר?',
  [Message.WEBSITE_ACCESSIBILITY_SITE_ACCESSABILITY_SECTION]:
    '<p>באתר יש תפריט נגישות של חברת הנגשת אתרים - נגיש בקליק. לחיצה על התפריט מאפשרת לפתוח את כפתורי הנגישות. לאחר בחירת פריט בתפריט, המתן עד שהדף ייטען.</p><p>התוכנה פועלת בדפדפנים פופולריים: Chrome, Firefox, Safari, Opera. האחריות לשימוש ויישום האתר חלה על בעלי האתר ו/או מי מטעמו, לרבות התכנים המוצגים באתר, בכפוף להגבלות ותנאי השימוש בתוכנה.</p>',
  [Message.WEBSITE_ACCESSIBILITY_MENU_TITLE]: 'אפשרות נגישות בתפריט:',
  [Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_1]:
    'חסימת ההבהובים - עצירת אלמנטים נעים וחסימת ההבהובים',
  [Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_2]:
    'הגדל את גופן האתר ל-5 גדלים שונים',
  [Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_3]:
    'התאמת האתר לקורא מסך - התאמת האתר לטכנולוגיות מסייעות כגון NVDA, JAWS',
  [Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_4]:
    'התאמה לניווט במקלדת - הפעלת ניווט במקלדת',
  [Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_5]: 'שנה את הגופן לקריאה יותר',
  [Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_6]: 'התאמת אתר לעיוורי צבעים',
  [Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_7]:
    'התאמת ניגודיות - שינוי ניגודיות צבע על סמך רקע בהיר',
  [Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_8]:
    'התאמת ניגודיות - שנה את ניגודיות הצבע על סמך רקע כהה',
  [Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_9]: 'הדגשת כותרות באתר',
  [Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_10]: 'הדגשת קישורים באתר',
  [Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_11]: 'הגדל את התצוגה לכ-200%',
  [Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_12]:
    'הגדל את הסמן ושנה את צבעו לשחור או לבן',
  [Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_13]: 'שליחת משוב על נגישות',
  [Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_14]: 'הצהרת נגישות',
  [Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_15]: 'מציג תיאור חלופי לתמונות',
  [Message.WEBSITE_ACCESSIBILITY_COORDINATOR_TITLE]: 'פנה לרכז הנגישות',
  [Message.WEBSITE_ACCESSIBILITY_COORDINATOR_DESC]:
    '<p>אם במהלך הגלישה באתר נתקלת בקשיים בנושא נגישות, צוות הנגישות של החברה עומד לרשותך במגוון ערוצי גישה לבעיות, נשמח לקבל ממך משוב.</p>{linebreak}פרטי רכז נגישות החברה{linebreak}',
  [Message.WEBSITE_ACCESSIBILITY_COORDINATOR_FIELD_NAME]: 'שֵׁם: ',
  [Message.WEBSITE_ACCESSIBILITY_COORDINATOR_FIELD_TELEPHONE]: 'טלפון:',
  [Message.WEBSITE_ACCESSIBILITY_COORDINATOR_FIELD_EMAIL]: 'אימייל :',
  [Message.WEBSITE_ACCESSIBILITY_COORDINATOR_FIELD_MAIL_ADDRESS]:
    'כתובת דואר :',
  [Message.WEBSITE_ACCESSIBILITY_ARRANGEMENTS_DEPANDANCY_TITLE]:
    'פרסום הסדרי נגישות בחברה (בהתאם לארגון, אופיו ושירותיו)',
  [Message.IDEA_PRICE_TAG_LABEL]: 'הדלק/כבה על מנת לראות תגי מחיר',
  [Message.IDEA_TAB_TITLE_TAB1]: 'אולי גם תאהב',
  [Message.IDEA_TAB_TITLE_TAB2]: 'מוצר בתמונה זו',
  [Message.IDEA_TAB_TOUR_BUTTON]: 'היכנסו לבית הזה',
  [Message.IDEA_IMAGE_TITLE]: 'צילום - Tekimport חדר רחצה לבן מודרני',
  [Message.IDEA_DESIGNED_BY_LABEL]: 'עוצב ע”י -',
  [Message.IDEA_START_TOUR_BUTTON]: 'צאו לסיור',
  [Message.IDEA_BRAND_IMAGE_TITLE]: 'מותגים שתוכלו למצוא בפנים ',
  [Message.IDEAS_TITLE]: 'רעיונות',
  [Message.IDEAS_NOT_FOUND]: 'רעיונות לא נמצאו',
  [Message.IDEAS_PRODUCT_TITLE]: 'מוצרים בתמונה זו {count}',

  [Message.SKINS_SORT_NEW]: 'החדש ביותר',
  [Message.SKINS_SORT_POPULAR]: 'פופולרי',
  [Message.SKINS_SORT_PRICE_HIGH]: 'מחיר: מהגבוה לנמוך',
  [Message.SKINS_SORT_PRICE_LOW]: 'מחיר: מהנמוך לגבוה',

  [Message.SKINS_FILTER_COLOR_RED]: 'אדום',
  [Message.SKINS_FILTER_COLOR_BLACK]: 'שחור',
  [Message.SKINS_FILTER_COLOR_BLUE]: 'כחול',
  [Message.SKINS_FILTER_COLOR_GREY]: 'אפור',
  [Message.SKINS_FILTER_COLOR_YELLOW]: 'צהוב',
  [Message.SKINS_FILTER_COLOR_ORANGE]: 'תפוז',
  [Message.SKINS_FILTER_COLOR_PURPLE]: 'סָגוֹל',
  [Message.SKINS_FILTER_COLOR_PINK]: 'ורוד',
  [Message.SKINS_FILTER_COLOR_BROWN]: 'חום',
  [Message.SKINS_FILTER_COLOR_GREEN]: 'ירוק',
  [Message.BUY_PATRICIA_COINS_TITLE]:
    'היי {name} , היתרה הנוכחית שלך עומדת על {count} מטבעות',
  [Message.BUY_PATRICIA_COINS_BUTTON_TITLE]:
    'מטבעות פטרישה - {count} מטבעות (קנה עוד)',

  [Message.ORDER_PDF_ORDER_SUMMARY_FOR]: 'סיכום הזמנה ללקוח:',
  [Message.TAKE_A_LOOK]: 'תסתכל',
  [Message.ONBOARDING_HOME_DESCRIPTION]: `אני <span>פטרישיה</span>, העוזרת האישית שלך לעיצוב וקניה לבית.{linebreak}
  על מנת לייצר לך את <span>הבית הוירטואלי האישי</span> שיתאים{linebreak}לסגנון העיצובי שלך בצורה הטובה ביותר,{linebreak}
  חיברתי כמה שאלות שיעזרו לנו.`,
  [Message.ONBOARDING_HOME_DESCRIPTION_MOBILE]: `אני <span>פטרישיה</span>,
  {linebreak}העוזרת האישית שלך לעיצוב וקניה לבית.{linebreak}
  על מנת לייצר לך את <span>הבית הוירטואלי האישי</span>
  {linebreak}שיתאים לסגנון העיצובי שלך בצורה הטובה ביותר,{linebreak} חיברתי כמה שאלות שיעזרו לנו.`,
  [Message.ONBOARDING_UPLOAD_YPUR_PLAN]: 'העלאת תכנית הדירה',
  [Message.ONBOARDING_UPLOAD_NOTICE]: `כדי לקבל את התוצאות הכי טובות, כדאי להעלות קבצי {linebreak}PDF / PNG / JPEG {linebreak} עד 10MB או 4 עמודים.`,
  [Message.ONBOARDING_FLOOR_PLAN]: 'תוכנית דירה',
  [Message.ONBOARDING__SELECTED_PREMADE_MESSAGE]:
    'בחרת <span>בתכנית {name}</span> חדרים מוכנה מראש',
  [Message.ONBOARDING_PLAN_UPLOAD_SUCCESS]:
    'תוכנית הדירה שלך <span>נקלטה בהצלחה</span>',
  [Message.ONBOARDING_AMOUNT_OF_MONEY_TO_SPEND]:
    'הגדירו את סכום הכסף שתרצו להשקיע בפרויקט זה',
  [Message.CHOOSE_IMAGE_SUBTEXT]: 'ניתן לבחור תמונה אחת בלבד',
  [Message.SELECT_ONE_COLOR]: 'ניתן לבחור פלטת צבעים אחת בלבד',
  [Message.YOUR_MOODBOARD]: 'לוח ההשראה שלך',
  [Message.KEY_CONFIRMATION_STARTED_WORKING]:
    'אני מתחילה לעבוד על <span>הבית שלך</span>',
  [Message.CREATE_DIGITAL_KEY]:
    'בכדי שהבית יהיה מאובטח ואישי רק לך, אנחנו צריכים ליצור לך כעת <span>מפתח דיגיטלי</span>',
  [Message.LETS_MAKE_KEY_BUTTON]: 'בואו ניצור מפתח',
  [Message.ONBOARDING_LEAVE_CINFIRM_TITLE]: 'האם אתם בטוחים?',
  [Message.ONBOARDING_LEAVE_CONFIRM_SUBTITLE1]: 'אתם עומדים לעזוב באמצע התהליך',
  [Message.ONBOARDING_LEAVE_CONFIRM_SUBTITLE2]:
    'במידה ותעזבו, התהליך לא יישמר.',
  [Message.OONBOARDING_LEAVE_CONFIRMATION_STAY_BUTTON]: 'אני רוצה להישאר',
  [Message.OONBOARDING_LEAVE_CONFIRMATION_LEAVE_BUTTON]: 'חזרה לעמוד הבית',
  [Message.ONBOARDING_TOOLTIP_STEP_1]: 'שלב 1',
  [Message.ONBOARDING_TOOLTIP_DESIGN_STYLE_STEP_6]: 'שלב 2',
  [Message.ONBOARDING_TOOLTIP_FLOOR_PLAN_SELECT]: 'סגנון עיצוב',
  [Message.ONBOARDING_TOOLTIP_DESIGN_STYLE_SELECT]: 'בחירת תכנית דירה',
  [Message.ONBOARDING_TOOLTIP_FLOOR_ROOM_SELECT]: 'בחירת חדרים לעיצוב',
  [Message.ONBOARDING_TOOLTIP_BUDGET_SELECT]: 'בחירת תקציב',
  [Message.ONBOARDING_TOOLTIP_BUDGET]: 'תקציב',
  [Message.ONBOARDING_TOOLTIP_CONFIRMATION]: 'אישור',
  [Message.ONBOARDING_TOOLTIP_MAKE_KEY]: 'יצירת מפתח',
  [Message.ONBOARDING_TOOLTIP_COLOR_SELECT]: 'פלטת צבעים',
  [Message.ONBOARDING_TOOLTIP_DESIGN_SELECT]: 'שאלון עיצוב',
  [Message.ONBOARDING_TOOLTIP_DESIGN_STYLE]: 'שלב 2',
  [Message.FINISH]: 'סיום',
  [Message.ONBOARDING_YOUR_MOODBOARD]: 'לוח ההשראה שלך',
  [Message.MOODBOARD_DESCRIPTION]:
    'תודה שעזרת לפטרישיה להבין את סגנון העיצוב שלך',
  [Message.ONBOARDING_DONE_KEY_CREATED_SUCCESSFULL]: 'המפתח נוצר בהצלחה!',
  [Message.ONBOARDING_DONE_SUBTITLE1]:
    'אעדכן אותך באימייל כשהבית שלך יהיה מוכן',
  [Message.ONBOARDING_DONE_EMAIL_NOTICE]:
    '(במידה ואינך רואה הודעה עד 24 שעות, בידקו בתיקיית ה “קידומי מכירות” באימייל)',
  [Message.ONBOARDING_DONE_SUBTITLE2]: 'לא יכולים לחכות?',
  [Message.ONBOARDING_DONE_SUBTITLE]:
    'אני מזמינה אתכם להיכנס <span>לעמוד הרעיונות</span> שלנו שנוצרו על ידי מעצבים{linebreak} ומשתמשים אחרים ולמצוא את ההשראה שלכם',
  [Message.ONBOARDING_DONE_SUBTITLE_MOBILE]:
    'אני מזמינה אתכם להיכנס <span>לעמוד הרעיונות</span> שלנו{linebreak} שנוצרו על ידי מעצבים ומשתמשים אחרים ולמצוא {linebreak} את ההשראה שלכם',
  [Message.ONBOARDING_DONE_RETURN_HOME_BUTTON]: 'חזור אל עמוד הבית',
  [Message.ONBOARDING_DONE_KEY_OWNER]: 'בעל המפתח:',
  [Message.ONBOARDING_DONE_PROPERTY_NUMBER]: 'מספר הנכס:',
  [Message.ONBOARDING_PATRICIA_GIFT]: 'מתנה מפטרישיה: ',
  [Message.ONBOARDING_DONE_COIN_NOTICE]: '*ליצירת תמונות באיכות HD',
  [Message.SELECT_ALL]: 'הכל',
  [Message.CLIENT_FIRST_NAME]: 'שם פרטי*',
  [Message.CLIENT_LAST_NAME]: 'שם משפחה*',
  [Message.CLIENT_EMAIL_TITLE]: 'דוא”ל*',
  [Message.CLIENT_PHONE_NUMBER_TITLE]: 'טלפון',
  [Message.CLIENT_PASSWORD]: 'סיסמת לקוח:',
  [Message.PROPERTIES_SEARCH_BUTTON]: 'לחפש',
  [Message.PROPERTIES_SEARCH_PLACEHOLDER]: 'חפש לפי שם הנכס',
  [Message.CLIENT_EMAIL_ERROR]: 'הדוא"ל כבר בשימוש, נא להזין דוא"ל חדש.',
  [Message.CLIENT_PHONE_ERROR]: 'מספר הטלפון שהוזן אינו חוקי.',
  [Message.GO_TO_IDEAS_BUTTON]: 'לעמוד הרעיונות',
  [Message.ONBOARDING_PLAN_UPLOAD_RESOLUTION_TITLE]: 'התמונה שבחרתם גדולה מידי',
  [Message.ONBOARDING_PLAN_UPLOAD_RESOLUTION_SUBTITLE]: 'אנא בחרו תמונה אחרת',
  [Message.ONBOARDING_PLAN_UPLOAD_RESOLUTION_NOTICE_DESKTOP]:
    'כמות תווים מקסימלית 10,000 תווים {linebreak} יחס התמונה צריך להיות 3:1',
  [Message.ONBOARDING_PLAN_UPLOAD_RESOLUTION_NOTICE_MOBILE]:
    'כמות תווים מקסימלית 10,000 תווים {linebreak} יחס התמונה צריך להיות 3:1',
  [Message.ONBOARDING_PLAN_UPLOAD_RESOLUTION_OK_BUTTON]: 'אוקיי, הבנתי!',
  [Message.REGISTRATION_PHONE_NUMBER]: 'מספר טלפון',
  [Message.IDEAS_CARD_VIEWS]: 'צפיות {viewCount}',
  [Message.ONBOARDING_QUIZ_PANORAMA_QUESTION]:
    'בחרו את <span>תצוגה</span> מהדירה שלכם',
  [Message.UPDATE_AVAILABLE_TITLE]: 'עדכון מערכת',
  [Message.UPDATE_AVAILABLE_DESCRIPTION]:
    'גרסה חדשה ומעודכנת של האפליקציה זמינה כעת להורדה בחנות האפליקציות, שימו לב כי הגרסאות הישנות יותר אינן נתמכות עוד.',
  [Message.UPDATE_BUTTON_TEXT]: 'עדכנו עכשיו',

  [Message.LANDING_PAGE_HEADING]: 'בואו לעצב את הבית בחינם',
  [Message.LANDING_PAGE_UPLOAD_FLOOR_PLAN_BUTTON]: 'העלו תכנית דירה',
  [Message.LANDING_PAGE_TEXT]:
    'רוצים לראות איך {linebreak} הרהיטים החדשים {linebreak} משתלבים אצלכם בבית?',
  [Message.LANDING_PAGE_TEXT_MOBILE]:
    'לא מצליחים לדמיין איך{linebreak} הרהיטים החדשים {linebreak}משתלבים אצלכם בבית?',
  [Message.LANDING_PAGE_SUB_TEXT]:
    'חדש ברשת ID DESIGN, מערכת תלת מימד שמדמה{linebreak} בדיוק כיצד הרהיטים החדשים ייראו אצלכם בבית',
  [Message.LANDING_PAGE_SUB_TEXT_MOBILE]:
    'חדש ברשת ID DESIGN, מערכת תלת מימד {linebreak}שמדמה בדיוק כיצד הרהיטים החדשים ייראו {linebreak}אצלכם בבית',
  [Message.LANDING_PAGE_TEXT_POINT_1]: 'ולעצב את הבית שלכם כמו שתמיד חלמתם',
  [Message.LANDING_PAGE_TEXT_POINT_2]:
    'פגישה לאחת מחנויות התצוגה שלנו בואו לתכנן',
  [Message.LANDING_PAGE_TEXT_POINT_3]:
    'השאירו פרטים והעלו תכנית דירה עוד היום קיבעו',
  [Message.LANDING_PAGE_BUTTON]: 'לצעד הראשון בדרך לבית מושלם הקליקו',
  [Message.LANDING_PAGE_MIDDLE_HEADING]: 'איך זה עובד?',
  [Message.LANDING_PAGE_UPLOAD_FLOOR_PLAN]: 'מעלים את תכנית הדירה',
  [Message.LANDING_PAGE_MEETING_SHOWROOM]: 'קובעים פגישה עם יועץ העיצוב',
  [Message.LANDING_PAGE_HIGH_QUALITY_IMAGES]: 'נהנים מחווית עיצוב באיכות גבוהה',
  [Message.LANDING_PAGE_UPLOAD_FLOOR_PLAN_TEXT]:
    'פירוט לגבי סעיף זה משהו כמו שלוש שורות פירוט לגבי סעיף זה משהו כמו שלוש שורות פירוט לגבי סעיף זה משהו כמו שלוש שורות',
  [Message.LANDING_PAGE_MEETING_SHOWROOM_TEXT]:
    'פירוט לגבי סעיף זה משהו כמו שלוש שורות פירוט לגבי סעיף זה משהו כמו שלוש שורות פירוט לגבי סעיף זה משהו כמו שלוש שורות',
  [Message.LANDING_PAGE_HIGH_QUALITY_IMAGES_TEXT]:
    'פירוט לגבי סעיף זה משהו כמו שלוש שורות פירוט לגבי סעיף זה משהו כמו שלוש שורות פירוט לגבי סעיף זה משהו כמו שלוש שורות',

  [Message.LANDING_PAGE_FORM_HEADING]: 'זה הזמן להפסיק לדמיין ולהתחיל לראות',
  [Message.LANDING_PAGE_HEADING_TEXT]: 'השאירו פרטים ואחד מנציגינו יחזור אליכם',
  [Message.LANDING_PAGE_FIRST_NAME]: 'שם פרטי*',
  [Message.LANDING_PAGE_LAST_NAME]: 'שם משפחה*',
  [Message.LANDING_PAGE_SCHEDULE_MEETING]: 'קבע פגישה*',
  [Message.LANDING_PAGE_FORM_EMAIL]: 'דוא”ל*',
  [Message.LANDING_PAGE_PHONE_NUMBER]: 'טלפון*',
  [Message.LANDING_PAGE_UPLPAD_FLOOR_PLAN_BUTTON]: 'העלאת תכנית',
  [Message.LANDING_PAGE_CHOOSE_FILE_BUTTON]: 'בחר קובץ',
  [Message.LANDING_PAGE_CHANGE_FILE_BUTTON]: 'בחר קובץ',
  [Message.LANDING_PAGE_FILE_UPLOAD_TERMS]:
    'כדי לקבל את התוצאות הכי טובות, כדאי להעלות קבצי',
  [Message.LANDING_PAGE_FILE_UPLOAD_FORMAT]:
    ' PDF / PNG / JPEG{linebreak} עד 10MB',
  [Message.LANDING_PAGE_ACCEPT_TERMS]:
    'אני מסכים/ה לכל<link>תנאי ההרשמה</link>',
  [Message.LANDING_PAGE_FINISH_BUTTON]: 'סיום',

  [Message.LANDING_PAGE_FOOTER_HEADING]: 'משהו לא ברור?',
  [Message.LANDING_PAGE_FOOTER_HEADING_TEXT]:
    'צרו קשר עוד היום או בואו לבקר אותנו באחד הסניפים',

  [Message.LANDING_PAGE_PHONE_TEXT]: 'טלפון',
  [Message.LANDING_PAGE_PHONE_NO]: '+972 52 656 7777',
  [Message.LANDING_PAGE_LOCATION]: 'מיקום',
  [Message.LANDING_PAGE_LOCATION_TEXT]: 'לח”י 34 , רמת גן 131254',
  [Message.LANDING_PAGE_EMAIL]: 'דוא”ל',
  [Message.LANDING_PAGE_EMAIL_TEXT]: 'Moshemoshi@beitili.com',

  [Message.LANDING_PAGE_CUSTOMER_SERVICE]: 'שירות לקוחות',
  [Message.LANDING_PAGE_CUSTOMER_SERVICE_WEEK]: 'ראשון - חמישי',
  [Message.LANDING_PAGE_CUSTOMER_SERVICE_WEEK_DAY]: 'שישי',
  [Message.LANDING_PAGE_CUSTOMER_SERVICE_WEEKEND]: 'שבת',

  [Message.LANDING_PAGE_CUSTOMER_SERVICE_WEEK_TIME]: '10:00 - 20:00',
  [Message.LANDING_PAGE_CUSTOMER_SERVICE_WEEK_DAY_1_TIME]: '09:00 - 14:00',
  [Message.LANDING_PAGE_CUSTOMER_SERVICE_WEEKEND_STATUS]: 'סגור',
  [Message.LANDING_PAGE_CONTACT_US]: 'צור קשר:',
  [Message.LANDING_PAGE_FOOTER_TEXT]: 'Powered by Patricia-AI',
  [Message.LANDING_PAGE_THANKYOU_SUCCESS]: 'תכנית הדירה הועלתה בהצלחה',
  [Message.LANDING_PAGE_THANKYOU_SUCCESS_SUBTITLE]:
    'תכניתך תהיה מוכנה תוך 24 שעות,{mobileLineBreak} נשמח לראותך באחד מסניפינו',
  [Message.LANDING_PAGE_THANKYOU_SUCCESS_APPOINTMENT_TEXT]:
    '*לשאלות נוספות / לקביעת פגישה בתאריך ושעה{mobileLineBreak} מסויימים, {contactName} - {contactNumber}',
  [Message.LANDING_PAGE_IMAGE_COPIED_NOTIFICATION]: 'תמונה הועתקה בהצלחה',
  [Message.LANDING_PAGE_IMAGE_DELETED_NOTIFICATION]: 'תמונה נמחקה בהצלחה.',
  [Message.VALIDATION_UPPER_CASE]: 'הקוד יכול להכיל אותיות ראשיות בלבד',
  [Message.VALIDATION_ALPHA_SPACE]: 'נא להכניס מספר תווים מתאים',
  [Message.VALIDATION_SPACE]: 'לא ניתן להשתמש ברווח',
  [Message.SUPPLIER_LOGIN_TITLE]: 'Welcome to the Patricia Sellers Portal',
  [Message.SUPPLIER_LOGIN_WELCOME_TITLE]: 'Pleasure to meet you, {name}!',
  [Message.SUPPLIER_LOGIN_THANKS_TITLE]: 'Thanks for the info!',
  [Message.SUPPLIER_LOGIN_CONGRATULATIONS_TITLE]: 'Congratulations!',
  [Message.SUPPLIER_LOGIN_CONGRATULATIONS_STORE_OPEN_TITLE]:
    'Your store has been successfully opened.',
  [Message.SUPPLIER_LOGIN_CONGRATULATIONS_STORE_OPEN_TITLE_MOBILE]:
    'Your store has been{linebreak} successfully opened',
  [Message.SUPPLIER_LOGIN_CONGRATULATIONS_JOIN_TITLE]:
    'Thank you for joining us.',
  [Message.SUPPLIER_LOGIN_CONGRATULATIONS_PROVIDED_TITLE]:
    'Thanks for the information you provided.',
  [Message.SUPPLIER_LOGIN_CONGRATULATIONS_PROVIDED_TITLE_MOBILE]:
    'Thanks for the information{linebreak} you provided.',
  [Message.SUPPLIER_REGISTER_SUBTITLE]: 'Fill in all the details below',
  [Message.SUPPLIER_REGISTER_TITLE]: 'Please sign up to continue',
  [Message.SUPPLIER_LOGIN_SUBTITLE]: 'Please sign in or <span>sign up</span>',
  [Message.SUPPLIER_LOGIN_WELCOME_SUBTITLE]: `We're just a few steps away from seeing your products come to life in 3D. To get things started, we need some info on your needs.`,
  [Message.SUPPLIER_LOGIN_WELCOME_SUBTITLE_MOBILE]: `We're just a few steps away from seeing{linebreak} your products come to life in 3D. To get{linebreak} things started, we need to know you{linebreak} better.`,
  [Message.SUPPLIER_LOGIN_THANKS_FIRST_SUBTITLE]:
    'Now you can start creating your 3D-catalog.{linebreak}Need some assistance? You can schedule a free{linebreak}onboarding call with a Patricia team member.',
  [Message.SUPPLIER_LOGIN_THANKS_FIRST_SUBTITLE_MOBILE]:
    'Now we can start working on your new{linebreak} 3D catalog.',
  [Message.SUPPLIER_LOGIN_THANKS_SECOND_SUBTITLE]:
    'You can schedule meeting with one of Patricia representative {linebreak} helping you with your store',
  [Message.SUPPLIER_LOGIN_CONTINUE_BUTTON]: 'Continue',
  [Message.SUPPLIER_LOGIN_FIRST_CONGRATULATIONS_SUBTITLE]: `Our staff start to work on your 3D model,{linebreak} it’s will take up to <span>24 hours</span> {linebreak}`,
  [Message.SUPPLIER_LOGIN_SECOND_CONGRATULATIONS_SUBTITLE]: `We will let you know when your first model is ready {linebreak} In the meantime, you can log into your store dashboard and {linebreak} find out what things you can do in Patricia`,
  [Message.SUPPLIER_LOGIN_SECOND_CONGRATULATIONS_SUBTITLE_MOBILE]: `We will let you know when your{linebreak} first model is ready{linebreak} In the meantime, you can log into your store{linebreak} dashboard and find out what things you can{linebreak} do in Patricia`,
  [Message.SUPPLIER_LOGIN__CONGRATULATIONS_SUBTITLE_SKIP]: `One of our team members will contact you shortly to assist you in {linebreak} completing the onboarding process. {linebreak}
  In the meantime, you can view the Patricia app here or view{linebreak} your demo store.`,
  [Message.SUPPLIER_LOGIN__CONGRATULATIONS_SUBTITLE_SKIP_MOBILE]: `One of our team members will contact you{linebreak} shortly to assist you in completing the{linebreak} onboarding process.{linebreak}
  In the meantime, you can view the Patricia{linebreak} app here or view your demo store.`,
  [Message.SUPPLIER_LOGIN__CONGRATULATIONS_SUBTITLE_SCHEDULED]: `One of our team members will contact you shortly for additional {linebreak} information and will assist you in completing the onboarding process`,
  [Message.SUPPLIER_LOGIN__CONGRATULATIONS_SUBTITLE_SCHEDULED_MOBILE]: `One of our team members will contact you{linebreak} shortly for additional information and will{linebreak} assist you in completing{linebreak} the onboarding process`,
  [Message.SUPPLIER_LOGIN_NEW_TO_APP]: 'New to the Patricia app?',
  [Message.SUPPLIER_LOGIN_SIGN_UP_HERE]: 'Sign up here',
  [Message.SUPPLIER_LOGIN_EMAIL_PLACEHOLDER]: 'Enter your email address',
  [Message.SUPPLIER_LOGIN_EMAIL_LABEL]: 'Email address',
  [Message.SUPPLIER_LOGIN_PASSWORD_PLACEHOLDER]: 'Enter your password',
  [Message.SUPPLIER_REGISTRATION_PHONE_NUMBER_LABEL]: 'Office Number*',
  [Message.SUPPLIER_WHAT_DO_YOU_SELL]: 'What do you sell?',
  [Message.SUPPLIER_SELECT_CATEGORY]: 'You an select more than one category',
  [Message.SUPPLIER_LOGIN_WELCOME_LETSDOTHIS_BUTTON]: `Let's do this`,
  [Message.SUPPLIER_LOGIN_WELCOME_CONGRATULATIONS_BUTTON]: `Go to your store`,
  [Message.SUPPLIER_LOGIN_WELCOME_GO_HOME_BUTTON]: `Go to home page`,
  [Message.SUPPLIER_LOGIN_FIRST_CONGRATULATIONS_RETURN_TO_HOME]: `Return to Home page`,
  [Message.SUPPLIER_LOGIN_THANKS_BUTTON]: 'Start your shop',
  [Message.SUPPLIER_STORE_INFO]: '<span>S</span>tore info',
  [Message.SUPPLIER_STORE_NAME_LABEL]: 'Store name',
  [Message.SUPPLIER_STORE_NAME_PLACEHOLDER]: 'Enter your store name',
  [Message.SUPPLIER_PHONE_NUMBER_PLACEHOLDER]: 'Enter your phone number',
  [Message.SUPPLIER_TAX_ID_LABEL]: 'Tax ID',
  [Message.SUPPLIER_TAX_ID_PLACEHOLDER]: 'Enter your tax number',
  [Message.SUPPLIER_WEBSITE_LABEL]: 'Website',
  [Message.SUPPLIER_WEBSITE_PLACEHOLDER]: 'Enter your website',
  [Message.SUPPLIER_MANDATORY_FIELDS]: '*Mandatory fields',
  [Message.SUPPLIER_UPLOAD_YOUR_LOGO_LABEL]: 'Upload your logo',
  [Message.SUPPLIER_TAX_ID_TOOLTIP_TEXT]:
    'Your lisence number the same{linebreak} number that you are pay taxes',
  [Message.SUPPLIER_MANUFACTURER_NAME_TOOLTIP_TEXT]:
    'The product manufacturer name',
  [Message.SUPPLIER_RETAILER_SKU_TOOLTIP_TEXT]:
    'Your SKU for the product as it written in{linebreak} your system - Has to be unique',
  [Message.SUPPLIER_MSRP_TOOLTIP_TEXT]: 'The original price',
  [Message.SUPPLIER_MEDIA_URLS_TOOLTIP_TEXT]: `Only images URL’s, don’t upload images here.{linebreak} Please use Comma ( , ) as a seperator between URL’s`,
  [Message.SUPPLIER_MEDIA_URLS_TOOLTIP_TEXT_MOBILE]: `Only images URL’s, don’t upload{linebreak} images here.{linebreak} Please use Comma ( , ) as a{linebreak} seperator between URL’s`,
  [Message.SUPPLIER_UPLOAD_YOUR_FIRST_PRODUCT]:
    'Upload your first product for <span>Free*</span>',
  [Message.SUPPLIER_MAIN_DETAILS]: 'Main details',
  [Message.SUPPLIER_PRODUCT_NAME_LABEL]: 'Product name',
  [Message.SUPPLIER_PRODUCT_NAME_PLACEHOLDER]: 'Enter your product name',
  [Message.SUPPLIER_RETAILER_SKU_LABEL]: 'Retailer SKU',
  [Message.SUPPLIER_RETAILER_SKU_PLACEHOLDER]: 'Enter your Retailer SKU',
  [Message.SUPPLIER_QUANTITY_LABEL]: 'Quantity',
  [Message.SUPPLIER_QUANTITY_PLACEHOLDER]: 'Enter your quantity',
  [Message.SUPPLIER_MSRP_LABEL]: 'MSRP',
  [Message.SUPPLIER_MSRP_PLACEHOLDER]: 'Enter your MSRP',
  [Message.SUPPLIER_SALES_PRICE_LABEL]: 'Sales price',
  [Message.SUPPLIER_SALES_PRICE_PLACEHOLDER]: 'Enter your product price',
  [Message.SUPPLIER_MEDIA_URL_LABEL]: `Media URL’s`,
  [Message.SUPPLIER_MEDIA_URL_PLACEHOLDER]:
    'Type somthing and then press enter...',
  [Message.SUPPLIER_UPLOAD_MEDIA_LABEL]: 'Upload media',
  [Message.SUPPLIER_ADDITIONAL_INFO]: 'Additional info',
  [Message.SUPPLIER_DESCRIPTION_LABEL]: 'Description',
  [Message.SUPPLIER_DESCRIPTION_PLACEHOLDER]:
    'Add a description and then press enter',
  [Message.SUPPLIER_WEBPAGE_LINK_LABEL]: 'Web page link',
  [Message.SUPPLIER_WEBPAGE_LINK_PLACEHOLDER]: 'Enter your shop name',
  [Message.SUPPLIER_TYPES_LABEL]: 'Types',
  [Message.SUPPLIER_TYPES_PLACEHOLDER]: 'Select type',
  [Message.SUPPLIER_TOTAL_LENGTH_LABEL]: 'Total length*',
  [Message.SUPPLIER_TOTAL_LENGTH_PLACEHOLDER]: 'Enter length',
  [Message.SUPPLIER_TOTAL_WIDTH_LABEL]: 'Total width*',
  [Message.SUPPLIER_TOTAL_WIDTH_PLACEHOLDER]: 'Enter width',
  [Message.SUPPLIER_TOTAL_HEIGHT_LABEL]: 'Total height*',
  [Message.SUPPLIER_TOTAL_HEIGHT_PLACEHOLDER]: 'Enter height',
  [Message.SUPPLIER_MEASUREMENT_UNITS_LABEL]: 'Measurement units*',
  [Message.SUPPLIER_MEASUREMENT_UNITS_PLACEHOLDER]: 'Select measurement units',
  [Message.SUPPLIER_MARKETING_INFO]: 'Marketing info - optional',
  [Message.SUPPLIER_UPLOAD_SPECIFICATION_FILE_LABEL]:
    'Upload specification files',
  [Message.SUPPLIER_SERIES_LABEL]: 'Series',
  [Message.SUPPLIER_SERIES_PLACEHOLDER]: 'Enter series number',
  [Message.SUPPLIER_COLOR_TAG_LABEL]: 'Color tag',
  [Message.SUPPLIER_COLOR_Tag_PLACEHOLDER]: 'Choose color',
  [Message.SUPPLIER_TAGS_LABEL]: 'Tags',
  [Message.SUPPLIER_TAGS_PLACEHOLDER]: 'Type somthing and then press enter...',
  [Message.SUPPLIER_REPEAT_PASSWORD]: 'Repeat Password',
  [Message.SUPPLIER_NOTE_TEXT]:
    '*Need some assistance? <span>Click here</span> to schedule a 1:1 onboarding session',
  [Message.SUPPLIER_SKIP_CONFIRMATION_SUBTITLE1]:
    'You are about to skip the product upload step, are you sure?',
  [Message.SUPPLIER_SKIP_CONFIRMATION_SUBTITLE2]:
    'Note that you can upload a product at any stage',
  [Message.UPLOAD_BANNER_TITLE]: 'UPLOAD',
  [Message.DESIGN_BANNER_TITLE]: 'DESIGN',
  [Message.SHOP_BANNER_TITLE]: 'SHOP',
  [Message.SUPPLIER_SCHEDULE_MEETING]: 'Schedule meeting',
  [Message.ONBOARDING_TOOLTIP_FLOOR_ROOM_CONFIRM]: 'אישור  תכנית דירה',
  [Message.PROPERTY_CARD_DESIGN_STYLE]: 'שם הנכס',
  [Message.PROPERTY_SIZE]: 'גודל הנכס',
  [Message.MODERN]: 'מודרני',
  [Message.CLASSIC]: 'קלאסי',
  [Message.SCANDINAVIAN]: 'סקנדינבי',
  [Message.VIEW_SELECTION]: 'בחירת נוף',
  [Message.WHAT_ARE_LOOKING_FOR]: 'בחר באיזה סוג דירה אתה מחפש?',
  [Message.PRIVATE]: 'פרטיים',
  [Message.PROJECT]: 'פרוייקטים',
  [Message.MYPROJECT]: 'הפרוייקטים שלי',
  [Message.ADD_A_NEW_PROJECT]: 'הוסף פרוייקט חדש',
  [Message.CREATE_MANUALLY]: 'העלאה ידנית',
  [Message.PEROJECT_DETAILS]: 'פרטי הפרוייקט',
  [Message.PROJECT_NAME]: 'שם הפרוייקט',
  [Message.DEVELOPER_NAME_FORM]: 'שם החברה*',
  [Message.PROJECT_NUMBER]: 'מספר הפרוייקט',
  [Message.PROJECT_ADDRESS_FORM]: 'כתובת הפרוייקט',
  [Message.COVER_PHOTO]: 'תמונה ראשית*',
  [Message.CSV_FILE]: 'קובץ CSV (אופציונלי)',
  [Message.REAL_ESTATE_DEVELOPER]: 'שם החברה',
  [Message.UPLOAD_COVER_PHOTO]: 'העלאת תמונה ראשית*',
  [Message.UPLOAD_MATRIX_FILE]: 'העלאת קובץ מטריצה',
  [Message.REPLACE_FILE]: 'החלפת קובץ',
  [Message.NO_RESULTS]: 'לא נמצאו תוצאות עבור',
  [Message.ADD_NEW_DEVELOPER]: 'הוסף יזם נדל”ן חדש',
  [Message.PROJECT_SEARCH_PLACEHOLDER]: 'הכנס שם פרוייקט',
  [Message.DELETE_PROJECT_TEXT]: 'האם אתה בטוח שברצונך למחוק את הפרויקט הזה?',
  [Message.EDIT_PROJECT]: 'השכונה הירוקה',
  [Message.PROJECT_ADDRESS]: 'כתובת הפרוייקט',
  [Message.PROJECT_ADDRESS_FORM_MY_PROJECT_TITLE]: 'כתובת הפרוייקט:',

  [Message.DEVELOPER_NAME_MY_PROJECT_TITLE]: 'שם החברה:',
  [Message.NUMBER_OF_PROPERTIES]: 'מספר דירות בפרוייקט:',
  [Message.PROPERTIES_SECTION_TITLE]: 'דירות <span>({properties})</span>',
  [Message.TYPES_SECTION_TITLE]: 'יחידות <span>({types})</span>',
  [Message.ENTER_APARTMENT_NUMBER_PLACEHOLDER]: 'הכנס מספר דירה',
  [Message.TYPE_NAME]: 'שם היחידה',
  [Message.TYPE_TITLE]: 'יחידה ',
  [Message.BUILDING_NAME_TITLE]: 'בניין ',
  [Message.BUILDING]: 'בניין:',
  [Message.TYPE]: 'יחידה:',
  [Message.ASSOCIATED_PROPERTIES]: 'דירות נלוות:',
  [Message.ADD_NEW_UNIT]: 'הוספת יחידה חדשה',
  [Message.ADD_CUSTOMER_LIST]: 'שיוך לקוחות מרשימה',
  [Message.BUILDING_NAME]: 'שם הבניין',
  [Message.FLOOR_PLAN]: 'תכנית דירה*',
  [Message.UPLOAD_FLOOR_PLAN]: 'העלאת תכנית דירה*',
  [Message.BROWSE]: 'הוספת קובץ',
  [Message.NEW_UNIT_UPLOAD_NOTICE]:
    'כדאי להעלות קבצי <span>PDF / PNG / JPEG</span>{linebreak} עד 10MB או 4 עמודים.',
  [Message.CREATE_NEW_UNIT]: 'יצירת יחידה חדשה',
  [Message.APPLY_CHANGES]: 'החל שינויים',
  [Message.PROJECT_SEARCH]: 'חיפוש',
  [Message.GREEN_HOUSE]: 'השכונה הירוקה',
  [Message.A]: 'א',
  [Message.PROJECT_LOADER_TEXT]: 'Please wait while the file uploading',
  [Message.FILE_UPLOADING_SUMMARY]: 'File uploading summary',
  [Message.SUCCESS]: 'הועלו בהצלחה',
  [Message.FAILED]: 'נכשלו בהעלאה',
  [Message.DOWNLOAD_CSV]: 'Download CSV',
  [Message.DOWNLOAD_PRODUCT_LIST]: 'Download Product List',
  [Message.SHARE_PROPERTY_WITH_CUSTOMER]: 'שיתוף דירה עם לקוח',
  [Message.FINISH_AND_SEND]: 'סיים ושלח',
  [Message.UPLOAD_FILE_FAIL]: 'Upload failed. Please, contact admins.',
  [Message.JOHN]: 'ישראל',
  [Message.DOE]: 'ישראלי',
  [Message.SUPPLIER_LOGIN_EMAIL_PLACEHOLDERS]: 'הכנס כתובת דואר אלקטרוני',
  [Message.MODY_BUTTON_TEXT]: 'לבחירת סוג הנכס לחצו כאן',
  [Message.MODY_HEADER_TEXT]: 'ברוכים הבאים',
  [Message.ALL_DETAILS_MANDATORY]: '*כל הפרטים הינם חובה',
  [Message.SCAN_YOUR_HOME]: 'סרוק את הבית שלך',
  [Message.PROJECT_NAME_FORM]: 'הבית הירוק',
  [Message.PROJECT_NUMBER_FORM]: '1158477',
  [Message.PROJECT_FORM_ADDRESS_FORM]: 'הרצל 57',
  [Message.ADDRESS_FORM_CITY_FORM]: 'חיפה',
  [Message.ADD_NEW_DEVELOPER_FORM]: 'בחרו את החברה*',
  [Message.ADD_NEW_UNIT_PROJECT]: 'הוסף יחידה חדשה',
  [Message.EDIT_UNIT]: 'ערוך יחידה',
  [Message.MATRIX_FILTER_BY_SUB_CATEGORY]: 'בחר קטגוריית מוצרים',
  [Message.MATRIX_PROJECT_SPECIFICATION]: 'מפרט פרויקט',
  [Message.MATRIX_FILTER_BY_CATEGORY]: 'מיון לפי תת-קטגוריה',
  [Message.PROJECT_UNIT_CREATE_USER_FILE]: 'יצירת קובץ משתמשים',
  [Message.PROJECT_UNIT_CREATE_USER_FILE_SUBTITLE]: 'אנא המתן בזמן יצירת הקובץ',
  [Message.PROJECT_UNIT_CREATE_USER_FILE_SUCCESS_SUBTITLE]:
    'קובץ משתמשים נוצר בהצלחה',
  [Message.PROPERTY_ADD_CO_OWNER]: 'הוסף בעלים משותף',
  [Message.UPLOAD_PLAN_ERROR_TYPE]: 'סוג הקובץ צריך להיות JPG, PNG JPEG או PDF',
  [Message.UPLOAD_PLAN_ERROR_RESOLUTION]:
    'רוחב או גובה הקובץ שלא יעלה על 10,000 פיקסלים',
  [Message.UPLOAD_PLAN_ERROR_SIZE]: 'גודל הקובץ לא יכול לעבור 10 MB',
  [Message.UPLOAD_PLAN_ERROR_RATIO]: 'יחס בין גובה לרוחב צריך להיות 3:1',
  [Message.PROPERTY_STATUS_ERROR]: 'טעות בתוכנית',
  [Message.PROPERTY_REUPLOAD_BUTTON]: 'להעלות מחדש',
  [Message.PROPERTY_UPLOAD_PRODUCT_CSV]: 'Upload Product CSV',
  [Message.STATUS_TEXT]: 'סטָטוּס',
  [Message.UNIT_STATUS_WAITING]: 'הַמתָנָה',
  [Message.UNIT_STATUS_TODO]: 'לעשות',
  [Message.UNIT_STATUS_ERR_IN_PLAN]: 'טעות בתוכנית',
  [Message.UNIT_STATUS_PUBLISHED]: 'יצא לאור',
  [Message.UNIT_STATUS_IN_PROGRESS]: 'בתהליך',
};

export default messages;
